import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useOverlay from '../useOverlay'
import { useWidth } from '../useWidth'
import { boolAttr } from '../utils'
import Separator from '../Separator'
import Image from '../image/Image'
import './nav.css'

import logoImg from '../../images/logo-lg.jpg'

const config = [
  { title: 'Services', path: '/services' },
  { title: 'About Me', path: '/about' },
  { title: 'Contact', path: '/contact' },
  { title: 'Project Gallery', path: '/work' },
  { title: 'Before & After', path: '/results' },
]

function Logo({ onClick }) {
  return (
    <Link to="/" className="logo" onClick={onClick}>
      <Image width={150} src={logoImg} alt="Ducks in a Rose | Home Organization with LeaRose" />
    </Link>
  )
}

function Name({ onClick }) {
  return (
    <Link to="/" className="logo" onClick={onClick}>
      Ducks in a Rose <small className="secondary">with LeaRose</small>
    </Link>
  )
}

export function Email() {
  return (
    <a href="mailto:contact@ducksinarose.com" className="no-breaks hidden">
      contact@ducksinarose.com
    </a>
  )
}

export function Phone() {
  return (
    <a href="tel:+18019232310" className="no-breaks hidden">
      (801) 923-2310
    </a>
  )
}

export function ContactInfo({ sep = '|' }) {
  return (
    <address>
      <Email /> {sep} <Phone />
    </address>
  )
}

function Links({ children, showHome = false, onClick }) {
  return (
    <div className="links">
      <ul>
        {showHome && (
          <li>
            <Link to="/" onClick={onClick}>
              Home
            </Link>
          </li>
        )}
        {config.map(({ title, path }) => (
          <li key={title}>
            <Link to={path} onClick={onClick}>
              {title}
            </Link>
          </li>
        ))}
      </ul>
      {children}
    </div>
  )
}

function MobileNav() {
  const { isOpen, open, close } = useOverlay()

  return (
    <div className="mobile-nav">
      <nav className="nav">
        <button
          className="iconButton material-icons md-36"
          aria-label="Open Navigation Menu"
          onClick={open}
          type="button"
        >
          menu
        </button>
        <Logo />
      </nav>
      <div className={'overlay' + (isOpen ? ' open' : '')}>
        <div className="nav">
          <button
            className="iconButton material-icons md-36"
            aria-label="Close Navigation Menu"
            onClick={close}
            type="button"
          >
            close
          </button>
          <Name onClick={close} />
        </div>
        <div className="divider" />
        <Separator />
        <div className="mobileNav">
          <Links onClick={close} showHome />
          <Separator />
          <ContactInfo sep={<br />} />
        </div>
      </div>
    </div>
  )
}

export default function Nav() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [pathname])

  const isMobile = useWidth(700)
  return (
    <header className="header" data-mobile={boolAttr(isMobile)}>
      {isMobile ? (
        <MobileNav />
      ) : (
        <nav className="nav">
          <Logo />
          <Links>
            <ContactInfo />
          </Links>
        </nav>
      )}
    </header>
  )
}

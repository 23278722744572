import { useState } from 'react'
import { useStableCallback } from './utils'

export default function useOverlay({ initialOpen = false } = {}) {
  const [isOpen, setOpen] = useState(initialOpen)
  return {
    isOpen,
    open: useStableCallback(() => setOpen(true)),
    close: useStableCallback(() => setOpen(false)),
  }
}

import bathroom1afterjpg from "../bathroom1-after.jpg"
import bathroom1after480wpng from "./bathroom1-after-480w.png"
import bathroom1after768wpng from "./bathroom1-after-768w.png"
import bathroom1after1024wpng from "./bathroom1-after-1024w.png"
import bathroom1after1440wpng from "./bathroom1-after-1440w.png"
import bathroom1after1920wpng from "./bathroom1-after-1920w.png"
import bathroom1after2048wpng from "./bathroom1-after-2048w.png"
import bathroom1after480wwebp from "./bathroom1-after-480w.webp"
import bathroom1after768wwebp from "./bathroom1-after-768w.webp"
import bathroom1after1024wwebp from "./bathroom1-after-1024w.webp"
import bathroom1after1440wwebp from "./bathroom1-after-1440w.webp"
import bathroom1after1920wwebp from "./bathroom1-after-1920w.webp"
import bathroom1after2048wwebp from "./bathroom1-after-2048w.webp"
import bathroom1after480wavif from "./bathroom1-after-480w.avif"
import bathroom1after768wavif from "./bathroom1-after-768w.avif"
import bathroom1after1024wavif from "./bathroom1-after-1024w.avif"
import bathroom1after1440wavif from "./bathroom1-after-1440w.avif"
import bathroom1after1920wavif from "./bathroom1-after-1920w.avif"
import bathroom1after2048wavif from "./bathroom1-after-2048w.avif"
import bathroom1beforejpg from "../bathroom1-before.jpg"
import bathroom1before480wpng from "./bathroom1-before-480w.png"
import bathroom1before768wpng from "./bathroom1-before-768w.png"
import bathroom1before1024wpng from "./bathroom1-before-1024w.png"
import bathroom1before1440wpng from "./bathroom1-before-1440w.png"
import bathroom1before1920wpng from "./bathroom1-before-1920w.png"
import bathroom1before2048wpng from "./bathroom1-before-2048w.png"
import bathroom1before480wwebp from "./bathroom1-before-480w.webp"
import bathroom1before768wwebp from "./bathroom1-before-768w.webp"
import bathroom1before1024wwebp from "./bathroom1-before-1024w.webp"
import bathroom1before1440wwebp from "./bathroom1-before-1440w.webp"
import bathroom1before1920wwebp from "./bathroom1-before-1920w.webp"
import bathroom1before2048wwebp from "./bathroom1-before-2048w.webp"
import bathroom1before480wavif from "./bathroom1-before-480w.avif"
import bathroom1before768wavif from "./bathroom1-before-768w.avif"
import bathroom1before1024wavif from "./bathroom1-before-1024w.avif"
import bathroom1before1440wavif from "./bathroom1-before-1440w.avif"
import bathroom1before1920wavif from "./bathroom1-before-1920w.avif"
import bathroom1before2048wavif from "./bathroom1-before-2048w.avif"
import bathroom2afterAjpg from "../bathroom2-afterA.jpg"
import bathroom2afterA480wpng from "./bathroom2-afterA-480w.png"
import bathroom2afterA768wpng from "./bathroom2-afterA-768w.png"
import bathroom2afterA1024wpng from "./bathroom2-afterA-1024w.png"
import bathroom2afterA1440wpng from "./bathroom2-afterA-1440w.png"
import bathroom2afterA1920wpng from "./bathroom2-afterA-1920w.png"
import bathroom2afterA2048wpng from "./bathroom2-afterA-2048w.png"
import bathroom2afterA480wwebp from "./bathroom2-afterA-480w.webp"
import bathroom2afterA768wwebp from "./bathroom2-afterA-768w.webp"
import bathroom2afterA1024wwebp from "./bathroom2-afterA-1024w.webp"
import bathroom2afterA1440wwebp from "./bathroom2-afterA-1440w.webp"
import bathroom2afterA1920wwebp from "./bathroom2-afterA-1920w.webp"
import bathroom2afterA2048wwebp from "./bathroom2-afterA-2048w.webp"
import bathroom2afterA480wavif from "./bathroom2-afterA-480w.avif"
import bathroom2afterA768wavif from "./bathroom2-afterA-768w.avif"
import bathroom2afterA1024wavif from "./bathroom2-afterA-1024w.avif"
import bathroom2afterA1440wavif from "./bathroom2-afterA-1440w.avif"
import bathroom2afterA1920wavif from "./bathroom2-afterA-1920w.avif"
import bathroom2afterA2048wavif from "./bathroom2-afterA-2048w.avif"
import booksjpg from "../books.jpg"
import books480wpng from "./books-480w.png"
import books768wpng from "./books-768w.png"
import books1024wpng from "./books-1024w.png"
import books1440wpng from "./books-1440w.png"
import books1920wpng from "./books-1920w.png"
import books2048wpng from "./books-2048w.png"
import books480wwebp from "./books-480w.webp"
import books768wwebp from "./books-768w.webp"
import books1024wwebp from "./books-1024w.webp"
import books1440wwebp from "./books-1440w.webp"
import books1920wwebp from "./books-1920w.webp"
import books2048wwebp from "./books-2048w.webp"
import books480wavif from "./books-480w.avif"
import books768wavif from "./books-768w.avif"
import books1024wavif from "./books-1024w.avif"
import books1440wavif from "./books-1440w.avif"
import books1920wavif from "./books-1920w.avif"
import books2048wavif from "./books-2048w.avif"
import books2afterAjpg from "../books2-afterA.jpg"
import books2afterA480wpng from "./books2-afterA-480w.png"
import books2afterA768wpng from "./books2-afterA-768w.png"
import books2afterA1024wpng from "./books2-afterA-1024w.png"
import books2afterA1440wpng from "./books2-afterA-1440w.png"
import books2afterA1920wpng from "./books2-afterA-1920w.png"
import books2afterA2048wpng from "./books2-afterA-2048w.png"
import books2afterA480wwebp from "./books2-afterA-480w.webp"
import books2afterA768wwebp from "./books2-afterA-768w.webp"
import books2afterA1024wwebp from "./books2-afterA-1024w.webp"
import books2afterA1440wwebp from "./books2-afterA-1440w.webp"
import books2afterA1920wwebp from "./books2-afterA-1920w.webp"
import books2afterA2048wwebp from "./books2-afterA-2048w.webp"
import books2afterA480wavif from "./books2-afterA-480w.avif"
import books2afterA768wavif from "./books2-afterA-768w.avif"
import books2afterA1024wavif from "./books2-afterA-1024w.avif"
import books2afterA1440wavif from "./books2-afterA-1440w.avif"
import books2afterA1920wavif from "./books2-afterA-1920w.avif"
import books2afterA2048wavif from "./books2-afterA-2048w.avif"
import books2afterBjpg from "../books2-afterB.jpg"
import books2afterB480wpng from "./books2-afterB-480w.png"
import books2afterB768wpng from "./books2-afterB-768w.png"
import books2afterB1024wpng from "./books2-afterB-1024w.png"
import books2afterB1440wpng from "./books2-afterB-1440w.png"
import books2afterB1920wpng from "./books2-afterB-1920w.png"
import books2afterB2048wpng from "./books2-afterB-2048w.png"
import books2afterB480wwebp from "./books2-afterB-480w.webp"
import books2afterB768wwebp from "./books2-afterB-768w.webp"
import books2afterB1024wwebp from "./books2-afterB-1024w.webp"
import books2afterB1440wwebp from "./books2-afterB-1440w.webp"
import books2afterB1920wwebp from "./books2-afterB-1920w.webp"
import books2afterB2048wwebp from "./books2-afterB-2048w.webp"
import books2afterB480wavif from "./books2-afterB-480w.avif"
import books2afterB768wavif from "./books2-afterB-768w.avif"
import books2afterB1024wavif from "./books2-afterB-1024w.avif"
import books2afterB1440wavif from "./books2-afterB-1440w.avif"
import books2afterB1920wavif from "./books2-afterB-1920w.avif"
import books2afterB2048wavif from "./books2-afterB-2048w.avif"
import cabinet1afterjpg from "../cabinet1-after.jpg"
import cabinet1after480wpng from "./cabinet1-after-480w.png"
import cabinet1after768wpng from "./cabinet1-after-768w.png"
import cabinet1after1024wpng from "./cabinet1-after-1024w.png"
import cabinet1after1440wpng from "./cabinet1-after-1440w.png"
import cabinet1after1920wpng from "./cabinet1-after-1920w.png"
import cabinet1after2048wpng from "./cabinet1-after-2048w.png"
import cabinet1after480wwebp from "./cabinet1-after-480w.webp"
import cabinet1after768wwebp from "./cabinet1-after-768w.webp"
import cabinet1after1024wwebp from "./cabinet1-after-1024w.webp"
import cabinet1after1440wwebp from "./cabinet1-after-1440w.webp"
import cabinet1after1920wwebp from "./cabinet1-after-1920w.webp"
import cabinet1after2048wwebp from "./cabinet1-after-2048w.webp"
import cabinet1after480wavif from "./cabinet1-after-480w.avif"
import cabinet1after768wavif from "./cabinet1-after-768w.avif"
import cabinet1after1024wavif from "./cabinet1-after-1024w.avif"
import cabinet1after1440wavif from "./cabinet1-after-1440w.avif"
import cabinet1after1920wavif from "./cabinet1-after-1920w.avif"
import cabinet1after2048wavif from "./cabinet1-after-2048w.avif"
import cabinet1beforejpg from "../cabinet1-before.jpg"
import cabinet1before480wpng from "./cabinet1-before-480w.png"
import cabinet1before768wpng from "./cabinet1-before-768w.png"
import cabinet1before1024wpng from "./cabinet1-before-1024w.png"
import cabinet1before1440wpng from "./cabinet1-before-1440w.png"
import cabinet1before1920wpng from "./cabinet1-before-1920w.png"
import cabinet1before2048wpng from "./cabinet1-before-2048w.png"
import cabinet1before480wwebp from "./cabinet1-before-480w.webp"
import cabinet1before768wwebp from "./cabinet1-before-768w.webp"
import cabinet1before1024wwebp from "./cabinet1-before-1024w.webp"
import cabinet1before1440wwebp from "./cabinet1-before-1440w.webp"
import cabinet1before1920wwebp from "./cabinet1-before-1920w.webp"
import cabinet1before2048wwebp from "./cabinet1-before-2048w.webp"
import cabinet1before480wavif from "./cabinet1-before-480w.avif"
import cabinet1before768wavif from "./cabinet1-before-768w.avif"
import cabinet1before1024wavif from "./cabinet1-before-1024w.avif"
import cabinet1before1440wavif from "./cabinet1-before-1440w.avif"
import cabinet1before1920wavif from "./cabinet1-before-1920w.avif"
import cabinet1before2048wavif from "./cabinet1-before-2048w.avif"
import closet1jpg from "../closet1.jpg"
import closet1480wpng from "./closet1-480w.png"
import closet1768wpng from "./closet1-768w.png"
import closet11024wpng from "./closet1-1024w.png"
import closet11440wpng from "./closet1-1440w.png"
import closet11920wpng from "./closet1-1920w.png"
import closet12048wpng from "./closet1-2048w.png"
import closet1480wwebp from "./closet1-480w.webp"
import closet1768wwebp from "./closet1-768w.webp"
import closet11024wwebp from "./closet1-1024w.webp"
import closet11440wwebp from "./closet1-1440w.webp"
import closet11920wwebp from "./closet1-1920w.webp"
import closet12048wwebp from "./closet1-2048w.webp"
import closet1480wavif from "./closet1-480w.avif"
import closet1768wavif from "./closet1-768w.avif"
import closet11024wavif from "./closet1-1024w.avif"
import closet11440wavif from "./closet1-1440w.avif"
import closet11920wavif from "./closet1-1920w.avif"
import closet12048wavif from "./closet1-2048w.avif"
import closet2jpg from "../closet2.jpg"
import closet2480wpng from "./closet2-480w.png"
import closet2768wpng from "./closet2-768w.png"
import closet21024wpng from "./closet2-1024w.png"
import closet21440wpng from "./closet2-1440w.png"
import closet21920wpng from "./closet2-1920w.png"
import closet22048wpng from "./closet2-2048w.png"
import closet2480wwebp from "./closet2-480w.webp"
import closet2768wwebp from "./closet2-768w.webp"
import closet21024wwebp from "./closet2-1024w.webp"
import closet21440wwebp from "./closet2-1440w.webp"
import closet21920wwebp from "./closet2-1920w.webp"
import closet22048wwebp from "./closet2-2048w.webp"
import closet2480wavif from "./closet2-480w.avif"
import closet2768wavif from "./closet2-768w.avif"
import closet21024wavif from "./closet2-1024w.avif"
import closet21440wavif from "./closet2-1440w.avif"
import closet21920wavif from "./closet2-1920w.avif"
import closet22048wavif from "./closet2-2048w.avif"
import closet3jpg from "../closet3.jpg"
import closet3480wpng from "./closet3-480w.png"
import closet3768wpng from "./closet3-768w.png"
import closet31024wpng from "./closet3-1024w.png"
import closet31440wpng from "./closet3-1440w.png"
import closet31920wpng from "./closet3-1920w.png"
import closet32048wpng from "./closet3-2048w.png"
import closet3480wwebp from "./closet3-480w.webp"
import closet3768wwebp from "./closet3-768w.webp"
import closet31024wwebp from "./closet3-1024w.webp"
import closet31440wwebp from "./closet3-1440w.webp"
import closet31920wwebp from "./closet3-1920w.webp"
import closet32048wwebp from "./closet3-2048w.webp"
import closet3480wavif from "./closet3-480w.avif"
import closet3768wavif from "./closet3-768w.avif"
import closet31024wavif from "./closet3-1024w.avif"
import closet31440wavif from "./closet3-1440w.avif"
import closet31920wavif from "./closet3-1920w.avif"
import closet32048wavif from "./closet3-2048w.avif"
import closet4jpg from "../closet4.jpg"
import closet4480wpng from "./closet4-480w.png"
import closet4768wpng from "./closet4-768w.png"
import closet41024wpng from "./closet4-1024w.png"
import closet41440wpng from "./closet4-1440w.png"
import closet41920wpng from "./closet4-1920w.png"
import closet42048wpng from "./closet4-2048w.png"
import closet4480wwebp from "./closet4-480w.webp"
import closet4768wwebp from "./closet4-768w.webp"
import closet41024wwebp from "./closet4-1024w.webp"
import closet41440wwebp from "./closet4-1440w.webp"
import closet41920wwebp from "./closet4-1920w.webp"
import closet42048wwebp from "./closet4-2048w.webp"
import closet4480wavif from "./closet4-480w.avif"
import closet4768wavif from "./closet4-768w.avif"
import closet41024wavif from "./closet4-1024w.avif"
import closet41440wavif from "./closet4-1440w.avif"
import closet41920wavif from "./closet4-1920w.avif"
import closet42048wavif from "./closet4-2048w.avif"
import closet5after1jpg from "../closet5-after1.jpg"
import closet5after1480wpng from "./closet5-after1-480w.png"
import closet5after1768wpng from "./closet5-after1-768w.png"
import closet5after11024wpng from "./closet5-after1-1024w.png"
import closet5after11440wpng from "./closet5-after1-1440w.png"
import closet5after11920wpng from "./closet5-after1-1920w.png"
import closet5after12048wpng from "./closet5-after1-2048w.png"
import closet5after1480wwebp from "./closet5-after1-480w.webp"
import closet5after1768wwebp from "./closet5-after1-768w.webp"
import closet5after11024wwebp from "./closet5-after1-1024w.webp"
import closet5after11440wwebp from "./closet5-after1-1440w.webp"
import closet5after11920wwebp from "./closet5-after1-1920w.webp"
import closet5after12048wwebp from "./closet5-after1-2048w.webp"
import closet5after1480wavif from "./closet5-after1-480w.avif"
import closet5after1768wavif from "./closet5-after1-768w.avif"
import closet5after11024wavif from "./closet5-after1-1024w.avif"
import closet5after11440wavif from "./closet5-after1-1440w.avif"
import closet5after11920wavif from "./closet5-after1-1920w.avif"
import closet5after12048wavif from "./closet5-after1-2048w.avif"
import closet5after2jpg from "../closet5-after2.jpg"
import closet5after2480wpng from "./closet5-after2-480w.png"
import closet5after2768wpng from "./closet5-after2-768w.png"
import closet5after21024wpng from "./closet5-after2-1024w.png"
import closet5after21440wpng from "./closet5-after2-1440w.png"
import closet5after21920wpng from "./closet5-after2-1920w.png"
import closet5after22048wpng from "./closet5-after2-2048w.png"
import closet5after2480wwebp from "./closet5-after2-480w.webp"
import closet5after2768wwebp from "./closet5-after2-768w.webp"
import closet5after21024wwebp from "./closet5-after2-1024w.webp"
import closet5after21440wwebp from "./closet5-after2-1440w.webp"
import closet5after21920wwebp from "./closet5-after2-1920w.webp"
import closet5after22048wwebp from "./closet5-after2-2048w.webp"
import closet5after2480wavif from "./closet5-after2-480w.avif"
import closet5after2768wavif from "./closet5-after2-768w.avif"
import closet5after21024wavif from "./closet5-after2-1024w.avif"
import closet5after21440wavif from "./closet5-after2-1440w.avif"
import closet5after21920wavif from "./closet5-after2-1920w.avif"
import closet5after22048wavif from "./closet5-after2-2048w.avif"
import closet5after3jpg from "../closet5-after3.jpg"
import closet5after3480wpng from "./closet5-after3-480w.png"
import closet5after3768wpng from "./closet5-after3-768w.png"
import closet5after31024wpng from "./closet5-after3-1024w.png"
import closet5after31440wpng from "./closet5-after3-1440w.png"
import closet5after31920wpng from "./closet5-after3-1920w.png"
import closet5after32048wpng from "./closet5-after3-2048w.png"
import closet5after3480wwebp from "./closet5-after3-480w.webp"
import closet5after3768wwebp from "./closet5-after3-768w.webp"
import closet5after31024wwebp from "./closet5-after3-1024w.webp"
import closet5after31440wwebp from "./closet5-after3-1440w.webp"
import closet5after31920wwebp from "./closet5-after3-1920w.webp"
import closet5after32048wwebp from "./closet5-after3-2048w.webp"
import closet5after3480wavif from "./closet5-after3-480w.avif"
import closet5after3768wavif from "./closet5-after3-768w.avif"
import closet5after31024wavif from "./closet5-after3-1024w.avif"
import closet5after31440wavif from "./closet5-after3-1440w.avif"
import closet5after31920wavif from "./closet5-after3-1920w.avif"
import closet5after32048wavif from "./closet5-after3-2048w.avif"
import closet5before1jpg from "../closet5-before1.jpg"
import closet5before1480wpng from "./closet5-before1-480w.png"
import closet5before1768wpng from "./closet5-before1-768w.png"
import closet5before11024wpng from "./closet5-before1-1024w.png"
import closet5before11440wpng from "./closet5-before1-1440w.png"
import closet5before11920wpng from "./closet5-before1-1920w.png"
import closet5before12048wpng from "./closet5-before1-2048w.png"
import closet5before1480wwebp from "./closet5-before1-480w.webp"
import closet5before1768wwebp from "./closet5-before1-768w.webp"
import closet5before11024wwebp from "./closet5-before1-1024w.webp"
import closet5before11440wwebp from "./closet5-before1-1440w.webp"
import closet5before11920wwebp from "./closet5-before1-1920w.webp"
import closet5before12048wwebp from "./closet5-before1-2048w.webp"
import closet5before1480wavif from "./closet5-before1-480w.avif"
import closet5before1768wavif from "./closet5-before1-768w.avif"
import closet5before11024wavif from "./closet5-before1-1024w.avif"
import closet5before11440wavif from "./closet5-before1-1440w.avif"
import closet5before11920wavif from "./closet5-before1-1920w.avif"
import closet5before12048wavif from "./closet5-before1-2048w.avif"
import closet5before2jpg from "../closet5-before2.jpg"
import closet5before2480wpng from "./closet5-before2-480w.png"
import closet5before2768wpng from "./closet5-before2-768w.png"
import closet5before21024wpng from "./closet5-before2-1024w.png"
import closet5before21440wpng from "./closet5-before2-1440w.png"
import closet5before21920wpng from "./closet5-before2-1920w.png"
import closet5before22048wpng from "./closet5-before2-2048w.png"
import closet5before2480wwebp from "./closet5-before2-480w.webp"
import closet5before2768wwebp from "./closet5-before2-768w.webp"
import closet5before21024wwebp from "./closet5-before2-1024w.webp"
import closet5before21440wwebp from "./closet5-before2-1440w.webp"
import closet5before21920wwebp from "./closet5-before2-1920w.webp"
import closet5before22048wwebp from "./closet5-before2-2048w.webp"
import closet5before2480wavif from "./closet5-before2-480w.avif"
import closet5before2768wavif from "./closet5-before2-768w.avif"
import closet5before21024wavif from "./closet5-before2-1024w.avif"
import closet5before21440wavif from "./closet5-before2-1440w.avif"
import closet5before21920wavif from "./closet5-before2-1920w.avif"
import closet5before22048wavif from "./closet5-before2-2048w.avif"
import closet5before3jpg from "../closet5-before3.jpg"
import closet5before3480wpng from "./closet5-before3-480w.png"
import closet5before3768wpng from "./closet5-before3-768w.png"
import closet5before31024wpng from "./closet5-before3-1024w.png"
import closet5before31440wpng from "./closet5-before3-1440w.png"
import closet5before31920wpng from "./closet5-before3-1920w.png"
import closet5before32048wpng from "./closet5-before3-2048w.png"
import closet5before3480wwebp from "./closet5-before3-480w.webp"
import closet5before3768wwebp from "./closet5-before3-768w.webp"
import closet5before31024wwebp from "./closet5-before3-1024w.webp"
import closet5before31440wwebp from "./closet5-before3-1440w.webp"
import closet5before31920wwebp from "./closet5-before3-1920w.webp"
import closet5before32048wwebp from "./closet5-before3-2048w.webp"
import closet5before3480wavif from "./closet5-before3-480w.avif"
import closet5before3768wavif from "./closet5-before3-768w.avif"
import closet5before31024wavif from "./closet5-before3-1024w.avif"
import closet5before31440wavif from "./closet5-before3-1440w.avif"
import closet5before31920wavif from "./closet5-before3-1920w.avif"
import closet5before32048wavif from "./closet5-before3-2048w.avif"
import colorsjpg from "../colors.jpg"
import colors480wpng from "./colors-480w.png"
import colors768wpng from "./colors-768w.png"
import colors1024wpng from "./colors-1024w.png"
import colors1440wpng from "./colors-1440w.png"
import colors1920wpng from "./colors-1920w.png"
import colors2048wpng from "./colors-2048w.png"
import colors480wwebp from "./colors-480w.webp"
import colors768wwebp from "./colors-768w.webp"
import colors1024wwebp from "./colors-1024w.webp"
import colors1440wwebp from "./colors-1440w.webp"
import colors1920wwebp from "./colors-1920w.webp"
import colors2048wwebp from "./colors-2048w.webp"
import colors480wavif from "./colors-480w.avif"
import colors768wavif from "./colors-768w.avif"
import colors1024wavif from "./colors-1024w.avif"
import colors1440wavif from "./colors-1440w.avif"
import colors1920wavif from "./colors-1920w.avif"
import colors2048wavif from "./colors-2048w.avif"
import fridge1after1jpg from "../fridge1-after1.jpg"
import fridge1after1480wpng from "./fridge1-after1-480w.png"
import fridge1after1768wpng from "./fridge1-after1-768w.png"
import fridge1after11024wpng from "./fridge1-after1-1024w.png"
import fridge1after11440wpng from "./fridge1-after1-1440w.png"
import fridge1after11920wpng from "./fridge1-after1-1920w.png"
import fridge1after12048wpng from "./fridge1-after1-2048w.png"
import fridge1after1480wwebp from "./fridge1-after1-480w.webp"
import fridge1after1768wwebp from "./fridge1-after1-768w.webp"
import fridge1after11024wwebp from "./fridge1-after1-1024w.webp"
import fridge1after11440wwebp from "./fridge1-after1-1440w.webp"
import fridge1after11920wwebp from "./fridge1-after1-1920w.webp"
import fridge1after12048wwebp from "./fridge1-after1-2048w.webp"
import fridge1after1480wavif from "./fridge1-after1-480w.avif"
import fridge1after1768wavif from "./fridge1-after1-768w.avif"
import fridge1after11024wavif from "./fridge1-after1-1024w.avif"
import fridge1after11440wavif from "./fridge1-after1-1440w.avif"
import fridge1after11920wavif from "./fridge1-after1-1920w.avif"
import fridge1after12048wavif from "./fridge1-after1-2048w.avif"
import fridge1after2jpg from "../fridge1-after2.jpg"
import fridge1after2480wpng from "./fridge1-after2-480w.png"
import fridge1after2768wpng from "./fridge1-after2-768w.png"
import fridge1after21024wpng from "./fridge1-after2-1024w.png"
import fridge1after21440wpng from "./fridge1-after2-1440w.png"
import fridge1after21920wpng from "./fridge1-after2-1920w.png"
import fridge1after22048wpng from "./fridge1-after2-2048w.png"
import fridge1after2480wwebp from "./fridge1-after2-480w.webp"
import fridge1after2768wwebp from "./fridge1-after2-768w.webp"
import fridge1after21024wwebp from "./fridge1-after2-1024w.webp"
import fridge1after21440wwebp from "./fridge1-after2-1440w.webp"
import fridge1after21920wwebp from "./fridge1-after2-1920w.webp"
import fridge1after22048wwebp from "./fridge1-after2-2048w.webp"
import fridge1after2480wavif from "./fridge1-after2-480w.avif"
import fridge1after2768wavif from "./fridge1-after2-768w.avif"
import fridge1after21024wavif from "./fridge1-after2-1024w.avif"
import fridge1after21440wavif from "./fridge1-after2-1440w.avif"
import fridge1after21920wavif from "./fridge1-after2-1920w.avif"
import fridge1after22048wavif from "./fridge1-after2-2048w.avif"
import fridge1before1jpg from "../fridge1-before1.jpg"
import fridge1before1480wpng from "./fridge1-before1-480w.png"
import fridge1before1768wpng from "./fridge1-before1-768w.png"
import fridge1before11024wpng from "./fridge1-before1-1024w.png"
import fridge1before11440wpng from "./fridge1-before1-1440w.png"
import fridge1before11920wpng from "./fridge1-before1-1920w.png"
import fridge1before12048wpng from "./fridge1-before1-2048w.png"
import fridge1before1480wwebp from "./fridge1-before1-480w.webp"
import fridge1before1768wwebp from "./fridge1-before1-768w.webp"
import fridge1before11024wwebp from "./fridge1-before1-1024w.webp"
import fridge1before11440wwebp from "./fridge1-before1-1440w.webp"
import fridge1before11920wwebp from "./fridge1-before1-1920w.webp"
import fridge1before12048wwebp from "./fridge1-before1-2048w.webp"
import fridge1before1480wavif from "./fridge1-before1-480w.avif"
import fridge1before1768wavif from "./fridge1-before1-768w.avif"
import fridge1before11024wavif from "./fridge1-before1-1024w.avif"
import fridge1before11440wavif from "./fridge1-before1-1440w.avif"
import fridge1before11920wavif from "./fridge1-before1-1920w.avif"
import fridge1before12048wavif from "./fridge1-before1-2048w.avif"
import fridge1before2jpg from "../fridge1-before2.jpg"
import fridge1before2480wpng from "./fridge1-before2-480w.png"
import fridge1before2768wpng from "./fridge1-before2-768w.png"
import fridge1before21024wpng from "./fridge1-before2-1024w.png"
import fridge1before21440wpng from "./fridge1-before2-1440w.png"
import fridge1before21920wpng from "./fridge1-before2-1920w.png"
import fridge1before22048wpng from "./fridge1-before2-2048w.png"
import fridge1before2480wwebp from "./fridge1-before2-480w.webp"
import fridge1before2768wwebp from "./fridge1-before2-768w.webp"
import fridge1before21024wwebp from "./fridge1-before2-1024w.webp"
import fridge1before21440wwebp from "./fridge1-before2-1440w.webp"
import fridge1before21920wwebp from "./fridge1-before2-1920w.webp"
import fridge1before22048wwebp from "./fridge1-before2-2048w.webp"
import fridge1before2480wavif from "./fridge1-before2-480w.avif"
import fridge1before2768wavif from "./fridge1-before2-768w.avif"
import fridge1before21024wavif from "./fridge1-before2-1024w.avif"
import fridge1before21440wavif from "./fridge1-before2-1440w.avif"
import fridge1before21920wavif from "./fridge1-before2-1920w.avif"
import fridge1before22048wavif from "./fridge1-before2-2048w.avif"
import fridge2afterAjpg from "../fridge2-afterA.jpg"
import fridge2afterA480wpng from "./fridge2-afterA-480w.png"
import fridge2afterA768wpng from "./fridge2-afterA-768w.png"
import fridge2afterA1024wpng from "./fridge2-afterA-1024w.png"
import fridge2afterA1440wpng from "./fridge2-afterA-1440w.png"
import fridge2afterA1920wpng from "./fridge2-afterA-1920w.png"
import fridge2afterA2048wpng from "./fridge2-afterA-2048w.png"
import fridge2afterA480wwebp from "./fridge2-afterA-480w.webp"
import fridge2afterA768wwebp from "./fridge2-afterA-768w.webp"
import fridge2afterA1024wwebp from "./fridge2-afterA-1024w.webp"
import fridge2afterA1440wwebp from "./fridge2-afterA-1440w.webp"
import fridge2afterA1920wwebp from "./fridge2-afterA-1920w.webp"
import fridge2afterA2048wwebp from "./fridge2-afterA-2048w.webp"
import fridge2afterA480wavif from "./fridge2-afterA-480w.avif"
import fridge2afterA768wavif from "./fridge2-afterA-768w.avif"
import fridge2afterA1024wavif from "./fridge2-afterA-1024w.avif"
import fridge2afterA1440wavif from "./fridge2-afterA-1440w.avif"
import fridge2afterA1920wavif from "./fridge2-afterA-1920w.avif"
import fridge2afterA2048wavif from "./fridge2-afterA-2048w.avif"
import fridge2beforeAjpg from "../fridge2-beforeA.jpg"
import fridge2beforeA480wpng from "./fridge2-beforeA-480w.png"
import fridge2beforeA768wpng from "./fridge2-beforeA-768w.png"
import fridge2beforeA1024wpng from "./fridge2-beforeA-1024w.png"
import fridge2beforeA1440wpng from "./fridge2-beforeA-1440w.png"
import fridge2beforeA1920wpng from "./fridge2-beforeA-1920w.png"
import fridge2beforeA2048wpng from "./fridge2-beforeA-2048w.png"
import fridge2beforeA480wwebp from "./fridge2-beforeA-480w.webp"
import fridge2beforeA768wwebp from "./fridge2-beforeA-768w.webp"
import fridge2beforeA1024wwebp from "./fridge2-beforeA-1024w.webp"
import fridge2beforeA1440wwebp from "./fridge2-beforeA-1440w.webp"
import fridge2beforeA1920wwebp from "./fridge2-beforeA-1920w.webp"
import fridge2beforeA2048wwebp from "./fridge2-beforeA-2048w.webp"
import fridge2beforeA480wavif from "./fridge2-beforeA-480w.avif"
import fridge2beforeA768wavif from "./fridge2-beforeA-768w.avif"
import fridge2beforeA1024wavif from "./fridge2-beforeA-1024w.avif"
import fridge2beforeA1440wavif from "./fridge2-beforeA-1440w.avif"
import fridge2beforeA1920wavif from "./fridge2-beforeA-1920w.avif"
import fridge2beforeA2048wavif from "./fridge2-beforeA-2048w.avif"
import fruitjpg from "../fruit.jpg"
import fruit480wpng from "./fruit-480w.png"
import fruit768wpng from "./fruit-768w.png"
import fruit1024wpng from "./fruit-1024w.png"
import fruit1440wpng from "./fruit-1440w.png"
import fruit1920wpng from "./fruit-1920w.png"
import fruit2048wpng from "./fruit-2048w.png"
import fruit480wwebp from "./fruit-480w.webp"
import fruit768wwebp from "./fruit-768w.webp"
import fruit1024wwebp from "./fruit-1024w.webp"
import fruit1440wwebp from "./fruit-1440w.webp"
import fruit1920wwebp from "./fruit-1920w.webp"
import fruit2048wwebp from "./fruit-2048w.webp"
import fruit480wavif from "./fruit-480w.avif"
import fruit768wavif from "./fruit-768w.avif"
import fruit1024wavif from "./fruit-1024w.avif"
import fruit1440wavif from "./fruit-1440w.avif"
import fruit1920wavif from "./fruit-1920w.avif"
import fruit2048wavif from "./fruit-2048w.avif"
import kitchen1jpg from "../kitchen1.jpg"
import kitchen1480wpng from "./kitchen1-480w.png"
import kitchen1768wpng from "./kitchen1-768w.png"
import kitchen11024wpng from "./kitchen1-1024w.png"
import kitchen11440wpng from "./kitchen1-1440w.png"
import kitchen11920wpng from "./kitchen1-1920w.png"
import kitchen12048wpng from "./kitchen1-2048w.png"
import kitchen1480wwebp from "./kitchen1-480w.webp"
import kitchen1768wwebp from "./kitchen1-768w.webp"
import kitchen11024wwebp from "./kitchen1-1024w.webp"
import kitchen11440wwebp from "./kitchen1-1440w.webp"
import kitchen11920wwebp from "./kitchen1-1920w.webp"
import kitchen12048wwebp from "./kitchen1-2048w.webp"
import kitchen1480wavif from "./kitchen1-480w.avif"
import kitchen1768wavif from "./kitchen1-768w.avif"
import kitchen11024wavif from "./kitchen1-1024w.avif"
import kitchen11440wavif from "./kitchen1-1440w.avif"
import kitchen11920wavif from "./kitchen1-1920w.avif"
import kitchen12048wavif from "./kitchen1-2048w.avif"
import kitchen2jpg from "../kitchen2.jpg"
import kitchen2480wpng from "./kitchen2-480w.png"
import kitchen2768wpng from "./kitchen2-768w.png"
import kitchen21024wpng from "./kitchen2-1024w.png"
import kitchen21440wpng from "./kitchen2-1440w.png"
import kitchen21920wpng from "./kitchen2-1920w.png"
import kitchen22048wpng from "./kitchen2-2048w.png"
import kitchen2480wwebp from "./kitchen2-480w.webp"
import kitchen2768wwebp from "./kitchen2-768w.webp"
import kitchen21024wwebp from "./kitchen2-1024w.webp"
import kitchen21440wwebp from "./kitchen2-1440w.webp"
import kitchen21920wwebp from "./kitchen2-1920w.webp"
import kitchen22048wwebp from "./kitchen2-2048w.webp"
import kitchen2480wavif from "./kitchen2-480w.avif"
import kitchen2768wavif from "./kitchen2-768w.avif"
import kitchen21024wavif from "./kitchen2-1024w.avif"
import kitchen21440wavif from "./kitchen2-1440w.avif"
import kitchen21920wavif from "./kitchen2-1920w.avif"
import kitchen22048wavif from "./kitchen2-2048w.avif"
import kitchen3after1jpg from "../kitchen3-after1.jpg"
import kitchen3after1480wpng from "./kitchen3-after1-480w.png"
import kitchen3after1768wpng from "./kitchen3-after1-768w.png"
import kitchen3after11024wpng from "./kitchen3-after1-1024w.png"
import kitchen3after11440wpng from "./kitchen3-after1-1440w.png"
import kitchen3after11920wpng from "./kitchen3-after1-1920w.png"
import kitchen3after12048wpng from "./kitchen3-after1-2048w.png"
import kitchen3after1480wwebp from "./kitchen3-after1-480w.webp"
import kitchen3after1768wwebp from "./kitchen3-after1-768w.webp"
import kitchen3after11024wwebp from "./kitchen3-after1-1024w.webp"
import kitchen3after11440wwebp from "./kitchen3-after1-1440w.webp"
import kitchen3after11920wwebp from "./kitchen3-after1-1920w.webp"
import kitchen3after12048wwebp from "./kitchen3-after1-2048w.webp"
import kitchen3after1480wavif from "./kitchen3-after1-480w.avif"
import kitchen3after1768wavif from "./kitchen3-after1-768w.avif"
import kitchen3after11024wavif from "./kitchen3-after1-1024w.avif"
import kitchen3after11440wavif from "./kitchen3-after1-1440w.avif"
import kitchen3after11920wavif from "./kitchen3-after1-1920w.avif"
import kitchen3after12048wavif from "./kitchen3-after1-2048w.avif"
import kitchen3after2jpg from "../kitchen3-after2.jpg"
import kitchen3after2480wpng from "./kitchen3-after2-480w.png"
import kitchen3after2768wpng from "./kitchen3-after2-768w.png"
import kitchen3after21024wpng from "./kitchen3-after2-1024w.png"
import kitchen3after21440wpng from "./kitchen3-after2-1440w.png"
import kitchen3after21920wpng from "./kitchen3-after2-1920w.png"
import kitchen3after22048wpng from "./kitchen3-after2-2048w.png"
import kitchen3after2480wwebp from "./kitchen3-after2-480w.webp"
import kitchen3after2768wwebp from "./kitchen3-after2-768w.webp"
import kitchen3after21024wwebp from "./kitchen3-after2-1024w.webp"
import kitchen3after21440wwebp from "./kitchen3-after2-1440w.webp"
import kitchen3after21920wwebp from "./kitchen3-after2-1920w.webp"
import kitchen3after22048wwebp from "./kitchen3-after2-2048w.webp"
import kitchen3after2480wavif from "./kitchen3-after2-480w.avif"
import kitchen3after2768wavif from "./kitchen3-after2-768w.avif"
import kitchen3after21024wavif from "./kitchen3-after2-1024w.avif"
import kitchen3after21440wavif from "./kitchen3-after2-1440w.avif"
import kitchen3after21920wavif from "./kitchen3-after2-1920w.avif"
import kitchen3after22048wavif from "./kitchen3-after2-2048w.avif"
import kitchen3after3jpg from "../kitchen3-after3.jpg"
import kitchen3after3480wpng from "./kitchen3-after3-480w.png"
import kitchen3after3768wpng from "./kitchen3-after3-768w.png"
import kitchen3after31024wpng from "./kitchen3-after3-1024w.png"
import kitchen3after31440wpng from "./kitchen3-after3-1440w.png"
import kitchen3after31920wpng from "./kitchen3-after3-1920w.png"
import kitchen3after32048wpng from "./kitchen3-after3-2048w.png"
import kitchen3after3480wwebp from "./kitchen3-after3-480w.webp"
import kitchen3after3768wwebp from "./kitchen3-after3-768w.webp"
import kitchen3after31024wwebp from "./kitchen3-after3-1024w.webp"
import kitchen3after31440wwebp from "./kitchen3-after3-1440w.webp"
import kitchen3after31920wwebp from "./kitchen3-after3-1920w.webp"
import kitchen3after32048wwebp from "./kitchen3-after3-2048w.webp"
import kitchen3after3480wavif from "./kitchen3-after3-480w.avif"
import kitchen3after3768wavif from "./kitchen3-after3-768w.avif"
import kitchen3after31024wavif from "./kitchen3-after3-1024w.avif"
import kitchen3after31440wavif from "./kitchen3-after3-1440w.avif"
import kitchen3after31920wavif from "./kitchen3-after3-1920w.avif"
import kitchen3after32048wavif from "./kitchen3-after3-2048w.avif"
import kitchen3afterAjpg from "../kitchen3-afterA.jpg"
import kitchen3afterA480wpng from "./kitchen3-afterA-480w.png"
import kitchen3afterA768wpng from "./kitchen3-afterA-768w.png"
import kitchen3afterA1024wpng from "./kitchen3-afterA-1024w.png"
import kitchen3afterA1440wpng from "./kitchen3-afterA-1440w.png"
import kitchen3afterA1920wpng from "./kitchen3-afterA-1920w.png"
import kitchen3afterA2048wpng from "./kitchen3-afterA-2048w.png"
import kitchen3afterA480wwebp from "./kitchen3-afterA-480w.webp"
import kitchen3afterA768wwebp from "./kitchen3-afterA-768w.webp"
import kitchen3afterA1024wwebp from "./kitchen3-afterA-1024w.webp"
import kitchen3afterA1440wwebp from "./kitchen3-afterA-1440w.webp"
import kitchen3afterA1920wwebp from "./kitchen3-afterA-1920w.webp"
import kitchen3afterA2048wwebp from "./kitchen3-afterA-2048w.webp"
import kitchen3afterA480wavif from "./kitchen3-afterA-480w.avif"
import kitchen3afterA768wavif from "./kitchen3-afterA-768w.avif"
import kitchen3afterA1024wavif from "./kitchen3-afterA-1024w.avif"
import kitchen3afterA1440wavif from "./kitchen3-afterA-1440w.avif"
import kitchen3afterA1920wavif from "./kitchen3-afterA-1920w.avif"
import kitchen3afterA2048wavif from "./kitchen3-afterA-2048w.avif"
import kitchen3afterBjpg from "../kitchen3-afterB.jpg"
import kitchen3afterB480wpng from "./kitchen3-afterB-480w.png"
import kitchen3afterB768wpng from "./kitchen3-afterB-768w.png"
import kitchen3afterB1024wpng from "./kitchen3-afterB-1024w.png"
import kitchen3afterB1440wpng from "./kitchen3-afterB-1440w.png"
import kitchen3afterB1920wpng from "./kitchen3-afterB-1920w.png"
import kitchen3afterB2048wpng from "./kitchen3-afterB-2048w.png"
import kitchen3afterB480wwebp from "./kitchen3-afterB-480w.webp"
import kitchen3afterB768wwebp from "./kitchen3-afterB-768w.webp"
import kitchen3afterB1024wwebp from "./kitchen3-afterB-1024w.webp"
import kitchen3afterB1440wwebp from "./kitchen3-afterB-1440w.webp"
import kitchen3afterB1920wwebp from "./kitchen3-afterB-1920w.webp"
import kitchen3afterB2048wwebp from "./kitchen3-afterB-2048w.webp"
import kitchen3afterB480wavif from "./kitchen3-afterB-480w.avif"
import kitchen3afterB768wavif from "./kitchen3-afterB-768w.avif"
import kitchen3afterB1024wavif from "./kitchen3-afterB-1024w.avif"
import kitchen3afterB1440wavif from "./kitchen3-afterB-1440w.avif"
import kitchen3afterB1920wavif from "./kitchen3-afterB-1920w.avif"
import kitchen3afterB2048wavif from "./kitchen3-afterB-2048w.avif"
import kitchen3afterCjpg from "../kitchen3-afterC.jpg"
import kitchen3afterC480wpng from "./kitchen3-afterC-480w.png"
import kitchen3afterC768wpng from "./kitchen3-afterC-768w.png"
import kitchen3afterC1024wpng from "./kitchen3-afterC-1024w.png"
import kitchen3afterC1440wpng from "./kitchen3-afterC-1440w.png"
import kitchen3afterC1920wpng from "./kitchen3-afterC-1920w.png"
import kitchen3afterC2048wpng from "./kitchen3-afterC-2048w.png"
import kitchen3afterC480wwebp from "./kitchen3-afterC-480w.webp"
import kitchen3afterC768wwebp from "./kitchen3-afterC-768w.webp"
import kitchen3afterC1024wwebp from "./kitchen3-afterC-1024w.webp"
import kitchen3afterC1440wwebp from "./kitchen3-afterC-1440w.webp"
import kitchen3afterC1920wwebp from "./kitchen3-afterC-1920w.webp"
import kitchen3afterC2048wwebp from "./kitchen3-afterC-2048w.webp"
import kitchen3afterC480wavif from "./kitchen3-afterC-480w.avif"
import kitchen3afterC768wavif from "./kitchen3-afterC-768w.avif"
import kitchen3afterC1024wavif from "./kitchen3-afterC-1024w.avif"
import kitchen3afterC1440wavif from "./kitchen3-afterC-1440w.avif"
import kitchen3afterC1920wavif from "./kitchen3-afterC-1920w.avif"
import kitchen3afterC2048wavif from "./kitchen3-afterC-2048w.avif"
import kitchen3afterDjpg from "../kitchen3-afterD.jpg"
import kitchen3afterD480wpng from "./kitchen3-afterD-480w.png"
import kitchen3afterD768wpng from "./kitchen3-afterD-768w.png"
import kitchen3afterD1024wpng from "./kitchen3-afterD-1024w.png"
import kitchen3afterD1440wpng from "./kitchen3-afterD-1440w.png"
import kitchen3afterD1920wpng from "./kitchen3-afterD-1920w.png"
import kitchen3afterD2048wpng from "./kitchen3-afterD-2048w.png"
import kitchen3afterD480wwebp from "./kitchen3-afterD-480w.webp"
import kitchen3afterD768wwebp from "./kitchen3-afterD-768w.webp"
import kitchen3afterD1024wwebp from "./kitchen3-afterD-1024w.webp"
import kitchen3afterD1440wwebp from "./kitchen3-afterD-1440w.webp"
import kitchen3afterD1920wwebp from "./kitchen3-afterD-1920w.webp"
import kitchen3afterD2048wwebp from "./kitchen3-afterD-2048w.webp"
import kitchen3afterD480wavif from "./kitchen3-afterD-480w.avif"
import kitchen3afterD768wavif from "./kitchen3-afterD-768w.avif"
import kitchen3afterD1024wavif from "./kitchen3-afterD-1024w.avif"
import kitchen3afterD1440wavif from "./kitchen3-afterD-1440w.avif"
import kitchen3afterD1920wavif from "./kitchen3-afterD-1920w.avif"
import kitchen3afterD2048wavif from "./kitchen3-afterD-2048w.avif"
import kitchen3afterEjpg from "../kitchen3-afterE.jpg"
import kitchen3afterE480wpng from "./kitchen3-afterE-480w.png"
import kitchen3afterE768wpng from "./kitchen3-afterE-768w.png"
import kitchen3afterE1024wpng from "./kitchen3-afterE-1024w.png"
import kitchen3afterE1440wpng from "./kitchen3-afterE-1440w.png"
import kitchen3afterE1920wpng from "./kitchen3-afterE-1920w.png"
import kitchen3afterE2048wpng from "./kitchen3-afterE-2048w.png"
import kitchen3afterE480wwebp from "./kitchen3-afterE-480w.webp"
import kitchen3afterE768wwebp from "./kitchen3-afterE-768w.webp"
import kitchen3afterE1024wwebp from "./kitchen3-afterE-1024w.webp"
import kitchen3afterE1440wwebp from "./kitchen3-afterE-1440w.webp"
import kitchen3afterE1920wwebp from "./kitchen3-afterE-1920w.webp"
import kitchen3afterE2048wwebp from "./kitchen3-afterE-2048w.webp"
import kitchen3afterE480wavif from "./kitchen3-afterE-480w.avif"
import kitchen3afterE768wavif from "./kitchen3-afterE-768w.avif"
import kitchen3afterE1024wavif from "./kitchen3-afterE-1024w.avif"
import kitchen3afterE1440wavif from "./kitchen3-afterE-1440w.avif"
import kitchen3afterE1920wavif from "./kitchen3-afterE-1920w.avif"
import kitchen3afterE2048wavif from "./kitchen3-afterE-2048w.avif"
import kitchen3afterFjpg from "../kitchen3-afterF.jpg"
import kitchen3afterF480wpng from "./kitchen3-afterF-480w.png"
import kitchen3afterF768wpng from "./kitchen3-afterF-768w.png"
import kitchen3afterF1024wpng from "./kitchen3-afterF-1024w.png"
import kitchen3afterF1440wpng from "./kitchen3-afterF-1440w.png"
import kitchen3afterF1920wpng from "./kitchen3-afterF-1920w.png"
import kitchen3afterF2048wpng from "./kitchen3-afterF-2048w.png"
import kitchen3afterF480wwebp from "./kitchen3-afterF-480w.webp"
import kitchen3afterF768wwebp from "./kitchen3-afterF-768w.webp"
import kitchen3afterF1024wwebp from "./kitchen3-afterF-1024w.webp"
import kitchen3afterF1440wwebp from "./kitchen3-afterF-1440w.webp"
import kitchen3afterF1920wwebp from "./kitchen3-afterF-1920w.webp"
import kitchen3afterF2048wwebp from "./kitchen3-afterF-2048w.webp"
import kitchen3afterF480wavif from "./kitchen3-afterF-480w.avif"
import kitchen3afterF768wavif from "./kitchen3-afterF-768w.avif"
import kitchen3afterF1024wavif from "./kitchen3-afterF-1024w.avif"
import kitchen3afterF1440wavif from "./kitchen3-afterF-1440w.avif"
import kitchen3afterF1920wavif from "./kitchen3-afterF-1920w.avif"
import kitchen3afterF2048wavif from "./kitchen3-afterF-2048w.avif"
import kitchen3afterGjpg from "../kitchen3-afterG.jpg"
import kitchen3afterG480wpng from "./kitchen3-afterG-480w.png"
import kitchen3afterG768wpng from "./kitchen3-afterG-768w.png"
import kitchen3afterG1024wpng from "./kitchen3-afterG-1024w.png"
import kitchen3afterG1440wpng from "./kitchen3-afterG-1440w.png"
import kitchen3afterG1920wpng from "./kitchen3-afterG-1920w.png"
import kitchen3afterG2048wpng from "./kitchen3-afterG-2048w.png"
import kitchen3afterG480wwebp from "./kitchen3-afterG-480w.webp"
import kitchen3afterG768wwebp from "./kitchen3-afterG-768w.webp"
import kitchen3afterG1024wwebp from "./kitchen3-afterG-1024w.webp"
import kitchen3afterG1440wwebp from "./kitchen3-afterG-1440w.webp"
import kitchen3afterG1920wwebp from "./kitchen3-afterG-1920w.webp"
import kitchen3afterG2048wwebp from "./kitchen3-afterG-2048w.webp"
import kitchen3afterG480wavif from "./kitchen3-afterG-480w.avif"
import kitchen3afterG768wavif from "./kitchen3-afterG-768w.avif"
import kitchen3afterG1024wavif from "./kitchen3-afterG-1024w.avif"
import kitchen3afterG1440wavif from "./kitchen3-afterG-1440w.avif"
import kitchen3afterG1920wavif from "./kitchen3-afterG-1920w.avif"
import kitchen3afterG2048wavif from "./kitchen3-afterG-2048w.avif"
import kitchen3afterHjpg from "../kitchen3-afterH.jpg"
import kitchen3afterH480wpng from "./kitchen3-afterH-480w.png"
import kitchen3afterH768wpng from "./kitchen3-afterH-768w.png"
import kitchen3afterH1024wpng from "./kitchen3-afterH-1024w.png"
import kitchen3afterH1440wpng from "./kitchen3-afterH-1440w.png"
import kitchen3afterH1920wpng from "./kitchen3-afterH-1920w.png"
import kitchen3afterH2048wpng from "./kitchen3-afterH-2048w.png"
import kitchen3afterH480wwebp from "./kitchen3-afterH-480w.webp"
import kitchen3afterH768wwebp from "./kitchen3-afterH-768w.webp"
import kitchen3afterH1024wwebp from "./kitchen3-afterH-1024w.webp"
import kitchen3afterH1440wwebp from "./kitchen3-afterH-1440w.webp"
import kitchen3afterH1920wwebp from "./kitchen3-afterH-1920w.webp"
import kitchen3afterH2048wwebp from "./kitchen3-afterH-2048w.webp"
import kitchen3afterH480wavif from "./kitchen3-afterH-480w.avif"
import kitchen3afterH768wavif from "./kitchen3-afterH-768w.avif"
import kitchen3afterH1024wavif from "./kitchen3-afterH-1024w.avif"
import kitchen3afterH1440wavif from "./kitchen3-afterH-1440w.avif"
import kitchen3afterH1920wavif from "./kitchen3-afterH-1920w.avif"
import kitchen3afterH2048wavif from "./kitchen3-afterH-2048w.avif"
import kitchen3afterIjpg from "../kitchen3-afterI.jpg"
import kitchen3afterI480wpng from "./kitchen3-afterI-480w.png"
import kitchen3afterI768wpng from "./kitchen3-afterI-768w.png"
import kitchen3afterI1024wpng from "./kitchen3-afterI-1024w.png"
import kitchen3afterI1440wpng from "./kitchen3-afterI-1440w.png"
import kitchen3afterI1920wpng from "./kitchen3-afterI-1920w.png"
import kitchen3afterI2048wpng from "./kitchen3-afterI-2048w.png"
import kitchen3afterI480wwebp from "./kitchen3-afterI-480w.webp"
import kitchen3afterI768wwebp from "./kitchen3-afterI-768w.webp"
import kitchen3afterI1024wwebp from "./kitchen3-afterI-1024w.webp"
import kitchen3afterI1440wwebp from "./kitchen3-afterI-1440w.webp"
import kitchen3afterI1920wwebp from "./kitchen3-afterI-1920w.webp"
import kitchen3afterI2048wwebp from "./kitchen3-afterI-2048w.webp"
import kitchen3afterI480wavif from "./kitchen3-afterI-480w.avif"
import kitchen3afterI768wavif from "./kitchen3-afterI-768w.avif"
import kitchen3afterI1024wavif from "./kitchen3-afterI-1024w.avif"
import kitchen3afterI1440wavif from "./kitchen3-afterI-1440w.avif"
import kitchen3afterI1920wavif from "./kitchen3-afterI-1920w.avif"
import kitchen3afterI2048wavif from "./kitchen3-afterI-2048w.avif"
import kitchen3afterJjpg from "../kitchen3-afterJ.jpg"
import kitchen3afterJ480wpng from "./kitchen3-afterJ-480w.png"
import kitchen3afterJ768wpng from "./kitchen3-afterJ-768w.png"
import kitchen3afterJ1024wpng from "./kitchen3-afterJ-1024w.png"
import kitchen3afterJ1440wpng from "./kitchen3-afterJ-1440w.png"
import kitchen3afterJ1920wpng from "./kitchen3-afterJ-1920w.png"
import kitchen3afterJ2048wpng from "./kitchen3-afterJ-2048w.png"
import kitchen3afterJ480wwebp from "./kitchen3-afterJ-480w.webp"
import kitchen3afterJ768wwebp from "./kitchen3-afterJ-768w.webp"
import kitchen3afterJ1024wwebp from "./kitchen3-afterJ-1024w.webp"
import kitchen3afterJ1440wwebp from "./kitchen3-afterJ-1440w.webp"
import kitchen3afterJ1920wwebp from "./kitchen3-afterJ-1920w.webp"
import kitchen3afterJ2048wwebp from "./kitchen3-afterJ-2048w.webp"
import kitchen3afterJ480wavif from "./kitchen3-afterJ-480w.avif"
import kitchen3afterJ768wavif from "./kitchen3-afterJ-768w.avif"
import kitchen3afterJ1024wavif from "./kitchen3-afterJ-1024w.avif"
import kitchen3afterJ1440wavif from "./kitchen3-afterJ-1440w.avif"
import kitchen3afterJ1920wavif from "./kitchen3-afterJ-1920w.avif"
import kitchen3afterJ2048wavif from "./kitchen3-afterJ-2048w.avif"
import kitchen3afterKjpg from "../kitchen3-afterK.jpg"
import kitchen3afterK480wpng from "./kitchen3-afterK-480w.png"
import kitchen3afterK768wpng from "./kitchen3-afterK-768w.png"
import kitchen3afterK1024wpng from "./kitchen3-afterK-1024w.png"
import kitchen3afterK1440wpng from "./kitchen3-afterK-1440w.png"
import kitchen3afterK1920wpng from "./kitchen3-afterK-1920w.png"
import kitchen3afterK2048wpng from "./kitchen3-afterK-2048w.png"
import kitchen3afterK480wwebp from "./kitchen3-afterK-480w.webp"
import kitchen3afterK768wwebp from "./kitchen3-afterK-768w.webp"
import kitchen3afterK1024wwebp from "./kitchen3-afterK-1024w.webp"
import kitchen3afterK1440wwebp from "./kitchen3-afterK-1440w.webp"
import kitchen3afterK1920wwebp from "./kitchen3-afterK-1920w.webp"
import kitchen3afterK2048wwebp from "./kitchen3-afterK-2048w.webp"
import kitchen3afterK480wavif from "./kitchen3-afterK-480w.avif"
import kitchen3afterK768wavif from "./kitchen3-afterK-768w.avif"
import kitchen3afterK1024wavif from "./kitchen3-afterK-1024w.avif"
import kitchen3afterK1440wavif from "./kitchen3-afterK-1440w.avif"
import kitchen3afterK1920wavif from "./kitchen3-afterK-1920w.avif"
import kitchen3afterK2048wavif from "./kitchen3-afterK-2048w.avif"
import kitchen3before1jpg from "../kitchen3-before1.jpg"
import kitchen3before1480wpng from "./kitchen3-before1-480w.png"
import kitchen3before1768wpng from "./kitchen3-before1-768w.png"
import kitchen3before11024wpng from "./kitchen3-before1-1024w.png"
import kitchen3before11440wpng from "./kitchen3-before1-1440w.png"
import kitchen3before11920wpng from "./kitchen3-before1-1920w.png"
import kitchen3before12048wpng from "./kitchen3-before1-2048w.png"
import kitchen3before1480wwebp from "./kitchen3-before1-480w.webp"
import kitchen3before1768wwebp from "./kitchen3-before1-768w.webp"
import kitchen3before11024wwebp from "./kitchen3-before1-1024w.webp"
import kitchen3before11440wwebp from "./kitchen3-before1-1440w.webp"
import kitchen3before11920wwebp from "./kitchen3-before1-1920w.webp"
import kitchen3before12048wwebp from "./kitchen3-before1-2048w.webp"
import kitchen3before1480wavif from "./kitchen3-before1-480w.avif"
import kitchen3before1768wavif from "./kitchen3-before1-768w.avif"
import kitchen3before11024wavif from "./kitchen3-before1-1024w.avif"
import kitchen3before11440wavif from "./kitchen3-before1-1440w.avif"
import kitchen3before11920wavif from "./kitchen3-before1-1920w.avif"
import kitchen3before12048wavif from "./kitchen3-before1-2048w.avif"
import kitchen3before2jpg from "../kitchen3-before2.jpg"
import kitchen3before2480wpng from "./kitchen3-before2-480w.png"
import kitchen3before2768wpng from "./kitchen3-before2-768w.png"
import kitchen3before21024wpng from "./kitchen3-before2-1024w.png"
import kitchen3before21440wpng from "./kitchen3-before2-1440w.png"
import kitchen3before21920wpng from "./kitchen3-before2-1920w.png"
import kitchen3before22048wpng from "./kitchen3-before2-2048w.png"
import kitchen3before2480wwebp from "./kitchen3-before2-480w.webp"
import kitchen3before2768wwebp from "./kitchen3-before2-768w.webp"
import kitchen3before21024wwebp from "./kitchen3-before2-1024w.webp"
import kitchen3before21440wwebp from "./kitchen3-before2-1440w.webp"
import kitchen3before21920wwebp from "./kitchen3-before2-1920w.webp"
import kitchen3before22048wwebp from "./kitchen3-before2-2048w.webp"
import kitchen3before2480wavif from "./kitchen3-before2-480w.avif"
import kitchen3before2768wavif from "./kitchen3-before2-768w.avif"
import kitchen3before21024wavif from "./kitchen3-before2-1024w.avif"
import kitchen3before21440wavif from "./kitchen3-before2-1440w.avif"
import kitchen3before21920wavif from "./kitchen3-before2-1920w.avif"
import kitchen3before22048wavif from "./kitchen3-before2-2048w.avif"
import kitchen3before3jpg from "../kitchen3-before3.jpg"
import kitchen3before3480wpng from "./kitchen3-before3-480w.png"
import kitchen3before3768wpng from "./kitchen3-before3-768w.png"
import kitchen3before31024wpng from "./kitchen3-before3-1024w.png"
import kitchen3before31440wpng from "./kitchen3-before3-1440w.png"
import kitchen3before31920wpng from "./kitchen3-before3-1920w.png"
import kitchen3before32048wpng from "./kitchen3-before3-2048w.png"
import kitchen3before3480wwebp from "./kitchen3-before3-480w.webp"
import kitchen3before3768wwebp from "./kitchen3-before3-768w.webp"
import kitchen3before31024wwebp from "./kitchen3-before3-1024w.webp"
import kitchen3before31440wwebp from "./kitchen3-before3-1440w.webp"
import kitchen3before31920wwebp from "./kitchen3-before3-1920w.webp"
import kitchen3before32048wwebp from "./kitchen3-before3-2048w.webp"
import kitchen3before3480wavif from "./kitchen3-before3-480w.avif"
import kitchen3before3768wavif from "./kitchen3-before3-768w.avif"
import kitchen3before31024wavif from "./kitchen3-before3-1024w.avif"
import kitchen3before31440wavif from "./kitchen3-before3-1440w.avif"
import kitchen3before31920wavif from "./kitchen3-before3-1920w.avif"
import kitchen3before32048wavif from "./kitchen3-before3-2048w.avif"
import kitchen4afterAjpg from "../kitchen4-afterA.jpg"
import kitchen4afterA480wpng from "./kitchen4-afterA-480w.png"
import kitchen4afterA768wpng from "./kitchen4-afterA-768w.png"
import kitchen4afterA1024wpng from "./kitchen4-afterA-1024w.png"
import kitchen4afterA1440wpng from "./kitchen4-afterA-1440w.png"
import kitchen4afterA1920wpng from "./kitchen4-afterA-1920w.png"
import kitchen4afterA2048wpng from "./kitchen4-afterA-2048w.png"
import kitchen4afterA480wwebp from "./kitchen4-afterA-480w.webp"
import kitchen4afterA768wwebp from "./kitchen4-afterA-768w.webp"
import kitchen4afterA1024wwebp from "./kitchen4-afterA-1024w.webp"
import kitchen4afterA1440wwebp from "./kitchen4-afterA-1440w.webp"
import kitchen4afterA1920wwebp from "./kitchen4-afterA-1920w.webp"
import kitchen4afterA2048wwebp from "./kitchen4-afterA-2048w.webp"
import kitchen4afterA480wavif from "./kitchen4-afterA-480w.avif"
import kitchen4afterA768wavif from "./kitchen4-afterA-768w.avif"
import kitchen4afterA1024wavif from "./kitchen4-afterA-1024w.avif"
import kitchen4afterA1440wavif from "./kitchen4-afterA-1440w.avif"
import kitchen4afterA1920wavif from "./kitchen4-afterA-1920w.avif"
import kitchen4afterA2048wavif from "./kitchen4-afterA-2048w.avif"
import kitchen4afterBjpg from "../kitchen4-afterB.jpg"
import kitchen4afterB480wpng from "./kitchen4-afterB-480w.png"
import kitchen4afterB768wpng from "./kitchen4-afterB-768w.png"
import kitchen4afterB1024wpng from "./kitchen4-afterB-1024w.png"
import kitchen4afterB1440wpng from "./kitchen4-afterB-1440w.png"
import kitchen4afterB1920wpng from "./kitchen4-afterB-1920w.png"
import kitchen4afterB2048wpng from "./kitchen4-afterB-2048w.png"
import kitchen4afterB480wwebp from "./kitchen4-afterB-480w.webp"
import kitchen4afterB768wwebp from "./kitchen4-afterB-768w.webp"
import kitchen4afterB1024wwebp from "./kitchen4-afterB-1024w.webp"
import kitchen4afterB1440wwebp from "./kitchen4-afterB-1440w.webp"
import kitchen4afterB1920wwebp from "./kitchen4-afterB-1920w.webp"
import kitchen4afterB2048wwebp from "./kitchen4-afterB-2048w.webp"
import kitchen4afterB480wavif from "./kitchen4-afterB-480w.avif"
import kitchen4afterB768wavif from "./kitchen4-afterB-768w.avif"
import kitchen4afterB1024wavif from "./kitchen4-afterB-1024w.avif"
import kitchen4afterB1440wavif from "./kitchen4-afterB-1440w.avif"
import kitchen4afterB1920wavif from "./kitchen4-afterB-1920w.avif"
import kitchen4afterB2048wavif from "./kitchen4-afterB-2048w.avif"
import kitchen4afterCjpg from "../kitchen4-afterC.jpg"
import kitchen4afterC480wpng from "./kitchen4-afterC-480w.png"
import kitchen4afterC768wpng from "./kitchen4-afterC-768w.png"
import kitchen4afterC1024wpng from "./kitchen4-afterC-1024w.png"
import kitchen4afterC1440wpng from "./kitchen4-afterC-1440w.png"
import kitchen4afterC1920wpng from "./kitchen4-afterC-1920w.png"
import kitchen4afterC2048wpng from "./kitchen4-afterC-2048w.png"
import kitchen4afterC480wwebp from "./kitchen4-afterC-480w.webp"
import kitchen4afterC768wwebp from "./kitchen4-afterC-768w.webp"
import kitchen4afterC1024wwebp from "./kitchen4-afterC-1024w.webp"
import kitchen4afterC1440wwebp from "./kitchen4-afterC-1440w.webp"
import kitchen4afterC1920wwebp from "./kitchen4-afterC-1920w.webp"
import kitchen4afterC2048wwebp from "./kitchen4-afterC-2048w.webp"
import kitchen4afterC480wavif from "./kitchen4-afterC-480w.avif"
import kitchen4afterC768wavif from "./kitchen4-afterC-768w.avif"
import kitchen4afterC1024wavif from "./kitchen4-afterC-1024w.avif"
import kitchen4afterC1440wavif from "./kitchen4-afterC-1440w.avif"
import kitchen4afterC1920wavif from "./kitchen4-afterC-1920w.avif"
import kitchen4afterC2048wavif from "./kitchen4-afterC-2048w.avif"
import kitchen4afterDjpg from "../kitchen4-afterD.jpg"
import kitchen4afterD480wpng from "./kitchen4-afterD-480w.png"
import kitchen4afterD768wpng from "./kitchen4-afterD-768w.png"
import kitchen4afterD1024wpng from "./kitchen4-afterD-1024w.png"
import kitchen4afterD1440wpng from "./kitchen4-afterD-1440w.png"
import kitchen4afterD1920wpng from "./kitchen4-afterD-1920w.png"
import kitchen4afterD2048wpng from "./kitchen4-afterD-2048w.png"
import kitchen4afterD480wwebp from "./kitchen4-afterD-480w.webp"
import kitchen4afterD768wwebp from "./kitchen4-afterD-768w.webp"
import kitchen4afterD1024wwebp from "./kitchen4-afterD-1024w.webp"
import kitchen4afterD1440wwebp from "./kitchen4-afterD-1440w.webp"
import kitchen4afterD1920wwebp from "./kitchen4-afterD-1920w.webp"
import kitchen4afterD2048wwebp from "./kitchen4-afterD-2048w.webp"
import kitchen4afterD480wavif from "./kitchen4-afterD-480w.avif"
import kitchen4afterD768wavif from "./kitchen4-afterD-768w.avif"
import kitchen4afterD1024wavif from "./kitchen4-afterD-1024w.avif"
import kitchen4afterD1440wavif from "./kitchen4-afterD-1440w.avif"
import kitchen4afterD1920wavif from "./kitchen4-afterD-1920w.avif"
import kitchen4afterD2048wavif from "./kitchen4-afterD-2048w.avif"
import kitchen4afterEjpg from "../kitchen4-afterE.jpg"
import kitchen4afterE480wpng from "./kitchen4-afterE-480w.png"
import kitchen4afterE768wpng from "./kitchen4-afterE-768w.png"
import kitchen4afterE1024wpng from "./kitchen4-afterE-1024w.png"
import kitchen4afterE1440wpng from "./kitchen4-afterE-1440w.png"
import kitchen4afterE1920wpng from "./kitchen4-afterE-1920w.png"
import kitchen4afterE2048wpng from "./kitchen4-afterE-2048w.png"
import kitchen4afterE480wwebp from "./kitchen4-afterE-480w.webp"
import kitchen4afterE768wwebp from "./kitchen4-afterE-768w.webp"
import kitchen4afterE1024wwebp from "./kitchen4-afterE-1024w.webp"
import kitchen4afterE1440wwebp from "./kitchen4-afterE-1440w.webp"
import kitchen4afterE1920wwebp from "./kitchen4-afterE-1920w.webp"
import kitchen4afterE2048wwebp from "./kitchen4-afterE-2048w.webp"
import kitchen4afterE480wavif from "./kitchen4-afterE-480w.avif"
import kitchen4afterE768wavif from "./kitchen4-afterE-768w.avif"
import kitchen4afterE1024wavif from "./kitchen4-afterE-1024w.avif"
import kitchen4afterE1440wavif from "./kitchen4-afterE-1440w.avif"
import kitchen4afterE1920wavif from "./kitchen4-afterE-1920w.avif"
import kitchen4afterE2048wavif from "./kitchen4-afterE-2048w.avif"
import kitchen4afterFjpg from "../kitchen4-afterF.jpg"
import kitchen4afterF480wpng from "./kitchen4-afterF-480w.png"
import kitchen4afterF768wpng from "./kitchen4-afterF-768w.png"
import kitchen4afterF1024wpng from "./kitchen4-afterF-1024w.png"
import kitchen4afterF1440wpng from "./kitchen4-afterF-1440w.png"
import kitchen4afterF1920wpng from "./kitchen4-afterF-1920w.png"
import kitchen4afterF2048wpng from "./kitchen4-afterF-2048w.png"
import kitchen4afterF480wwebp from "./kitchen4-afterF-480w.webp"
import kitchen4afterF768wwebp from "./kitchen4-afterF-768w.webp"
import kitchen4afterF1024wwebp from "./kitchen4-afterF-1024w.webp"
import kitchen4afterF1440wwebp from "./kitchen4-afterF-1440w.webp"
import kitchen4afterF1920wwebp from "./kitchen4-afterF-1920w.webp"
import kitchen4afterF2048wwebp from "./kitchen4-afterF-2048w.webp"
import kitchen4afterF480wavif from "./kitchen4-afterF-480w.avif"
import kitchen4afterF768wavif from "./kitchen4-afterF-768w.avif"
import kitchen4afterF1024wavif from "./kitchen4-afterF-1024w.avif"
import kitchen4afterF1440wavif from "./kitchen4-afterF-1440w.avif"
import kitchen4afterF1920wavif from "./kitchen4-afterF-1920w.avif"
import kitchen4afterF2048wavif from "./kitchen4-afterF-2048w.avif"
import kitchen5afterAjpg from "../kitchen5-afterA.jpg"
import kitchen5afterA480wpng from "./kitchen5-afterA-480w.png"
import kitchen5afterA768wpng from "./kitchen5-afterA-768w.png"
import kitchen5afterA1024wpng from "./kitchen5-afterA-1024w.png"
import kitchen5afterA1440wpng from "./kitchen5-afterA-1440w.png"
import kitchen5afterA1920wpng from "./kitchen5-afterA-1920w.png"
import kitchen5afterA2048wpng from "./kitchen5-afterA-2048w.png"
import kitchen5afterA480wwebp from "./kitchen5-afterA-480w.webp"
import kitchen5afterA768wwebp from "./kitchen5-afterA-768w.webp"
import kitchen5afterA1024wwebp from "./kitchen5-afterA-1024w.webp"
import kitchen5afterA1440wwebp from "./kitchen5-afterA-1440w.webp"
import kitchen5afterA1920wwebp from "./kitchen5-afterA-1920w.webp"
import kitchen5afterA2048wwebp from "./kitchen5-afterA-2048w.webp"
import kitchen5afterA480wavif from "./kitchen5-afterA-480w.avif"
import kitchen5afterA768wavif from "./kitchen5-afterA-768w.avif"
import kitchen5afterA1024wavif from "./kitchen5-afterA-1024w.avif"
import kitchen5afterA1440wavif from "./kitchen5-afterA-1440w.avif"
import kitchen5afterA1920wavif from "./kitchen5-afterA-1920w.avif"
import kitchen5afterA2048wavif from "./kitchen5-afterA-2048w.avif"
import kitchen5afterBjpg from "../kitchen5-afterB.jpg"
import kitchen5afterB480wpng from "./kitchen5-afterB-480w.png"
import kitchen5afterB768wpng from "./kitchen5-afterB-768w.png"
import kitchen5afterB1024wpng from "./kitchen5-afterB-1024w.png"
import kitchen5afterB1440wpng from "./kitchen5-afterB-1440w.png"
import kitchen5afterB1920wpng from "./kitchen5-afterB-1920w.png"
import kitchen5afterB2048wpng from "./kitchen5-afterB-2048w.png"
import kitchen5afterB480wwebp from "./kitchen5-afterB-480w.webp"
import kitchen5afterB768wwebp from "./kitchen5-afterB-768w.webp"
import kitchen5afterB1024wwebp from "./kitchen5-afterB-1024w.webp"
import kitchen5afterB1440wwebp from "./kitchen5-afterB-1440w.webp"
import kitchen5afterB1920wwebp from "./kitchen5-afterB-1920w.webp"
import kitchen5afterB2048wwebp from "./kitchen5-afterB-2048w.webp"
import kitchen5afterB480wavif from "./kitchen5-afterB-480w.avif"
import kitchen5afterB768wavif from "./kitchen5-afterB-768w.avif"
import kitchen5afterB1024wavif from "./kitchen5-afterB-1024w.avif"
import kitchen5afterB1440wavif from "./kitchen5-afterB-1440w.avif"
import kitchen5afterB1920wavif from "./kitchen5-afterB-1920w.avif"
import kitchen5afterB2048wavif from "./kitchen5-afterB-2048w.avif"
import kitchen5afterCjpg from "../kitchen5-afterC.jpg"
import kitchen5afterC480wpng from "./kitchen5-afterC-480w.png"
import kitchen5afterC768wpng from "./kitchen5-afterC-768w.png"
import kitchen5afterC1024wpng from "./kitchen5-afterC-1024w.png"
import kitchen5afterC1440wpng from "./kitchen5-afterC-1440w.png"
import kitchen5afterC1920wpng from "./kitchen5-afterC-1920w.png"
import kitchen5afterC2048wpng from "./kitchen5-afterC-2048w.png"
import kitchen5afterC480wwebp from "./kitchen5-afterC-480w.webp"
import kitchen5afterC768wwebp from "./kitchen5-afterC-768w.webp"
import kitchen5afterC1024wwebp from "./kitchen5-afterC-1024w.webp"
import kitchen5afterC1440wwebp from "./kitchen5-afterC-1440w.webp"
import kitchen5afterC1920wwebp from "./kitchen5-afterC-1920w.webp"
import kitchen5afterC2048wwebp from "./kitchen5-afterC-2048w.webp"
import kitchen5afterC480wavif from "./kitchen5-afterC-480w.avif"
import kitchen5afterC768wavif from "./kitchen5-afterC-768w.avif"
import kitchen5afterC1024wavif from "./kitchen5-afterC-1024w.avif"
import kitchen5afterC1440wavif from "./kitchen5-afterC-1440w.avif"
import kitchen5afterC1920wavif from "./kitchen5-afterC-1920w.avif"
import kitchen5afterC2048wavif from "./kitchen5-afterC-2048w.avif"
import kitchen5afterDjpg from "../kitchen5-afterD.jpg"
import kitchen5afterD480wpng from "./kitchen5-afterD-480w.png"
import kitchen5afterD768wpng from "./kitchen5-afterD-768w.png"
import kitchen5afterD1024wpng from "./kitchen5-afterD-1024w.png"
import kitchen5afterD1440wpng from "./kitchen5-afterD-1440w.png"
import kitchen5afterD1920wpng from "./kitchen5-afterD-1920w.png"
import kitchen5afterD2048wpng from "./kitchen5-afterD-2048w.png"
import kitchen5afterD480wwebp from "./kitchen5-afterD-480w.webp"
import kitchen5afterD768wwebp from "./kitchen5-afterD-768w.webp"
import kitchen5afterD1024wwebp from "./kitchen5-afterD-1024w.webp"
import kitchen5afterD1440wwebp from "./kitchen5-afterD-1440w.webp"
import kitchen5afterD1920wwebp from "./kitchen5-afterD-1920w.webp"
import kitchen5afterD2048wwebp from "./kitchen5-afterD-2048w.webp"
import kitchen5afterD480wavif from "./kitchen5-afterD-480w.avif"
import kitchen5afterD768wavif from "./kitchen5-afterD-768w.avif"
import kitchen5afterD1024wavif from "./kitchen5-afterD-1024w.avif"
import kitchen5afterD1440wavif from "./kitchen5-afterD-1440w.avif"
import kitchen5afterD1920wavif from "./kitchen5-afterD-1920w.avif"
import kitchen5afterD2048wavif from "./kitchen5-afterD-2048w.avif"
import kitchen5afterEjpg from "../kitchen5-afterE.jpg"
import kitchen5afterE480wpng from "./kitchen5-afterE-480w.png"
import kitchen5afterE768wpng from "./kitchen5-afterE-768w.png"
import kitchen5afterE1024wpng from "./kitchen5-afterE-1024w.png"
import kitchen5afterE1440wpng from "./kitchen5-afterE-1440w.png"
import kitchen5afterE1920wpng from "./kitchen5-afterE-1920w.png"
import kitchen5afterE2048wpng from "./kitchen5-afterE-2048w.png"
import kitchen5afterE480wwebp from "./kitchen5-afterE-480w.webp"
import kitchen5afterE768wwebp from "./kitchen5-afterE-768w.webp"
import kitchen5afterE1024wwebp from "./kitchen5-afterE-1024w.webp"
import kitchen5afterE1440wwebp from "./kitchen5-afterE-1440w.webp"
import kitchen5afterE1920wwebp from "./kitchen5-afterE-1920w.webp"
import kitchen5afterE2048wwebp from "./kitchen5-afterE-2048w.webp"
import kitchen5afterE480wavif from "./kitchen5-afterE-480w.avif"
import kitchen5afterE768wavif from "./kitchen5-afterE-768w.avif"
import kitchen5afterE1024wavif from "./kitchen5-afterE-1024w.avif"
import kitchen5afterE1440wavif from "./kitchen5-afterE-1440w.avif"
import kitchen5afterE1920wavif from "./kitchen5-afterE-1920w.avif"
import kitchen5afterE2048wavif from "./kitchen5-afterE-2048w.avif"
import kitchen5beforeAjpg from "../kitchen5-beforeA.jpg"
import kitchen5beforeA480wpng from "./kitchen5-beforeA-480w.png"
import kitchen5beforeA768wpng from "./kitchen5-beforeA-768w.png"
import kitchen5beforeA1024wpng from "./kitchen5-beforeA-1024w.png"
import kitchen5beforeA1440wpng from "./kitchen5-beforeA-1440w.png"
import kitchen5beforeA1920wpng from "./kitchen5-beforeA-1920w.png"
import kitchen5beforeA2048wpng from "./kitchen5-beforeA-2048w.png"
import kitchen5beforeA480wwebp from "./kitchen5-beforeA-480w.webp"
import kitchen5beforeA768wwebp from "./kitchen5-beforeA-768w.webp"
import kitchen5beforeA1024wwebp from "./kitchen5-beforeA-1024w.webp"
import kitchen5beforeA1440wwebp from "./kitchen5-beforeA-1440w.webp"
import kitchen5beforeA1920wwebp from "./kitchen5-beforeA-1920w.webp"
import kitchen5beforeA2048wwebp from "./kitchen5-beforeA-2048w.webp"
import kitchen5beforeA480wavif from "./kitchen5-beforeA-480w.avif"
import kitchen5beforeA768wavif from "./kitchen5-beforeA-768w.avif"
import kitchen5beforeA1024wavif from "./kitchen5-beforeA-1024w.avif"
import kitchen5beforeA1440wavif from "./kitchen5-beforeA-1440w.avif"
import kitchen5beforeA1920wavif from "./kitchen5-beforeA-1920w.avif"
import kitchen5beforeA2048wavif from "./kitchen5-beforeA-2048w.avif"
import kitchen5beforeBjpg from "../kitchen5-beforeB.jpg"
import kitchen5beforeB480wpng from "./kitchen5-beforeB-480w.png"
import kitchen5beforeB768wpng from "./kitchen5-beforeB-768w.png"
import kitchen5beforeB1024wpng from "./kitchen5-beforeB-1024w.png"
import kitchen5beforeB1440wpng from "./kitchen5-beforeB-1440w.png"
import kitchen5beforeB1920wpng from "./kitchen5-beforeB-1920w.png"
import kitchen5beforeB2048wpng from "./kitchen5-beforeB-2048w.png"
import kitchen5beforeB480wwebp from "./kitchen5-beforeB-480w.webp"
import kitchen5beforeB768wwebp from "./kitchen5-beforeB-768w.webp"
import kitchen5beforeB1024wwebp from "./kitchen5-beforeB-1024w.webp"
import kitchen5beforeB1440wwebp from "./kitchen5-beforeB-1440w.webp"
import kitchen5beforeB1920wwebp from "./kitchen5-beforeB-1920w.webp"
import kitchen5beforeB2048wwebp from "./kitchen5-beforeB-2048w.webp"
import kitchen5beforeB480wavif from "./kitchen5-beforeB-480w.avif"
import kitchen5beforeB768wavif from "./kitchen5-beforeB-768w.avif"
import kitchen5beforeB1024wavif from "./kitchen5-beforeB-1024w.avif"
import kitchen5beforeB1440wavif from "./kitchen5-beforeB-1440w.avif"
import kitchen5beforeB1920wavif from "./kitchen5-beforeB-1920w.avif"
import kitchen5beforeB2048wavif from "./kitchen5-beforeB-2048w.avif"
import kitchen5beforeCjpg from "../kitchen5-beforeC.jpg"
import kitchen5beforeC480wpng from "./kitchen5-beforeC-480w.png"
import kitchen5beforeC768wpng from "./kitchen5-beforeC-768w.png"
import kitchen5beforeC1024wpng from "./kitchen5-beforeC-1024w.png"
import kitchen5beforeC1440wpng from "./kitchen5-beforeC-1440w.png"
import kitchen5beforeC1920wpng from "./kitchen5-beforeC-1920w.png"
import kitchen5beforeC2048wpng from "./kitchen5-beforeC-2048w.png"
import kitchen5beforeC480wwebp from "./kitchen5-beforeC-480w.webp"
import kitchen5beforeC768wwebp from "./kitchen5-beforeC-768w.webp"
import kitchen5beforeC1024wwebp from "./kitchen5-beforeC-1024w.webp"
import kitchen5beforeC1440wwebp from "./kitchen5-beforeC-1440w.webp"
import kitchen5beforeC1920wwebp from "./kitchen5-beforeC-1920w.webp"
import kitchen5beforeC2048wwebp from "./kitchen5-beforeC-2048w.webp"
import kitchen5beforeC480wavif from "./kitchen5-beforeC-480w.avif"
import kitchen5beforeC768wavif from "./kitchen5-beforeC-768w.avif"
import kitchen5beforeC1024wavif from "./kitchen5-beforeC-1024w.avif"
import kitchen5beforeC1440wavif from "./kitchen5-beforeC-1440w.avif"
import kitchen5beforeC1920wavif from "./kitchen5-beforeC-1920w.avif"
import kitchen5beforeC2048wavif from "./kitchen5-beforeC-2048w.avif"
import kitchen5beforeDjpg from "../kitchen5-beforeD.jpg"
import kitchen5beforeD480wpng from "./kitchen5-beforeD-480w.png"
import kitchen5beforeD768wpng from "./kitchen5-beforeD-768w.png"
import kitchen5beforeD1024wpng from "./kitchen5-beforeD-1024w.png"
import kitchen5beforeD1440wpng from "./kitchen5-beforeD-1440w.png"
import kitchen5beforeD1920wpng from "./kitchen5-beforeD-1920w.png"
import kitchen5beforeD2048wpng from "./kitchen5-beforeD-2048w.png"
import kitchen5beforeD480wwebp from "./kitchen5-beforeD-480w.webp"
import kitchen5beforeD768wwebp from "./kitchen5-beforeD-768w.webp"
import kitchen5beforeD1024wwebp from "./kitchen5-beforeD-1024w.webp"
import kitchen5beforeD1440wwebp from "./kitchen5-beforeD-1440w.webp"
import kitchen5beforeD1920wwebp from "./kitchen5-beforeD-1920w.webp"
import kitchen5beforeD2048wwebp from "./kitchen5-beforeD-2048w.webp"
import kitchen5beforeD480wavif from "./kitchen5-beforeD-480w.avif"
import kitchen5beforeD768wavif from "./kitchen5-beforeD-768w.avif"
import kitchen5beforeD1024wavif from "./kitchen5-beforeD-1024w.avif"
import kitchen5beforeD1440wavif from "./kitchen5-beforeD-1440w.avif"
import kitchen5beforeD1920wavif from "./kitchen5-beforeD-1920w.avif"
import kitchen5beforeD2048wavif from "./kitchen5-beforeD-2048w.avif"
import kitchen5beforeEjpg from "../kitchen5-beforeE.jpg"
import kitchen5beforeE480wpng from "./kitchen5-beforeE-480w.png"
import kitchen5beforeE768wpng from "./kitchen5-beforeE-768w.png"
import kitchen5beforeE1024wpng from "./kitchen5-beforeE-1024w.png"
import kitchen5beforeE1440wpng from "./kitchen5-beforeE-1440w.png"
import kitchen5beforeE1920wpng from "./kitchen5-beforeE-1920w.png"
import kitchen5beforeE2048wpng from "./kitchen5-beforeE-2048w.png"
import kitchen5beforeE480wwebp from "./kitchen5-beforeE-480w.webp"
import kitchen5beforeE768wwebp from "./kitchen5-beforeE-768w.webp"
import kitchen5beforeE1024wwebp from "./kitchen5-beforeE-1024w.webp"
import kitchen5beforeE1440wwebp from "./kitchen5-beforeE-1440w.webp"
import kitchen5beforeE1920wwebp from "./kitchen5-beforeE-1920w.webp"
import kitchen5beforeE2048wwebp from "./kitchen5-beforeE-2048w.webp"
import kitchen5beforeE480wavif from "./kitchen5-beforeE-480w.avif"
import kitchen5beforeE768wavif from "./kitchen5-beforeE-768w.avif"
import kitchen5beforeE1024wavif from "./kitchen5-beforeE-1024w.avif"
import kitchen5beforeE1440wavif from "./kitchen5-beforeE-1440w.avif"
import kitchen5beforeE1920wavif from "./kitchen5-beforeE-1920w.avif"
import kitchen5beforeE2048wavif from "./kitchen5-beforeE-2048w.avif"
import laundry1afterAjpg from "../laundry1-afterA.jpg"
import laundry1afterA480wpng from "./laundry1-afterA-480w.png"
import laundry1afterA768wpng from "./laundry1-afterA-768w.png"
import laundry1afterA1024wpng from "./laundry1-afterA-1024w.png"
import laundry1afterA1440wpng from "./laundry1-afterA-1440w.png"
import laundry1afterA1920wpng from "./laundry1-afterA-1920w.png"
import laundry1afterA2048wpng from "./laundry1-afterA-2048w.png"
import laundry1afterA480wwebp from "./laundry1-afterA-480w.webp"
import laundry1afterA768wwebp from "./laundry1-afterA-768w.webp"
import laundry1afterA1024wwebp from "./laundry1-afterA-1024w.webp"
import laundry1afterA1440wwebp from "./laundry1-afterA-1440w.webp"
import laundry1afterA1920wwebp from "./laundry1-afterA-1920w.webp"
import laundry1afterA2048wwebp from "./laundry1-afterA-2048w.webp"
import laundry1afterA480wavif from "./laundry1-afterA-480w.avif"
import laundry1afterA768wavif from "./laundry1-afterA-768w.avif"
import laundry1afterA1024wavif from "./laundry1-afterA-1024w.avif"
import laundry1afterA1440wavif from "./laundry1-afterA-1440w.avif"
import laundry1afterA1920wavif from "./laundry1-afterA-1920w.avif"
import laundry1afterA2048wavif from "./laundry1-afterA-2048w.avif"
import laundry1beforeAjpg from "../laundry1-beforeA.jpg"
import laundry1beforeA480wpng from "./laundry1-beforeA-480w.png"
import laundry1beforeA768wpng from "./laundry1-beforeA-768w.png"
import laundry1beforeA1024wpng from "./laundry1-beforeA-1024w.png"
import laundry1beforeA1440wpng from "./laundry1-beforeA-1440w.png"
import laundry1beforeA1920wpng from "./laundry1-beforeA-1920w.png"
import laundry1beforeA2048wpng from "./laundry1-beforeA-2048w.png"
import laundry1beforeA480wwebp from "./laundry1-beforeA-480w.webp"
import laundry1beforeA768wwebp from "./laundry1-beforeA-768w.webp"
import laundry1beforeA1024wwebp from "./laundry1-beforeA-1024w.webp"
import laundry1beforeA1440wwebp from "./laundry1-beforeA-1440w.webp"
import laundry1beforeA1920wwebp from "./laundry1-beforeA-1920w.webp"
import laundry1beforeA2048wwebp from "./laundry1-beforeA-2048w.webp"
import laundry1beforeA480wavif from "./laundry1-beforeA-480w.avif"
import laundry1beforeA768wavif from "./laundry1-beforeA-768w.avif"
import laundry1beforeA1024wavif from "./laundry1-beforeA-1024w.avif"
import laundry1beforeA1440wavif from "./laundry1-beforeA-1440w.avif"
import laundry1beforeA1920wavif from "./laundry1-beforeA-1920w.avif"
import laundry1beforeA2048wavif from "./laundry1-beforeA-2048w.avif"
import laundry2afterjpg from "../laundry2-after.jpg"
import laundry2after480wpng from "./laundry2-after-480w.png"
import laundry2after768wpng from "./laundry2-after-768w.png"
import laundry2after1024wpng from "./laundry2-after-1024w.png"
import laundry2after1440wpng from "./laundry2-after-1440w.png"
import laundry2after1920wpng from "./laundry2-after-1920w.png"
import laundry2after2048wpng from "./laundry2-after-2048w.png"
import laundry2after480wwebp from "./laundry2-after-480w.webp"
import laundry2after768wwebp from "./laundry2-after-768w.webp"
import laundry2after1024wwebp from "./laundry2-after-1024w.webp"
import laundry2after1440wwebp from "./laundry2-after-1440w.webp"
import laundry2after1920wwebp from "./laundry2-after-1920w.webp"
import laundry2after2048wwebp from "./laundry2-after-2048w.webp"
import laundry2after480wavif from "./laundry2-after-480w.avif"
import laundry2after768wavif from "./laundry2-after-768w.avif"
import laundry2after1024wavif from "./laundry2-after-1024w.avif"
import laundry2after1440wavif from "./laundry2-after-1440w.avif"
import laundry2after1920wavif from "./laundry2-after-1920w.avif"
import laundry2after2048wavif from "./laundry2-after-2048w.avif"
import laundry2afterBjpg from "../laundry2-afterB.jpg"
import laundry2afterB480wpng from "./laundry2-afterB-480w.png"
import laundry2afterB768wpng from "./laundry2-afterB-768w.png"
import laundry2afterB1024wpng from "./laundry2-afterB-1024w.png"
import laundry2afterB1440wpng from "./laundry2-afterB-1440w.png"
import laundry2afterB1920wpng from "./laundry2-afterB-1920w.png"
import laundry2afterB2048wpng from "./laundry2-afterB-2048w.png"
import laundry2afterB480wwebp from "./laundry2-afterB-480w.webp"
import laundry2afterB768wwebp from "./laundry2-afterB-768w.webp"
import laundry2afterB1024wwebp from "./laundry2-afterB-1024w.webp"
import laundry2afterB1440wwebp from "./laundry2-afterB-1440w.webp"
import laundry2afterB1920wwebp from "./laundry2-afterB-1920w.webp"
import laundry2afterB2048wwebp from "./laundry2-afterB-2048w.webp"
import laundry2afterB480wavif from "./laundry2-afterB-480w.avif"
import laundry2afterB768wavif from "./laundry2-afterB-768w.avif"
import laundry2afterB1024wavif from "./laundry2-afterB-1024w.avif"
import laundry2afterB1440wavif from "./laundry2-afterB-1440w.avif"
import laundry2afterB1920wavif from "./laundry2-afterB-1920w.avif"
import laundry2afterB2048wavif from "./laundry2-afterB-2048w.avif"
import laundry2beforejpg from "../laundry2-before.jpg"
import laundry2before480wpng from "./laundry2-before-480w.png"
import laundry2before768wpng from "./laundry2-before-768w.png"
import laundry2before1024wpng from "./laundry2-before-1024w.png"
import laundry2before1440wpng from "./laundry2-before-1440w.png"
import laundry2before1920wpng from "./laundry2-before-1920w.png"
import laundry2before2048wpng from "./laundry2-before-2048w.png"
import laundry2before480wwebp from "./laundry2-before-480w.webp"
import laundry2before768wwebp from "./laundry2-before-768w.webp"
import laundry2before1024wwebp from "./laundry2-before-1024w.webp"
import laundry2before1440wwebp from "./laundry2-before-1440w.webp"
import laundry2before1920wwebp from "./laundry2-before-1920w.webp"
import laundry2before2048wwebp from "./laundry2-before-2048w.webp"
import laundry2before480wavif from "./laundry2-before-480w.avif"
import laundry2before768wavif from "./laundry2-before-768w.avif"
import laundry2before1024wavif from "./laundry2-before-1024w.avif"
import laundry2before1440wavif from "./laundry2-before-1440w.avif"
import laundry2before1920wavif from "./laundry2-before-1920w.avif"
import laundry2before2048wavif from "./laundry2-before-2048w.avif"
import laundry2beforeBjpg from "../laundry2-beforeB.jpg"
import laundry2beforeB480wpng from "./laundry2-beforeB-480w.png"
import laundry2beforeB768wpng from "./laundry2-beforeB-768w.png"
import laundry2beforeB1024wpng from "./laundry2-beforeB-1024w.png"
import laundry2beforeB1440wpng from "./laundry2-beforeB-1440w.png"
import laundry2beforeB1920wpng from "./laundry2-beforeB-1920w.png"
import laundry2beforeB2048wpng from "./laundry2-beforeB-2048w.png"
import laundry2beforeB480wwebp from "./laundry2-beforeB-480w.webp"
import laundry2beforeB768wwebp from "./laundry2-beforeB-768w.webp"
import laundry2beforeB1024wwebp from "./laundry2-beforeB-1024w.webp"
import laundry2beforeB1440wwebp from "./laundry2-beforeB-1440w.webp"
import laundry2beforeB1920wwebp from "./laundry2-beforeB-1920w.webp"
import laundry2beforeB2048wwebp from "./laundry2-beforeB-2048w.webp"
import laundry2beforeB480wavif from "./laundry2-beforeB-480w.avif"
import laundry2beforeB768wavif from "./laundry2-beforeB-768w.avif"
import laundry2beforeB1024wavif from "./laundry2-beforeB-1024w.avif"
import laundry2beforeB1440wavif from "./laundry2-beforeB-1440w.avif"
import laundry2beforeB1920wavif from "./laundry2-beforeB-1920w.avif"
import laundry2beforeB2048wavif from "./laundry2-beforeB-2048w.avif"
import learosejpg from "../learose.jpg"
import learose480wpng from "./learose-480w.png"
import learose768wpng from "./learose-768w.png"
import learose1024wpng from "./learose-1024w.png"
import learose1440wpng from "./learose-1440w.png"
import learose1920wpng from "./learose-1920w.png"
import learose2048wpng from "./learose-2048w.png"
import learose480wwebp from "./learose-480w.webp"
import learose768wwebp from "./learose-768w.webp"
import learose1024wwebp from "./learose-1024w.webp"
import learose1440wwebp from "./learose-1440w.webp"
import learose1920wwebp from "./learose-1920w.webp"
import learose2048wwebp from "./learose-2048w.webp"
import learose480wavif from "./learose-480w.avif"
import learose768wavif from "./learose-768w.avif"
import learose1024wavif from "./learose-1024w.avif"
import learose1440wavif from "./learose-1440w.avif"
import learose1920wavif from "./learose-1920w.avif"
import learose2048wavif from "./learose-2048w.avif"
import learose2jpg from "../learose2.jpg"
import learose2480wpng from "./learose2-480w.png"
import learose2768wpng from "./learose2-768w.png"
import learose21024wpng from "./learose2-1024w.png"
import learose21440wpng from "./learose2-1440w.png"
import learose21920wpng from "./learose2-1920w.png"
import learose22048wpng from "./learose2-2048w.png"
import learose2480wwebp from "./learose2-480w.webp"
import learose2768wwebp from "./learose2-768w.webp"
import learose21024wwebp from "./learose2-1024w.webp"
import learose21440wwebp from "./learose2-1440w.webp"
import learose21920wwebp from "./learose2-1920w.webp"
import learose22048wwebp from "./learose2-2048w.webp"
import learose2480wavif from "./learose2-480w.avif"
import learose2768wavif from "./learose2-768w.avif"
import learose21024wavif from "./learose2-1024w.avif"
import learose21440wavif from "./learose2-1440w.avif"
import learose21920wavif from "./learose2-1920w.avif"
import learose22048wavif from "./learose2-2048w.avif"
import learose3jpg from "../learose3.jpg"
import learose3480wpng from "./learose3-480w.png"
import learose3768wpng from "./learose3-768w.png"
import learose31024wpng from "./learose3-1024w.png"
import learose31440wpng from "./learose3-1440w.png"
import learose31920wpng from "./learose3-1920w.png"
import learose32048wpng from "./learose3-2048w.png"
import learose3480wwebp from "./learose3-480w.webp"
import learose3768wwebp from "./learose3-768w.webp"
import learose31024wwebp from "./learose3-1024w.webp"
import learose31440wwebp from "./learose3-1440w.webp"
import learose31920wwebp from "./learose3-1920w.webp"
import learose32048wwebp from "./learose3-2048w.webp"
import learose3480wavif from "./learose3-480w.avif"
import learose3768wavif from "./learose3-768w.avif"
import learose31024wavif from "./learose3-1024w.avif"
import learose31440wavif from "./learose3-1440w.avif"
import learose31920wavif from "./learose3-1920w.avif"
import learose32048wavif from "./learose3-2048w.avif"
import learose4jpg from "../learose4.jpg"
import learose4480wpng from "./learose4-480w.png"
import learose4768wpng from "./learose4-768w.png"
import learose41024wpng from "./learose4-1024w.png"
import learose41440wpng from "./learose4-1440w.png"
import learose41920wpng from "./learose4-1920w.png"
import learose42048wpng from "./learose4-2048w.png"
import learose4480wwebp from "./learose4-480w.webp"
import learose4768wwebp from "./learose4-768w.webp"
import learose41024wwebp from "./learose4-1024w.webp"
import learose41440wwebp from "./learose4-1440w.webp"
import learose41920wwebp from "./learose4-1920w.webp"
import learose42048wwebp from "./learose4-2048w.webp"
import learose4480wavif from "./learose4-480w.avif"
import learose4768wavif from "./learose4-768w.avif"
import learose41024wavif from "./learose4-1024w.avif"
import learose41440wavif from "./learose4-1440w.avif"
import learose41920wavif from "./learose4-1920w.avif"
import learose42048wavif from "./learose4-2048w.avif"
import learose5jpg from "../learose5.jpg"
import learose5480wpng from "./learose5-480w.png"
import learose5768wpng from "./learose5-768w.png"
import learose51024wpng from "./learose5-1024w.png"
import learose51440wpng from "./learose5-1440w.png"
import learose51920wpng from "./learose5-1920w.png"
import learose52048wpng from "./learose5-2048w.png"
import learose5480wwebp from "./learose5-480w.webp"
import learose5768wwebp from "./learose5-768w.webp"
import learose51024wwebp from "./learose5-1024w.webp"
import learose51440wwebp from "./learose5-1440w.webp"
import learose51920wwebp from "./learose5-1920w.webp"
import learose52048wwebp from "./learose5-2048w.webp"
import learose5480wavif from "./learose5-480w.avif"
import learose5768wavif from "./learose5-768w.avif"
import learose51024wavif from "./learose5-1024w.avif"
import learose51440wavif from "./learose5-1440w.avif"
import learose51920wavif from "./learose5-1920w.avif"
import learose52048wavif from "./learose5-2048w.avif"
import logocardjpg from "../logo-card.jpg"
import logocard480wpng from "./logo-card-480w.png"
import logocard768wpng from "./logo-card-768w.png"
import logocard1024wpng from "./logo-card-1024w.png"
import logocard1440wpng from "./logo-card-1440w.png"
import logocard1920wpng from "./logo-card-1920w.png"
import logocard2048wpng from "./logo-card-2048w.png"
import logocard480wwebp from "./logo-card-480w.webp"
import logocard768wwebp from "./logo-card-768w.webp"
import logocard1024wwebp from "./logo-card-1024w.webp"
import logocard1440wwebp from "./logo-card-1440w.webp"
import logocard1920wwebp from "./logo-card-1920w.webp"
import logocard2048wwebp from "./logo-card-2048w.webp"
import logocard480wavif from "./logo-card-480w.avif"
import logocard768wavif from "./logo-card-768w.avif"
import logocard1024wavif from "./logo-card-1024w.avif"
import logocard1440wavif from "./logo-card-1440w.avif"
import logocard1920wavif from "./logo-card-1920w.avif"
import logocard2048wavif from "./logo-card-2048w.avif"
import logolgjpg from "../logo-lg.jpg"
import logolg480wpng from "./logo-lg-480w.png"
import logolg768wpng from "./logo-lg-768w.png"
import logolg1024wpng from "./logo-lg-1024w.png"
import logolg1440wpng from "./logo-lg-1440w.png"
import logolg1920wpng from "./logo-lg-1920w.png"
import logolg2048wpng from "./logo-lg-2048w.png"
import logolg480wwebp from "./logo-lg-480w.webp"
import logolg768wwebp from "./logo-lg-768w.webp"
import logolg1024wwebp from "./logo-lg-1024w.webp"
import logolg1440wwebp from "./logo-lg-1440w.webp"
import logolg1920wwebp from "./logo-lg-1920w.webp"
import logolg2048wwebp from "./logo-lg-2048w.webp"
import logolg480wavif from "./logo-lg-480w.avif"
import logolg768wavif from "./logo-lg-768w.avif"
import logolg1024wavif from "./logo-lg-1024w.avif"
import logolg1440wavif from "./logo-lg-1440w.avif"
import logolg1920wavif from "./logo-lg-1920w.avif"
import logolg2048wavif from "./logo-lg-2048w.avif"
import logomdjpg from "../logo-md.jpg"
import logomd480wpng from "./logo-md-480w.png"
import logomd768wpng from "./logo-md-768w.png"
import logomd1024wpng from "./logo-md-1024w.png"
import logomd1440wpng from "./logo-md-1440w.png"
import logomd1920wpng from "./logo-md-1920w.png"
import logomd2048wpng from "./logo-md-2048w.png"
import logomd480wwebp from "./logo-md-480w.webp"
import logomd768wwebp from "./logo-md-768w.webp"
import logomd1024wwebp from "./logo-md-1024w.webp"
import logomd1440wwebp from "./logo-md-1440w.webp"
import logomd1920wwebp from "./logo-md-1920w.webp"
import logomd2048wwebp from "./logo-md-2048w.webp"
import logomd480wavif from "./logo-md-480w.avif"
import logomd768wavif from "./logo-md-768w.avif"
import logomd1024wavif from "./logo-md-1024w.avif"
import logomd1440wavif from "./logo-md-1440w.avif"
import logomd1920wavif from "./logo-md-1920w.avif"
import logomd2048wavif from "./logo-md-2048w.avif"
import logoxljpg from "../logo-xl.jpg"
import logoxl480wpng from "./logo-xl-480w.png"
import logoxl768wpng from "./logo-xl-768w.png"
import logoxl1024wpng from "./logo-xl-1024w.png"
import logoxl1440wpng from "./logo-xl-1440w.png"
import logoxl1920wpng from "./logo-xl-1920w.png"
import logoxl2048wpng from "./logo-xl-2048w.png"
import logoxl480wwebp from "./logo-xl-480w.webp"
import logoxl768wwebp from "./logo-xl-768w.webp"
import logoxl1024wwebp from "./logo-xl-1024w.webp"
import logoxl1440wwebp from "./logo-xl-1440w.webp"
import logoxl1920wwebp from "./logo-xl-1920w.webp"
import logoxl2048wwebp from "./logo-xl-2048w.webp"
import logoxl480wavif from "./logo-xl-480w.avif"
import logoxl768wavif from "./logo-xl-768w.avif"
import logoxl1024wavif from "./logo-xl-1024w.avif"
import logoxl1440wavif from "./logo-xl-1440w.avif"
import logoxl1920wavif from "./logo-xl-1920w.avif"
import logoxl2048wavif from "./logo-xl-2048w.avif"
import miscbathtubAjpg from "../misc-bathtubA.jpg"
import miscbathtubA480wpng from "./misc-bathtubA-480w.png"
import miscbathtubA768wpng from "./misc-bathtubA-768w.png"
import miscbathtubA1024wpng from "./misc-bathtubA-1024w.png"
import miscbathtubA1440wpng from "./misc-bathtubA-1440w.png"
import miscbathtubA1920wpng from "./misc-bathtubA-1920w.png"
import miscbathtubA2048wpng from "./misc-bathtubA-2048w.png"
import miscbathtubA480wwebp from "./misc-bathtubA-480w.webp"
import miscbathtubA768wwebp from "./misc-bathtubA-768w.webp"
import miscbathtubA1024wwebp from "./misc-bathtubA-1024w.webp"
import miscbathtubA1440wwebp from "./misc-bathtubA-1440w.webp"
import miscbathtubA1920wwebp from "./misc-bathtubA-1920w.webp"
import miscbathtubA2048wwebp from "./misc-bathtubA-2048w.webp"
import miscbathtubA480wavif from "./misc-bathtubA-480w.avif"
import miscbathtubA768wavif from "./misc-bathtubA-768w.avif"
import miscbathtubA1024wavif from "./misc-bathtubA-1024w.avif"
import miscbathtubA1440wavif from "./misc-bathtubA-1440w.avif"
import miscbathtubA1920wavif from "./misc-bathtubA-1920w.avif"
import miscbathtubA2048wavif from "./misc-bathtubA-2048w.avif"
import miscbooks1jpg from "../misc-books1.jpg"
import miscbooks1480wpng from "./misc-books1-480w.png"
import miscbooks1768wpng from "./misc-books1-768w.png"
import miscbooks11024wpng from "./misc-books1-1024w.png"
import miscbooks11440wpng from "./misc-books1-1440w.png"
import miscbooks11920wpng from "./misc-books1-1920w.png"
import miscbooks12048wpng from "./misc-books1-2048w.png"
import miscbooks1480wwebp from "./misc-books1-480w.webp"
import miscbooks1768wwebp from "./misc-books1-768w.webp"
import miscbooks11024wwebp from "./misc-books1-1024w.webp"
import miscbooks11440wwebp from "./misc-books1-1440w.webp"
import miscbooks11920wwebp from "./misc-books1-1920w.webp"
import miscbooks12048wwebp from "./misc-books1-2048w.webp"
import miscbooks1480wavif from "./misc-books1-480w.avif"
import miscbooks1768wavif from "./misc-books1-768w.avif"
import miscbooks11024wavif from "./misc-books1-1024w.avif"
import miscbooks11440wavif from "./misc-books1-1440w.avif"
import miscbooks11920wavif from "./misc-books1-1920w.avif"
import miscbooks12048wavif from "./misc-books1-2048w.avif"
import miscbooks2jpg from "../misc-books2.jpg"
import miscbooks2480wpng from "./misc-books2-480w.png"
import miscbooks2768wpng from "./misc-books2-768w.png"
import miscbooks21024wpng from "./misc-books2-1024w.png"
import miscbooks21440wpng from "./misc-books2-1440w.png"
import miscbooks21920wpng from "./misc-books2-1920w.png"
import miscbooks22048wpng from "./misc-books2-2048w.png"
import miscbooks2480wwebp from "./misc-books2-480w.webp"
import miscbooks2768wwebp from "./misc-books2-768w.webp"
import miscbooks21024wwebp from "./misc-books2-1024w.webp"
import miscbooks21440wwebp from "./misc-books2-1440w.webp"
import miscbooks21920wwebp from "./misc-books2-1920w.webp"
import miscbooks22048wwebp from "./misc-books2-2048w.webp"
import miscbooks2480wavif from "./misc-books2-480w.avif"
import miscbooks2768wavif from "./misc-books2-768w.avif"
import miscbooks21024wavif from "./misc-books2-1024w.avif"
import miscbooks21440wavif from "./misc-books2-1440w.avif"
import miscbooks21920wavif from "./misc-books2-1920w.avif"
import miscbooks22048wavif from "./misc-books2-2048w.avif"
import miscbooks3jpg from "../misc-books3.jpg"
import miscbooks3480wpng from "./misc-books3-480w.png"
import miscbooks3768wpng from "./misc-books3-768w.png"
import miscbooks31024wpng from "./misc-books3-1024w.png"
import miscbooks31440wpng from "./misc-books3-1440w.png"
import miscbooks31920wpng from "./misc-books3-1920w.png"
import miscbooks32048wpng from "./misc-books3-2048w.png"
import miscbooks3480wwebp from "./misc-books3-480w.webp"
import miscbooks3768wwebp from "./misc-books3-768w.webp"
import miscbooks31024wwebp from "./misc-books3-1024w.webp"
import miscbooks31440wwebp from "./misc-books3-1440w.webp"
import miscbooks31920wwebp from "./misc-books3-1920w.webp"
import miscbooks32048wwebp from "./misc-books3-2048w.webp"
import miscbooks3480wavif from "./misc-books3-480w.avif"
import miscbooks3768wavif from "./misc-books3-768w.avif"
import miscbooks31024wavif from "./misc-books3-1024w.avif"
import miscbooks31440wavif from "./misc-books3-1440w.avif"
import miscbooks31920wavif from "./misc-books3-1920w.avif"
import miscbooks32048wavif from "./misc-books3-2048w.avif"
import miscbooks4jpg from "../misc-books4.jpg"
import miscbooks4480wpng from "./misc-books4-480w.png"
import miscbooks4768wpng from "./misc-books4-768w.png"
import miscbooks41024wpng from "./misc-books4-1024w.png"
import miscbooks41440wpng from "./misc-books4-1440w.png"
import miscbooks41920wpng from "./misc-books4-1920w.png"
import miscbooks42048wpng from "./misc-books4-2048w.png"
import miscbooks4480wwebp from "./misc-books4-480w.webp"
import miscbooks4768wwebp from "./misc-books4-768w.webp"
import miscbooks41024wwebp from "./misc-books4-1024w.webp"
import miscbooks41440wwebp from "./misc-books4-1440w.webp"
import miscbooks41920wwebp from "./misc-books4-1920w.webp"
import miscbooks42048wwebp from "./misc-books4-2048w.webp"
import miscbooks4480wavif from "./misc-books4-480w.avif"
import miscbooks4768wavif from "./misc-books4-768w.avif"
import miscbooks41024wavif from "./misc-books4-1024w.avif"
import miscbooks41440wavif from "./misc-books4-1440w.avif"
import miscbooks41920wavif from "./misc-books4-1920w.avif"
import miscbooks42048wavif from "./misc-books4-2048w.avif"
import miscbooks5jpg from "../misc-books5.jpg"
import miscbooks5480wpng from "./misc-books5-480w.png"
import miscbooks5768wpng from "./misc-books5-768w.png"
import miscbooks51024wpng from "./misc-books5-1024w.png"
import miscbooks51440wpng from "./misc-books5-1440w.png"
import miscbooks51920wpng from "./misc-books5-1920w.png"
import miscbooks52048wpng from "./misc-books5-2048w.png"
import miscbooks5480wwebp from "./misc-books5-480w.webp"
import miscbooks5768wwebp from "./misc-books5-768w.webp"
import miscbooks51024wwebp from "./misc-books5-1024w.webp"
import miscbooks51440wwebp from "./misc-books5-1440w.webp"
import miscbooks51920wwebp from "./misc-books5-1920w.webp"
import miscbooks52048wwebp from "./misc-books5-2048w.webp"
import miscbooks5480wavif from "./misc-books5-480w.avif"
import miscbooks5768wavif from "./misc-books5-768w.avif"
import miscbooks51024wavif from "./misc-books5-1024w.avif"
import miscbooks51440wavif from "./misc-books5-1440w.avif"
import miscbooks51920wavif from "./misc-books5-1920w.avif"
import miscbooks52048wavif from "./misc-books5-2048w.avif"
import misccloset2jpg from "../misc-closet2.jpg"
import misccloset2480wpng from "./misc-closet2-480w.png"
import misccloset2768wpng from "./misc-closet2-768w.png"
import misccloset21024wpng from "./misc-closet2-1024w.png"
import misccloset21440wpng from "./misc-closet2-1440w.png"
import misccloset21920wpng from "./misc-closet2-1920w.png"
import misccloset22048wpng from "./misc-closet2-2048w.png"
import misccloset2480wwebp from "./misc-closet2-480w.webp"
import misccloset2768wwebp from "./misc-closet2-768w.webp"
import misccloset21024wwebp from "./misc-closet2-1024w.webp"
import misccloset21440wwebp from "./misc-closet2-1440w.webp"
import misccloset21920wwebp from "./misc-closet2-1920w.webp"
import misccloset22048wwebp from "./misc-closet2-2048w.webp"
import misccloset2480wavif from "./misc-closet2-480w.avif"
import misccloset2768wavif from "./misc-closet2-768w.avif"
import misccloset21024wavif from "./misc-closet2-1024w.avif"
import misccloset21440wavif from "./misc-closet2-1440w.avif"
import misccloset21920wavif from "./misc-closet2-1920w.avif"
import misccloset22048wavif from "./misc-closet2-2048w.avif"
import miscclosetAjpg from "../misc-closetA.jpg"
import miscclosetA480wpng from "./misc-closetA-480w.png"
import miscclosetA768wpng from "./misc-closetA-768w.png"
import miscclosetA1024wpng from "./misc-closetA-1024w.png"
import miscclosetA1440wpng from "./misc-closetA-1440w.png"
import miscclosetA1920wpng from "./misc-closetA-1920w.png"
import miscclosetA2048wpng from "./misc-closetA-2048w.png"
import miscclosetA480wwebp from "./misc-closetA-480w.webp"
import miscclosetA768wwebp from "./misc-closetA-768w.webp"
import miscclosetA1024wwebp from "./misc-closetA-1024w.webp"
import miscclosetA1440wwebp from "./misc-closetA-1440w.webp"
import miscclosetA1920wwebp from "./misc-closetA-1920w.webp"
import miscclosetA2048wwebp from "./misc-closetA-2048w.webp"
import miscclosetA480wavif from "./misc-closetA-480w.avif"
import miscclosetA768wavif from "./misc-closetA-768w.avif"
import miscclosetA1024wavif from "./misc-closetA-1024w.avif"
import miscclosetA1440wavif from "./misc-closetA-1440w.avif"
import miscclosetA1920wavif from "./misc-closetA-1920w.avif"
import miscclosetA2048wavif from "./misc-closetA-2048w.avif"
import miscclosetBjpg from "../misc-closetB.jpg"
import miscclosetB480wpng from "./misc-closetB-480w.png"
import miscclosetB768wpng from "./misc-closetB-768w.png"
import miscclosetB1024wpng from "./misc-closetB-1024w.png"
import miscclosetB1440wpng from "./misc-closetB-1440w.png"
import miscclosetB1920wpng from "./misc-closetB-1920w.png"
import miscclosetB2048wpng from "./misc-closetB-2048w.png"
import miscclosetB480wwebp from "./misc-closetB-480w.webp"
import miscclosetB768wwebp from "./misc-closetB-768w.webp"
import miscclosetB1024wwebp from "./misc-closetB-1024w.webp"
import miscclosetB1440wwebp from "./misc-closetB-1440w.webp"
import miscclosetB1920wwebp from "./misc-closetB-1920w.webp"
import miscclosetB2048wwebp from "./misc-closetB-2048w.webp"
import miscclosetB480wavif from "./misc-closetB-480w.avif"
import miscclosetB768wavif from "./misc-closetB-768w.avif"
import miscclosetB1024wavif from "./misc-closetB-1024w.avif"
import miscclosetB1440wavif from "./misc-closetB-1440w.avif"
import miscclosetB1920wavif from "./misc-closetB-1920w.avif"
import miscclosetB2048wavif from "./misc-closetB-2048w.avif"
import miscclosetCjpg from "../misc-closetC.jpg"
import miscclosetC480wpng from "./misc-closetC-480w.png"
import miscclosetC768wpng from "./misc-closetC-768w.png"
import miscclosetC1024wpng from "./misc-closetC-1024w.png"
import miscclosetC1440wpng from "./misc-closetC-1440w.png"
import miscclosetC1920wpng from "./misc-closetC-1920w.png"
import miscclosetC2048wpng from "./misc-closetC-2048w.png"
import miscclosetC480wwebp from "./misc-closetC-480w.webp"
import miscclosetC768wwebp from "./misc-closetC-768w.webp"
import miscclosetC1024wwebp from "./misc-closetC-1024w.webp"
import miscclosetC1440wwebp from "./misc-closetC-1440w.webp"
import miscclosetC1920wwebp from "./misc-closetC-1920w.webp"
import miscclosetC2048wwebp from "./misc-closetC-2048w.webp"
import miscclosetC480wavif from "./misc-closetC-480w.avif"
import miscclosetC768wavif from "./misc-closetC-768w.avif"
import miscclosetC1024wavif from "./misc-closetC-1024w.avif"
import miscclosetC1440wavif from "./misc-closetC-1440w.avif"
import miscclosetC1920wavif from "./misc-closetC-1920w.avif"
import miscclosetC2048wavif from "./misc-closetC-2048w.avif"
import miscdrawerAjpg from "../misc-drawerA.jpg"
import miscdrawerA480wpng from "./misc-drawerA-480w.png"
import miscdrawerA768wpng from "./misc-drawerA-768w.png"
import miscdrawerA1024wpng from "./misc-drawerA-1024w.png"
import miscdrawerA1440wpng from "./misc-drawerA-1440w.png"
import miscdrawerA1920wpng from "./misc-drawerA-1920w.png"
import miscdrawerA2048wpng from "./misc-drawerA-2048w.png"
import miscdrawerA480wwebp from "./misc-drawerA-480w.webp"
import miscdrawerA768wwebp from "./misc-drawerA-768w.webp"
import miscdrawerA1024wwebp from "./misc-drawerA-1024w.webp"
import miscdrawerA1440wwebp from "./misc-drawerA-1440w.webp"
import miscdrawerA1920wwebp from "./misc-drawerA-1920w.webp"
import miscdrawerA2048wwebp from "./misc-drawerA-2048w.webp"
import miscdrawerA480wavif from "./misc-drawerA-480w.avif"
import miscdrawerA768wavif from "./misc-drawerA-768w.avif"
import miscdrawerA1024wavif from "./misc-drawerA-1024w.avif"
import miscdrawerA1440wavif from "./misc-drawerA-1440w.avif"
import miscdrawerA1920wavif from "./misc-drawerA-1920w.avif"
import miscdrawerA2048wavif from "./misc-drawerA-2048w.avif"
import miscdrawerBjpg from "../misc-drawerB.jpg"
import miscdrawerB480wpng from "./misc-drawerB-480w.png"
import miscdrawerB768wpng from "./misc-drawerB-768w.png"
import miscdrawerB1024wpng from "./misc-drawerB-1024w.png"
import miscdrawerB1440wpng from "./misc-drawerB-1440w.png"
import miscdrawerB1920wpng from "./misc-drawerB-1920w.png"
import miscdrawerB2048wpng from "./misc-drawerB-2048w.png"
import miscdrawerB480wwebp from "./misc-drawerB-480w.webp"
import miscdrawerB768wwebp from "./misc-drawerB-768w.webp"
import miscdrawerB1024wwebp from "./misc-drawerB-1024w.webp"
import miscdrawerB1440wwebp from "./misc-drawerB-1440w.webp"
import miscdrawerB1920wwebp from "./misc-drawerB-1920w.webp"
import miscdrawerB2048wwebp from "./misc-drawerB-2048w.webp"
import miscdrawerB480wavif from "./misc-drawerB-480w.avif"
import miscdrawerB768wavif from "./misc-drawerB-768w.avif"
import miscdrawerB1024wavif from "./misc-drawerB-1024w.avif"
import miscdrawerB1440wavif from "./misc-drawerB-1440w.avif"
import miscdrawerB1920wavif from "./misc-drawerB-1920w.avif"
import miscdrawerB2048wavif from "./misc-drawerB-2048w.avif"
import miscfireplaceAjpg from "../misc-fireplaceA.jpg"
import miscfireplaceA480wpng from "./misc-fireplaceA-480w.png"
import miscfireplaceA768wpng from "./misc-fireplaceA-768w.png"
import miscfireplaceA1024wpng from "./misc-fireplaceA-1024w.png"
import miscfireplaceA1440wpng from "./misc-fireplaceA-1440w.png"
import miscfireplaceA1920wpng from "./misc-fireplaceA-1920w.png"
import miscfireplaceA2048wpng from "./misc-fireplaceA-2048w.png"
import miscfireplaceA480wwebp from "./misc-fireplaceA-480w.webp"
import miscfireplaceA768wwebp from "./misc-fireplaceA-768w.webp"
import miscfireplaceA1024wwebp from "./misc-fireplaceA-1024w.webp"
import miscfireplaceA1440wwebp from "./misc-fireplaceA-1440w.webp"
import miscfireplaceA1920wwebp from "./misc-fireplaceA-1920w.webp"
import miscfireplaceA2048wwebp from "./misc-fireplaceA-2048w.webp"
import miscfireplaceA480wavif from "./misc-fireplaceA-480w.avif"
import miscfireplaceA768wavif from "./misc-fireplaceA-768w.avif"
import miscfireplaceA1024wavif from "./misc-fireplaceA-1024w.avif"
import miscfireplaceA1440wavif from "./misc-fireplaceA-1440w.avif"
import miscfireplaceA1920wavif from "./misc-fireplaceA-1920w.avif"
import miscfireplaceA2048wavif from "./misc-fireplaceA-2048w.avif"
import miscmakeupAjpg from "../misc-makeupA.jpg"
import miscmakeupA480wpng from "./misc-makeupA-480w.png"
import miscmakeupA768wpng from "./misc-makeupA-768w.png"
import miscmakeupA1024wpng from "./misc-makeupA-1024w.png"
import miscmakeupA1440wpng from "./misc-makeupA-1440w.png"
import miscmakeupA1920wpng from "./misc-makeupA-1920w.png"
import miscmakeupA2048wpng from "./misc-makeupA-2048w.png"
import miscmakeupA480wwebp from "./misc-makeupA-480w.webp"
import miscmakeupA768wwebp from "./misc-makeupA-768w.webp"
import miscmakeupA1024wwebp from "./misc-makeupA-1024w.webp"
import miscmakeupA1440wwebp from "./misc-makeupA-1440w.webp"
import miscmakeupA1920wwebp from "./misc-makeupA-1920w.webp"
import miscmakeupA2048wwebp from "./misc-makeupA-2048w.webp"
import miscmakeupA480wavif from "./misc-makeupA-480w.avif"
import miscmakeupA768wavif from "./misc-makeupA-768w.avif"
import miscmakeupA1024wavif from "./misc-makeupA-1024w.avif"
import miscmakeupA1440wavif from "./misc-makeupA-1440w.avif"
import miscmakeupA1920wavif from "./misc-makeupA-1920w.avif"
import miscmakeupA2048wavif from "./misc-makeupA-2048w.avif"
import miscpantry1jpg from "../misc-pantry1.jpg"
import miscpantry1480wpng from "./misc-pantry1-480w.png"
import miscpantry1768wpng from "./misc-pantry1-768w.png"
import miscpantry11024wpng from "./misc-pantry1-1024w.png"
import miscpantry11440wpng from "./misc-pantry1-1440w.png"
import miscpantry11920wpng from "./misc-pantry1-1920w.png"
import miscpantry12048wpng from "./misc-pantry1-2048w.png"
import miscpantry1480wwebp from "./misc-pantry1-480w.webp"
import miscpantry1768wwebp from "./misc-pantry1-768w.webp"
import miscpantry11024wwebp from "./misc-pantry1-1024w.webp"
import miscpantry11440wwebp from "./misc-pantry1-1440w.webp"
import miscpantry11920wwebp from "./misc-pantry1-1920w.webp"
import miscpantry12048wwebp from "./misc-pantry1-2048w.webp"
import miscpantry1480wavif from "./misc-pantry1-480w.avif"
import miscpantry1768wavif from "./misc-pantry1-768w.avif"
import miscpantry11024wavif from "./misc-pantry1-1024w.avif"
import miscpantry11440wavif from "./misc-pantry1-1440w.avif"
import miscpantry11920wavif from "./misc-pantry1-1920w.avif"
import miscpantry12048wavif from "./misc-pantry1-2048w.avif"
import miscpantry2jpg from "../misc-pantry2.jpg"
import miscpantry2480wpng from "./misc-pantry2-480w.png"
import miscpantry2768wpng from "./misc-pantry2-768w.png"
import miscpantry21024wpng from "./misc-pantry2-1024w.png"
import miscpantry21440wpng from "./misc-pantry2-1440w.png"
import miscpantry21920wpng from "./misc-pantry2-1920w.png"
import miscpantry22048wpng from "./misc-pantry2-2048w.png"
import miscpantry2480wwebp from "./misc-pantry2-480w.webp"
import miscpantry2768wwebp from "./misc-pantry2-768w.webp"
import miscpantry21024wwebp from "./misc-pantry2-1024w.webp"
import miscpantry21440wwebp from "./misc-pantry2-1440w.webp"
import miscpantry21920wwebp from "./misc-pantry2-1920w.webp"
import miscpantry22048wwebp from "./misc-pantry2-2048w.webp"
import miscpantry2480wavif from "./misc-pantry2-480w.avif"
import miscpantry2768wavif from "./misc-pantry2-768w.avif"
import miscpantry21024wavif from "./misc-pantry2-1024w.avif"
import miscpantry21440wavif from "./misc-pantry2-1440w.avif"
import miscpantry21920wavif from "./misc-pantry2-1920w.avif"
import miscpantry22048wavif from "./misc-pantry2-2048w.avif"
import miscpantry3jpg from "../misc-pantry3.jpg"
import miscpantry3480wpng from "./misc-pantry3-480w.png"
import miscpantry3768wpng from "./misc-pantry3-768w.png"
import miscpantry31024wpng from "./misc-pantry3-1024w.png"
import miscpantry31440wpng from "./misc-pantry3-1440w.png"
import miscpantry31920wpng from "./misc-pantry3-1920w.png"
import miscpantry32048wpng from "./misc-pantry3-2048w.png"
import miscpantry3480wwebp from "./misc-pantry3-480w.webp"
import miscpantry3768wwebp from "./misc-pantry3-768w.webp"
import miscpantry31024wwebp from "./misc-pantry3-1024w.webp"
import miscpantry31440wwebp from "./misc-pantry3-1440w.webp"
import miscpantry31920wwebp from "./misc-pantry3-1920w.webp"
import miscpantry32048wwebp from "./misc-pantry3-2048w.webp"
import miscpantry3480wavif from "./misc-pantry3-480w.avif"
import miscpantry3768wavif from "./misc-pantry3-768w.avif"
import miscpantry31024wavif from "./misc-pantry3-1024w.avif"
import miscpantry31440wavif from "./misc-pantry3-1440w.avif"
import miscpantry31920wavif from "./misc-pantry3-1920w.avif"
import miscpantry32048wavif from "./misc-pantry3-2048w.avif"
import office1after1jpg from "../office1-after1.jpg"
import office1after1480wpng from "./office1-after1-480w.png"
import office1after1768wpng from "./office1-after1-768w.png"
import office1after11024wpng from "./office1-after1-1024w.png"
import office1after11440wpng from "./office1-after1-1440w.png"
import office1after11920wpng from "./office1-after1-1920w.png"
import office1after12048wpng from "./office1-after1-2048w.png"
import office1after1480wwebp from "./office1-after1-480w.webp"
import office1after1768wwebp from "./office1-after1-768w.webp"
import office1after11024wwebp from "./office1-after1-1024w.webp"
import office1after11440wwebp from "./office1-after1-1440w.webp"
import office1after11920wwebp from "./office1-after1-1920w.webp"
import office1after12048wwebp from "./office1-after1-2048w.webp"
import office1after1480wavif from "./office1-after1-480w.avif"
import office1after1768wavif from "./office1-after1-768w.avif"
import office1after11024wavif from "./office1-after1-1024w.avif"
import office1after11440wavif from "./office1-after1-1440w.avif"
import office1after11920wavif from "./office1-after1-1920w.avif"
import office1after12048wavif from "./office1-after1-2048w.avif"
import office1after2jpg from "../office1-after2.jpg"
import office1after2480wpng from "./office1-after2-480w.png"
import office1after2768wpng from "./office1-after2-768w.png"
import office1after21024wpng from "./office1-after2-1024w.png"
import office1after21440wpng from "./office1-after2-1440w.png"
import office1after21920wpng from "./office1-after2-1920w.png"
import office1after22048wpng from "./office1-after2-2048w.png"
import office1after2480wwebp from "./office1-after2-480w.webp"
import office1after2768wwebp from "./office1-after2-768w.webp"
import office1after21024wwebp from "./office1-after2-1024w.webp"
import office1after21440wwebp from "./office1-after2-1440w.webp"
import office1after21920wwebp from "./office1-after2-1920w.webp"
import office1after22048wwebp from "./office1-after2-2048w.webp"
import office1after2480wavif from "./office1-after2-480w.avif"
import office1after2768wavif from "./office1-after2-768w.avif"
import office1after21024wavif from "./office1-after2-1024w.avif"
import office1after21440wavif from "./office1-after2-1440w.avif"
import office1after21920wavif from "./office1-after2-1920w.avif"
import office1after22048wavif from "./office1-after2-2048w.avif"
import office1before1jpg from "../office1-before1.jpg"
import office1before1480wpng from "./office1-before1-480w.png"
import office1before1768wpng from "./office1-before1-768w.png"
import office1before11024wpng from "./office1-before1-1024w.png"
import office1before11440wpng from "./office1-before1-1440w.png"
import office1before11920wpng from "./office1-before1-1920w.png"
import office1before12048wpng from "./office1-before1-2048w.png"
import office1before1480wwebp from "./office1-before1-480w.webp"
import office1before1768wwebp from "./office1-before1-768w.webp"
import office1before11024wwebp from "./office1-before1-1024w.webp"
import office1before11440wwebp from "./office1-before1-1440w.webp"
import office1before11920wwebp from "./office1-before1-1920w.webp"
import office1before12048wwebp from "./office1-before1-2048w.webp"
import office1before1480wavif from "./office1-before1-480w.avif"
import office1before1768wavif from "./office1-before1-768w.avif"
import office1before11024wavif from "./office1-before1-1024w.avif"
import office1before11440wavif from "./office1-before1-1440w.avif"
import office1before11920wavif from "./office1-before1-1920w.avif"
import office1before12048wavif from "./office1-before1-2048w.avif"
import office1before2jpg from "../office1-before2.jpg"
import office1before2480wpng from "./office1-before2-480w.png"
import office1before2768wpng from "./office1-before2-768w.png"
import office1before21024wpng from "./office1-before2-1024w.png"
import office1before21440wpng from "./office1-before2-1440w.png"
import office1before21920wpng from "./office1-before2-1920w.png"
import office1before22048wpng from "./office1-before2-2048w.png"
import office1before2480wwebp from "./office1-before2-480w.webp"
import office1before2768wwebp from "./office1-before2-768w.webp"
import office1before21024wwebp from "./office1-before2-1024w.webp"
import office1before21440wwebp from "./office1-before2-1440w.webp"
import office1before21920wwebp from "./office1-before2-1920w.webp"
import office1before22048wwebp from "./office1-before2-2048w.webp"
import office1before2480wavif from "./office1-before2-480w.avif"
import office1before2768wavif from "./office1-before2-768w.avif"
import office1before21024wavif from "./office1-before2-1024w.avif"
import office1before21440wavif from "./office1-before2-1440w.avif"
import office1before21920wavif from "./office1-before2-1920w.avif"
import office1before22048wavif from "./office1-before2-2048w.avif"
import office2afterAjpg from "../office2-afterA.jpg"
import office2afterA480wpng from "./office2-afterA-480w.png"
import office2afterA768wpng from "./office2-afterA-768w.png"
import office2afterA1024wpng from "./office2-afterA-1024w.png"
import office2afterA1440wpng from "./office2-afterA-1440w.png"
import office2afterA1920wpng from "./office2-afterA-1920w.png"
import office2afterA2048wpng from "./office2-afterA-2048w.png"
import office2afterA480wwebp from "./office2-afterA-480w.webp"
import office2afterA768wwebp from "./office2-afterA-768w.webp"
import office2afterA1024wwebp from "./office2-afterA-1024w.webp"
import office2afterA1440wwebp from "./office2-afterA-1440w.webp"
import office2afterA1920wwebp from "./office2-afterA-1920w.webp"
import office2afterA2048wwebp from "./office2-afterA-2048w.webp"
import office2afterA480wavif from "./office2-afterA-480w.avif"
import office2afterA768wavif from "./office2-afterA-768w.avif"
import office2afterA1024wavif from "./office2-afterA-1024w.avif"
import office2afterA1440wavif from "./office2-afterA-1440w.avif"
import office2afterA1920wavif from "./office2-afterA-1920w.avif"
import office2afterA2048wavif from "./office2-afterA-2048w.avif"
import office2afterBjpg from "../office2-afterB.jpg"
import office2afterB480wpng from "./office2-afterB-480w.png"
import office2afterB768wpng from "./office2-afterB-768w.png"
import office2afterB1024wpng from "./office2-afterB-1024w.png"
import office2afterB1440wpng from "./office2-afterB-1440w.png"
import office2afterB1920wpng from "./office2-afterB-1920w.png"
import office2afterB2048wpng from "./office2-afterB-2048w.png"
import office2afterB480wwebp from "./office2-afterB-480w.webp"
import office2afterB768wwebp from "./office2-afterB-768w.webp"
import office2afterB1024wwebp from "./office2-afterB-1024w.webp"
import office2afterB1440wwebp from "./office2-afterB-1440w.webp"
import office2afterB1920wwebp from "./office2-afterB-1920w.webp"
import office2afterB2048wwebp from "./office2-afterB-2048w.webp"
import office2afterB480wavif from "./office2-afterB-480w.avif"
import office2afterB768wavif from "./office2-afterB-768w.avif"
import office2afterB1024wavif from "./office2-afterB-1024w.avif"
import office2afterB1440wavif from "./office2-afterB-1440w.avif"
import office2afterB1920wavif from "./office2-afterB-1920w.avif"
import office2afterB2048wavif from "./office2-afterB-2048w.avif"
import pantry1afterAjpg from "../pantry1-afterA.jpg"
import pantry1afterA480wpng from "./pantry1-afterA-480w.png"
import pantry1afterA768wpng from "./pantry1-afterA-768w.png"
import pantry1afterA1024wpng from "./pantry1-afterA-1024w.png"
import pantry1afterA1440wpng from "./pantry1-afterA-1440w.png"
import pantry1afterA1920wpng from "./pantry1-afterA-1920w.png"
import pantry1afterA2048wpng from "./pantry1-afterA-2048w.png"
import pantry1afterA480wwebp from "./pantry1-afterA-480w.webp"
import pantry1afterA768wwebp from "./pantry1-afterA-768w.webp"
import pantry1afterA1024wwebp from "./pantry1-afterA-1024w.webp"
import pantry1afterA1440wwebp from "./pantry1-afterA-1440w.webp"
import pantry1afterA1920wwebp from "./pantry1-afterA-1920w.webp"
import pantry1afterA2048wwebp from "./pantry1-afterA-2048w.webp"
import pantry1afterA480wavif from "./pantry1-afterA-480w.avif"
import pantry1afterA768wavif from "./pantry1-afterA-768w.avif"
import pantry1afterA1024wavif from "./pantry1-afterA-1024w.avif"
import pantry1afterA1440wavif from "./pantry1-afterA-1440w.avif"
import pantry1afterA1920wavif from "./pantry1-afterA-1920w.avif"
import pantry1afterA2048wavif from "./pantry1-afterA-2048w.avif"
import pantry1afterBjpg from "../pantry1-afterB.jpg"
import pantry1afterB480wpng from "./pantry1-afterB-480w.png"
import pantry1afterB768wpng from "./pantry1-afterB-768w.png"
import pantry1afterB1024wpng from "./pantry1-afterB-1024w.png"
import pantry1afterB1440wpng from "./pantry1-afterB-1440w.png"
import pantry1afterB1920wpng from "./pantry1-afterB-1920w.png"
import pantry1afterB2048wpng from "./pantry1-afterB-2048w.png"
import pantry1afterB480wwebp from "./pantry1-afterB-480w.webp"
import pantry1afterB768wwebp from "./pantry1-afterB-768w.webp"
import pantry1afterB1024wwebp from "./pantry1-afterB-1024w.webp"
import pantry1afterB1440wwebp from "./pantry1-afterB-1440w.webp"
import pantry1afterB1920wwebp from "./pantry1-afterB-1920w.webp"
import pantry1afterB2048wwebp from "./pantry1-afterB-2048w.webp"
import pantry1afterB480wavif from "./pantry1-afterB-480w.avif"
import pantry1afterB768wavif from "./pantry1-afterB-768w.avif"
import pantry1afterB1024wavif from "./pantry1-afterB-1024w.avif"
import pantry1afterB1440wavif from "./pantry1-afterB-1440w.avif"
import pantry1afterB1920wavif from "./pantry1-afterB-1920w.avif"
import pantry1afterB2048wavif from "./pantry1-afterB-2048w.avif"
import pantry1afterCjpg from "../pantry1-afterC.jpg"
import pantry1afterC480wpng from "./pantry1-afterC-480w.png"
import pantry1afterC768wpng from "./pantry1-afterC-768w.png"
import pantry1afterC1024wpng from "./pantry1-afterC-1024w.png"
import pantry1afterC1440wpng from "./pantry1-afterC-1440w.png"
import pantry1afterC1920wpng from "./pantry1-afterC-1920w.png"
import pantry1afterC2048wpng from "./pantry1-afterC-2048w.png"
import pantry1afterC480wwebp from "./pantry1-afterC-480w.webp"
import pantry1afterC768wwebp from "./pantry1-afterC-768w.webp"
import pantry1afterC1024wwebp from "./pantry1-afterC-1024w.webp"
import pantry1afterC1440wwebp from "./pantry1-afterC-1440w.webp"
import pantry1afterC1920wwebp from "./pantry1-afterC-1920w.webp"
import pantry1afterC2048wwebp from "./pantry1-afterC-2048w.webp"
import pantry1afterC480wavif from "./pantry1-afterC-480w.avif"
import pantry1afterC768wavif from "./pantry1-afterC-768w.avif"
import pantry1afterC1024wavif from "./pantry1-afterC-1024w.avif"
import pantry1afterC1440wavif from "./pantry1-afterC-1440w.avif"
import pantry1afterC1920wavif from "./pantry1-afterC-1920w.avif"
import pantry1afterC2048wavif from "./pantry1-afterC-2048w.avif"
import pantry1afterDjpg from "../pantry1-afterD.jpg"
import pantry1afterD480wpng from "./pantry1-afterD-480w.png"
import pantry1afterD768wpng from "./pantry1-afterD-768w.png"
import pantry1afterD1024wpng from "./pantry1-afterD-1024w.png"
import pantry1afterD1440wpng from "./pantry1-afterD-1440w.png"
import pantry1afterD1920wpng from "./pantry1-afterD-1920w.png"
import pantry1afterD2048wpng from "./pantry1-afterD-2048w.png"
import pantry1afterD480wwebp from "./pantry1-afterD-480w.webp"
import pantry1afterD768wwebp from "./pantry1-afterD-768w.webp"
import pantry1afterD1024wwebp from "./pantry1-afterD-1024w.webp"
import pantry1afterD1440wwebp from "./pantry1-afterD-1440w.webp"
import pantry1afterD1920wwebp from "./pantry1-afterD-1920w.webp"
import pantry1afterD2048wwebp from "./pantry1-afterD-2048w.webp"
import pantry1afterD480wavif from "./pantry1-afterD-480w.avif"
import pantry1afterD768wavif from "./pantry1-afterD-768w.avif"
import pantry1afterD1024wavif from "./pantry1-afterD-1024w.avif"
import pantry1afterD1440wavif from "./pantry1-afterD-1440w.avif"
import pantry1afterD1920wavif from "./pantry1-afterD-1920w.avif"
import pantry1afterD2048wavif from "./pantry1-afterD-2048w.avif"
import pantry1afterEjpg from "../pantry1-afterE.jpg"
import pantry1afterE480wpng from "./pantry1-afterE-480w.png"
import pantry1afterE768wpng from "./pantry1-afterE-768w.png"
import pantry1afterE1024wpng from "./pantry1-afterE-1024w.png"
import pantry1afterE1440wpng from "./pantry1-afterE-1440w.png"
import pantry1afterE1920wpng from "./pantry1-afterE-1920w.png"
import pantry1afterE2048wpng from "./pantry1-afterE-2048w.png"
import pantry1afterE480wwebp from "./pantry1-afterE-480w.webp"
import pantry1afterE768wwebp from "./pantry1-afterE-768w.webp"
import pantry1afterE1024wwebp from "./pantry1-afterE-1024w.webp"
import pantry1afterE1440wwebp from "./pantry1-afterE-1440w.webp"
import pantry1afterE1920wwebp from "./pantry1-afterE-1920w.webp"
import pantry1afterE2048wwebp from "./pantry1-afterE-2048w.webp"
import pantry1afterE480wavif from "./pantry1-afterE-480w.avif"
import pantry1afterE768wavif from "./pantry1-afterE-768w.avif"
import pantry1afterE1024wavif from "./pantry1-afterE-1024w.avif"
import pantry1afterE1440wavif from "./pantry1-afterE-1440w.avif"
import pantry1afterE1920wavif from "./pantry1-afterE-1920w.avif"
import pantry1afterE2048wavif from "./pantry1-afterE-2048w.avif"
import pantry1afterFjpg from "../pantry1-afterF.jpg"
import pantry1afterF480wpng from "./pantry1-afterF-480w.png"
import pantry1afterF768wpng from "./pantry1-afterF-768w.png"
import pantry1afterF1024wpng from "./pantry1-afterF-1024w.png"
import pantry1afterF1440wpng from "./pantry1-afterF-1440w.png"
import pantry1afterF1920wpng from "./pantry1-afterF-1920w.png"
import pantry1afterF2048wpng from "./pantry1-afterF-2048w.png"
import pantry1afterF480wwebp from "./pantry1-afterF-480w.webp"
import pantry1afterF768wwebp from "./pantry1-afterF-768w.webp"
import pantry1afterF1024wwebp from "./pantry1-afterF-1024w.webp"
import pantry1afterF1440wwebp from "./pantry1-afterF-1440w.webp"
import pantry1afterF1920wwebp from "./pantry1-afterF-1920w.webp"
import pantry1afterF2048wwebp from "./pantry1-afterF-2048w.webp"
import pantry1afterF480wavif from "./pantry1-afterF-480w.avif"
import pantry1afterF768wavif from "./pantry1-afterF-768w.avif"
import pantry1afterF1024wavif from "./pantry1-afterF-1024w.avif"
import pantry1afterF1440wavif from "./pantry1-afterF-1440w.avif"
import pantry1afterF1920wavif from "./pantry1-afterF-1920w.avif"
import pantry1afterF2048wavif from "./pantry1-afterF-2048w.avif"
import pantry1afterGjpg from "../pantry1-afterG.jpg"
import pantry1afterG480wpng from "./pantry1-afterG-480w.png"
import pantry1afterG768wpng from "./pantry1-afterG-768w.png"
import pantry1afterG1024wpng from "./pantry1-afterG-1024w.png"
import pantry1afterG1440wpng from "./pantry1-afterG-1440w.png"
import pantry1afterG1920wpng from "./pantry1-afterG-1920w.png"
import pantry1afterG2048wpng from "./pantry1-afterG-2048w.png"
import pantry1afterG480wwebp from "./pantry1-afterG-480w.webp"
import pantry1afterG768wwebp from "./pantry1-afterG-768w.webp"
import pantry1afterG1024wwebp from "./pantry1-afterG-1024w.webp"
import pantry1afterG1440wwebp from "./pantry1-afterG-1440w.webp"
import pantry1afterG1920wwebp from "./pantry1-afterG-1920w.webp"
import pantry1afterG2048wwebp from "./pantry1-afterG-2048w.webp"
import pantry1afterG480wavif from "./pantry1-afterG-480w.avif"
import pantry1afterG768wavif from "./pantry1-afterG-768w.avif"
import pantry1afterG1024wavif from "./pantry1-afterG-1024w.avif"
import pantry1afterG1440wavif from "./pantry1-afterG-1440w.avif"
import pantry1afterG1920wavif from "./pantry1-afterG-1920w.avif"
import pantry1afterG2048wavif from "./pantry1-afterG-2048w.avif"
import pantry1afterHjpg from "../pantry1-afterH.jpg"
import pantry1afterH480wpng from "./pantry1-afterH-480w.png"
import pantry1afterH768wpng from "./pantry1-afterH-768w.png"
import pantry1afterH1024wpng from "./pantry1-afterH-1024w.png"
import pantry1afterH1440wpng from "./pantry1-afterH-1440w.png"
import pantry1afterH1920wpng from "./pantry1-afterH-1920w.png"
import pantry1afterH2048wpng from "./pantry1-afterH-2048w.png"
import pantry1afterH480wwebp from "./pantry1-afterH-480w.webp"
import pantry1afterH768wwebp from "./pantry1-afterH-768w.webp"
import pantry1afterH1024wwebp from "./pantry1-afterH-1024w.webp"
import pantry1afterH1440wwebp from "./pantry1-afterH-1440w.webp"
import pantry1afterH1920wwebp from "./pantry1-afterH-1920w.webp"
import pantry1afterH2048wwebp from "./pantry1-afterH-2048w.webp"
import pantry1afterH480wavif from "./pantry1-afterH-480w.avif"
import pantry1afterH768wavif from "./pantry1-afterH-768w.avif"
import pantry1afterH1024wavif from "./pantry1-afterH-1024w.avif"
import pantry1afterH1440wavif from "./pantry1-afterH-1440w.avif"
import pantry1afterH1920wavif from "./pantry1-afterH-1920w.avif"
import pantry1afterH2048wavif from "./pantry1-afterH-2048w.avif"
import pantry1afterIjpg from "../pantry1-afterI.jpg"
import pantry1afterI480wpng from "./pantry1-afterI-480w.png"
import pantry1afterI768wpng from "./pantry1-afterI-768w.png"
import pantry1afterI1024wpng from "./pantry1-afterI-1024w.png"
import pantry1afterI1440wpng from "./pantry1-afterI-1440w.png"
import pantry1afterI1920wpng from "./pantry1-afterI-1920w.png"
import pantry1afterI2048wpng from "./pantry1-afterI-2048w.png"
import pantry1afterI480wwebp from "./pantry1-afterI-480w.webp"
import pantry1afterI768wwebp from "./pantry1-afterI-768w.webp"
import pantry1afterI1024wwebp from "./pantry1-afterI-1024w.webp"
import pantry1afterI1440wwebp from "./pantry1-afterI-1440w.webp"
import pantry1afterI1920wwebp from "./pantry1-afterI-1920w.webp"
import pantry1afterI2048wwebp from "./pantry1-afterI-2048w.webp"
import pantry1afterI480wavif from "./pantry1-afterI-480w.avif"
import pantry1afterI768wavif from "./pantry1-afterI-768w.avif"
import pantry1afterI1024wavif from "./pantry1-afterI-1024w.avif"
import pantry1afterI1440wavif from "./pantry1-afterI-1440w.avif"
import pantry1afterI1920wavif from "./pantry1-afterI-1920w.avif"
import pantry1afterI2048wavif from "./pantry1-afterI-2048w.avif"
import pantry2after1jpg from "../pantry2-after1.jpg"
import pantry2after1480wpng from "./pantry2-after1-480w.png"
import pantry2after1768wpng from "./pantry2-after1-768w.png"
import pantry2after11024wpng from "./pantry2-after1-1024w.png"
import pantry2after11440wpng from "./pantry2-after1-1440w.png"
import pantry2after11920wpng from "./pantry2-after1-1920w.png"
import pantry2after12048wpng from "./pantry2-after1-2048w.png"
import pantry2after1480wwebp from "./pantry2-after1-480w.webp"
import pantry2after1768wwebp from "./pantry2-after1-768w.webp"
import pantry2after11024wwebp from "./pantry2-after1-1024w.webp"
import pantry2after11440wwebp from "./pantry2-after1-1440w.webp"
import pantry2after11920wwebp from "./pantry2-after1-1920w.webp"
import pantry2after12048wwebp from "./pantry2-after1-2048w.webp"
import pantry2after1480wavif from "./pantry2-after1-480w.avif"
import pantry2after1768wavif from "./pantry2-after1-768w.avif"
import pantry2after11024wavif from "./pantry2-after1-1024w.avif"
import pantry2after11440wavif from "./pantry2-after1-1440w.avif"
import pantry2after11920wavif from "./pantry2-after1-1920w.avif"
import pantry2after12048wavif from "./pantry2-after1-2048w.avif"
import pantry2after2jpg from "../pantry2-after2.jpg"
import pantry2after2480wpng from "./pantry2-after2-480w.png"
import pantry2after2768wpng from "./pantry2-after2-768w.png"
import pantry2after21024wpng from "./pantry2-after2-1024w.png"
import pantry2after21440wpng from "./pantry2-after2-1440w.png"
import pantry2after21920wpng from "./pantry2-after2-1920w.png"
import pantry2after22048wpng from "./pantry2-after2-2048w.png"
import pantry2after2480wwebp from "./pantry2-after2-480w.webp"
import pantry2after2768wwebp from "./pantry2-after2-768w.webp"
import pantry2after21024wwebp from "./pantry2-after2-1024w.webp"
import pantry2after21440wwebp from "./pantry2-after2-1440w.webp"
import pantry2after21920wwebp from "./pantry2-after2-1920w.webp"
import pantry2after22048wwebp from "./pantry2-after2-2048w.webp"
import pantry2after2480wavif from "./pantry2-after2-480w.avif"
import pantry2after2768wavif from "./pantry2-after2-768w.avif"
import pantry2after21024wavif from "./pantry2-after2-1024w.avif"
import pantry2after21440wavif from "./pantry2-after2-1440w.avif"
import pantry2after21920wavif from "./pantry2-after2-1920w.avif"
import pantry2after22048wavif from "./pantry2-after2-2048w.avif"
import pantry2before1jpg from "../pantry2-before1.jpg"
import pantry2before1480wpng from "./pantry2-before1-480w.png"
import pantry2before1768wpng from "./pantry2-before1-768w.png"
import pantry2before11024wpng from "./pantry2-before1-1024w.png"
import pantry2before11440wpng from "./pantry2-before1-1440w.png"
import pantry2before11920wpng from "./pantry2-before1-1920w.png"
import pantry2before12048wpng from "./pantry2-before1-2048w.png"
import pantry2before1480wwebp from "./pantry2-before1-480w.webp"
import pantry2before1768wwebp from "./pantry2-before1-768w.webp"
import pantry2before11024wwebp from "./pantry2-before1-1024w.webp"
import pantry2before11440wwebp from "./pantry2-before1-1440w.webp"
import pantry2before11920wwebp from "./pantry2-before1-1920w.webp"
import pantry2before12048wwebp from "./pantry2-before1-2048w.webp"
import pantry2before1480wavif from "./pantry2-before1-480w.avif"
import pantry2before1768wavif from "./pantry2-before1-768w.avif"
import pantry2before11024wavif from "./pantry2-before1-1024w.avif"
import pantry2before11440wavif from "./pantry2-before1-1440w.avif"
import pantry2before11920wavif from "./pantry2-before1-1920w.avif"
import pantry2before12048wavif from "./pantry2-before1-2048w.avif"
import pantry2before2jpg from "../pantry2-before2.jpg"
import pantry2before2480wpng from "./pantry2-before2-480w.png"
import pantry2before2768wpng from "./pantry2-before2-768w.png"
import pantry2before21024wpng from "./pantry2-before2-1024w.png"
import pantry2before21440wpng from "./pantry2-before2-1440w.png"
import pantry2before21920wpng from "./pantry2-before2-1920w.png"
import pantry2before22048wpng from "./pantry2-before2-2048w.png"
import pantry2before2480wwebp from "./pantry2-before2-480w.webp"
import pantry2before2768wwebp from "./pantry2-before2-768w.webp"
import pantry2before21024wwebp from "./pantry2-before2-1024w.webp"
import pantry2before21440wwebp from "./pantry2-before2-1440w.webp"
import pantry2before21920wwebp from "./pantry2-before2-1920w.webp"
import pantry2before22048wwebp from "./pantry2-before2-2048w.webp"
import pantry2before2480wavif from "./pantry2-before2-480w.avif"
import pantry2before2768wavif from "./pantry2-before2-768w.avif"
import pantry2before21024wavif from "./pantry2-before2-1024w.avif"
import pantry2before21440wavif from "./pantry2-before2-1440w.avif"
import pantry2before21920wavif from "./pantry2-before2-1920w.avif"
import pantry2before22048wavif from "./pantry2-before2-2048w.avif"
import qrcodesitepng from "../qrcode-site.png"
import qrcodesite480wpng from "./qrcode-site-480w.png"
import qrcodesite768wpng from "./qrcode-site-768w.png"
import qrcodesite1024wpng from "./qrcode-site-1024w.png"
import qrcodesite1440wpng from "./qrcode-site-1440w.png"
import qrcodesite1920wpng from "./qrcode-site-1920w.png"
import qrcodesite2048wpng from "./qrcode-site-2048w.png"
import qrcodesite480wwebp from "./qrcode-site-480w.webp"
import qrcodesite768wwebp from "./qrcode-site-768w.webp"
import qrcodesite1024wwebp from "./qrcode-site-1024w.webp"
import qrcodesite1440wwebp from "./qrcode-site-1440w.webp"
import qrcodesite1920wwebp from "./qrcode-site-1920w.webp"
import qrcodesite2048wwebp from "./qrcode-site-2048w.webp"
import qrcodesite480wavif from "./qrcode-site-480w.avif"
import qrcodesite768wavif from "./qrcode-site-768w.avif"
import qrcodesite1024wavif from "./qrcode-site-1024w.avif"
import qrcodesite1440wavif from "./qrcode-site-1440w.avif"
import qrcodesite1920wavif from "./qrcode-site-1920w.avif"
import qrcodesite2048wavif from "./qrcode-site-2048w.avif"
import scrabblejpg from "../scrabble.jpg"
import scrabble480wpng from "./scrabble-480w.png"
import scrabble768wpng from "./scrabble-768w.png"
import scrabble1024wpng from "./scrabble-1024w.png"
import scrabble1440wpng from "./scrabble-1440w.png"
import scrabble1920wpng from "./scrabble-1920w.png"
import scrabble2048wpng from "./scrabble-2048w.png"
import scrabble480wwebp from "./scrabble-480w.webp"
import scrabble768wwebp from "./scrabble-768w.webp"
import scrabble1024wwebp from "./scrabble-1024w.webp"
import scrabble1440wwebp from "./scrabble-1440w.webp"
import scrabble1920wwebp from "./scrabble-1920w.webp"
import scrabble2048wwebp from "./scrabble-2048w.webp"
import scrabble480wavif from "./scrabble-480w.avif"
import scrabble768wavif from "./scrabble-768w.avif"
import scrabble1024wavif from "./scrabble-1024w.avif"
import scrabble1440wavif from "./scrabble-1440w.avif"
import scrabble1920wavif from "./scrabble-1920w.avif"
import scrabble2048wavif from "./scrabble-2048w.avif"
import shoes1afterAjpg from "../shoes1-afterA.jpg"
import shoes1afterA480wpng from "./shoes1-afterA-480w.png"
import shoes1afterA768wpng from "./shoes1-afterA-768w.png"
import shoes1afterA1024wpng from "./shoes1-afterA-1024w.png"
import shoes1afterA1440wpng from "./shoes1-afterA-1440w.png"
import shoes1afterA1920wpng from "./shoes1-afterA-1920w.png"
import shoes1afterA2048wpng from "./shoes1-afterA-2048w.png"
import shoes1afterA480wwebp from "./shoes1-afterA-480w.webp"
import shoes1afterA768wwebp from "./shoes1-afterA-768w.webp"
import shoes1afterA1024wwebp from "./shoes1-afterA-1024w.webp"
import shoes1afterA1440wwebp from "./shoes1-afterA-1440w.webp"
import shoes1afterA1920wwebp from "./shoes1-afterA-1920w.webp"
import shoes1afterA2048wwebp from "./shoes1-afterA-2048w.webp"
import shoes1afterA480wavif from "./shoes1-afterA-480w.avif"
import shoes1afterA768wavif from "./shoes1-afterA-768w.avif"
import shoes1afterA1024wavif from "./shoes1-afterA-1024w.avif"
import shoes1afterA1440wavif from "./shoes1-afterA-1440w.avif"
import shoes1afterA1920wavif from "./shoes1-afterA-1920w.avif"
import shoes1afterA2048wavif from "./shoes1-afterA-2048w.avif"
import storageUnit1afterAjpg from "../storageUnit1-afterA.jpg"
import storageUnit1afterA480wpng from "./storageUnit1-afterA-480w.png"
import storageUnit1afterA768wpng from "./storageUnit1-afterA-768w.png"
import storageUnit1afterA1024wpng from "./storageUnit1-afterA-1024w.png"
import storageUnit1afterA1440wpng from "./storageUnit1-afterA-1440w.png"
import storageUnit1afterA1920wpng from "./storageUnit1-afterA-1920w.png"
import storageUnit1afterA2048wpng from "./storageUnit1-afterA-2048w.png"
import storageUnit1afterA480wwebp from "./storageUnit1-afterA-480w.webp"
import storageUnit1afterA768wwebp from "./storageUnit1-afterA-768w.webp"
import storageUnit1afterA1024wwebp from "./storageUnit1-afterA-1024w.webp"
import storageUnit1afterA1440wwebp from "./storageUnit1-afterA-1440w.webp"
import storageUnit1afterA1920wwebp from "./storageUnit1-afterA-1920w.webp"
import storageUnit1afterA2048wwebp from "./storageUnit1-afterA-2048w.webp"
import storageUnit1afterA480wavif from "./storageUnit1-afterA-480w.avif"
import storageUnit1afterA768wavif from "./storageUnit1-afterA-768w.avif"
import storageUnit1afterA1024wavif from "./storageUnit1-afterA-1024w.avif"
import storageUnit1afterA1440wavif from "./storageUnit1-afterA-1440w.avif"
import storageUnit1afterA1920wavif from "./storageUnit1-afterA-1920w.avif"
import storageUnit1afterA2048wavif from "./storageUnit1-afterA-2048w.avif"
import storageUnit1afterBjpg from "../storageUnit1-afterB.jpg"
import storageUnit1afterB480wpng from "./storageUnit1-afterB-480w.png"
import storageUnit1afterB768wpng from "./storageUnit1-afterB-768w.png"
import storageUnit1afterB1024wpng from "./storageUnit1-afterB-1024w.png"
import storageUnit1afterB1440wpng from "./storageUnit1-afterB-1440w.png"
import storageUnit1afterB1920wpng from "./storageUnit1-afterB-1920w.png"
import storageUnit1afterB2048wpng from "./storageUnit1-afterB-2048w.png"
import storageUnit1afterB480wwebp from "./storageUnit1-afterB-480w.webp"
import storageUnit1afterB768wwebp from "./storageUnit1-afterB-768w.webp"
import storageUnit1afterB1024wwebp from "./storageUnit1-afterB-1024w.webp"
import storageUnit1afterB1440wwebp from "./storageUnit1-afterB-1440w.webp"
import storageUnit1afterB1920wwebp from "./storageUnit1-afterB-1920w.webp"
import storageUnit1afterB2048wwebp from "./storageUnit1-afterB-2048w.webp"
import storageUnit1afterB480wavif from "./storageUnit1-afterB-480w.avif"
import storageUnit1afterB768wavif from "./storageUnit1-afterB-768w.avif"
import storageUnit1afterB1024wavif from "./storageUnit1-afterB-1024w.avif"
import storageUnit1afterB1440wavif from "./storageUnit1-afterB-1440w.avif"
import storageUnit1afterB1920wavif from "./storageUnit1-afterB-1920w.avif"
import storageUnit1afterB2048wavif from "./storageUnit1-afterB-2048w.avif"
import storageUnit1afterCjpg from "../storageUnit1-afterC.jpg"
import storageUnit1afterC480wpng from "./storageUnit1-afterC-480w.png"
import storageUnit1afterC768wpng from "./storageUnit1-afterC-768w.png"
import storageUnit1afterC1024wpng from "./storageUnit1-afterC-1024w.png"
import storageUnit1afterC1440wpng from "./storageUnit1-afterC-1440w.png"
import storageUnit1afterC1920wpng from "./storageUnit1-afterC-1920w.png"
import storageUnit1afterC2048wpng from "./storageUnit1-afterC-2048w.png"
import storageUnit1afterC480wwebp from "./storageUnit1-afterC-480w.webp"
import storageUnit1afterC768wwebp from "./storageUnit1-afterC-768w.webp"
import storageUnit1afterC1024wwebp from "./storageUnit1-afterC-1024w.webp"
import storageUnit1afterC1440wwebp from "./storageUnit1-afterC-1440w.webp"
import storageUnit1afterC1920wwebp from "./storageUnit1-afterC-1920w.webp"
import storageUnit1afterC2048wwebp from "./storageUnit1-afterC-2048w.webp"
import storageUnit1afterC480wavif from "./storageUnit1-afterC-480w.avif"
import storageUnit1afterC768wavif from "./storageUnit1-afterC-768w.avif"
import storageUnit1afterC1024wavif from "./storageUnit1-afterC-1024w.avif"
import storageUnit1afterC1440wavif from "./storageUnit1-afterC-1440w.avif"
import storageUnit1afterC1920wavif from "./storageUnit1-afterC-1920w.avif"
import storageUnit1afterC2048wavif from "./storageUnit1-afterC-2048w.avif"
import storageUnit1afterDjpg from "../storageUnit1-afterD.jpg"
import storageUnit1afterD480wpng from "./storageUnit1-afterD-480w.png"
import storageUnit1afterD768wpng from "./storageUnit1-afterD-768w.png"
import storageUnit1afterD1024wpng from "./storageUnit1-afterD-1024w.png"
import storageUnit1afterD1440wpng from "./storageUnit1-afterD-1440w.png"
import storageUnit1afterD1920wpng from "./storageUnit1-afterD-1920w.png"
import storageUnit1afterD2048wpng from "./storageUnit1-afterD-2048w.png"
import storageUnit1afterD480wwebp from "./storageUnit1-afterD-480w.webp"
import storageUnit1afterD768wwebp from "./storageUnit1-afterD-768w.webp"
import storageUnit1afterD1024wwebp from "./storageUnit1-afterD-1024w.webp"
import storageUnit1afterD1440wwebp from "./storageUnit1-afterD-1440w.webp"
import storageUnit1afterD1920wwebp from "./storageUnit1-afterD-1920w.webp"
import storageUnit1afterD2048wwebp from "./storageUnit1-afterD-2048w.webp"
import storageUnit1afterD480wavif from "./storageUnit1-afterD-480w.avif"
import storageUnit1afterD768wavif from "./storageUnit1-afterD-768w.avif"
import storageUnit1afterD1024wavif from "./storageUnit1-afterD-1024w.avif"
import storageUnit1afterD1440wavif from "./storageUnit1-afterD-1440w.avif"
import storageUnit1afterD1920wavif from "./storageUnit1-afterD-1920w.avif"
import storageUnit1afterD2048wavif from "./storageUnit1-afterD-2048w.avif"
import storageUnit1beforeAjpg from "../storageUnit1-beforeA.jpg"
import storageUnit1beforeA480wpng from "./storageUnit1-beforeA-480w.png"
import storageUnit1beforeA768wpng from "./storageUnit1-beforeA-768w.png"
import storageUnit1beforeA1024wpng from "./storageUnit1-beforeA-1024w.png"
import storageUnit1beforeA1440wpng from "./storageUnit1-beforeA-1440w.png"
import storageUnit1beforeA1920wpng from "./storageUnit1-beforeA-1920w.png"
import storageUnit1beforeA2048wpng from "./storageUnit1-beforeA-2048w.png"
import storageUnit1beforeA480wwebp from "./storageUnit1-beforeA-480w.webp"
import storageUnit1beforeA768wwebp from "./storageUnit1-beforeA-768w.webp"
import storageUnit1beforeA1024wwebp from "./storageUnit1-beforeA-1024w.webp"
import storageUnit1beforeA1440wwebp from "./storageUnit1-beforeA-1440w.webp"
import storageUnit1beforeA1920wwebp from "./storageUnit1-beforeA-1920w.webp"
import storageUnit1beforeA2048wwebp from "./storageUnit1-beforeA-2048w.webp"
import storageUnit1beforeA480wavif from "./storageUnit1-beforeA-480w.avif"
import storageUnit1beforeA768wavif from "./storageUnit1-beforeA-768w.avif"
import storageUnit1beforeA1024wavif from "./storageUnit1-beforeA-1024w.avif"
import storageUnit1beforeA1440wavif from "./storageUnit1-beforeA-1440w.avif"
import storageUnit1beforeA1920wavif from "./storageUnit1-beforeA-1920w.avif"
import storageUnit1beforeA2048wavif from "./storageUnit1-beforeA-2048w.avif"
import storageUnit1beforeBjpg from "../storageUnit1-beforeB.jpg"
import storageUnit1beforeB480wpng from "./storageUnit1-beforeB-480w.png"
import storageUnit1beforeB768wpng from "./storageUnit1-beforeB-768w.png"
import storageUnit1beforeB1024wpng from "./storageUnit1-beforeB-1024w.png"
import storageUnit1beforeB1440wpng from "./storageUnit1-beforeB-1440w.png"
import storageUnit1beforeB1920wpng from "./storageUnit1-beforeB-1920w.png"
import storageUnit1beforeB2048wpng from "./storageUnit1-beforeB-2048w.png"
import storageUnit1beforeB480wwebp from "./storageUnit1-beforeB-480w.webp"
import storageUnit1beforeB768wwebp from "./storageUnit1-beforeB-768w.webp"
import storageUnit1beforeB1024wwebp from "./storageUnit1-beforeB-1024w.webp"
import storageUnit1beforeB1440wwebp from "./storageUnit1-beforeB-1440w.webp"
import storageUnit1beforeB1920wwebp from "./storageUnit1-beforeB-1920w.webp"
import storageUnit1beforeB2048wwebp from "./storageUnit1-beforeB-2048w.webp"
import storageUnit1beforeB480wavif from "./storageUnit1-beforeB-480w.avif"
import storageUnit1beforeB768wavif from "./storageUnit1-beforeB-768w.avif"
import storageUnit1beforeB1024wavif from "./storageUnit1-beforeB-1024w.avif"
import storageUnit1beforeB1440wavif from "./storageUnit1-beforeB-1440w.avif"
import storageUnit1beforeB1920wavif from "./storageUnit1-beforeB-1920w.avif"
import storageUnit1beforeB2048wavif from "./storageUnit1-beforeB-2048w.avif"
import treatyourselfjpg from "../treat-yourself.jpg"
import treatyourself480wpng from "./treat-yourself-480w.png"
import treatyourself768wpng from "./treat-yourself-768w.png"
import treatyourself1024wpng from "./treat-yourself-1024w.png"
import treatyourself1440wpng from "./treat-yourself-1440w.png"
import treatyourself1920wpng from "./treat-yourself-1920w.png"
import treatyourself2048wpng from "./treat-yourself-2048w.png"
import treatyourself480wwebp from "./treat-yourself-480w.webp"
import treatyourself768wwebp from "./treat-yourself-768w.webp"
import treatyourself1024wwebp from "./treat-yourself-1024w.webp"
import treatyourself1440wwebp from "./treat-yourself-1440w.webp"
import treatyourself1920wwebp from "./treat-yourself-1920w.webp"
import treatyourself2048wwebp from "./treat-yourself-2048w.webp"
import treatyourself480wavif from "./treat-yourself-480w.avif"
import treatyourself768wavif from "./treat-yourself-768w.avif"
import treatyourself1024wavif from "./treat-yourself-1024w.avif"
import treatyourself1440wavif from "./treat-yourself-1440w.avif"
import treatyourself1920wavif from "./treat-yourself-1920w.avif"
import treatyourself2048wavif from "./treat-yourself-2048w.avif"
import turnideasintorealityjpg from "../turn-ideas-into-reality.jpg"
import turnideasintoreality480wpng from "./turn-ideas-into-reality-480w.png"
import turnideasintoreality768wpng from "./turn-ideas-into-reality-768w.png"
import turnideasintoreality1024wpng from "./turn-ideas-into-reality-1024w.png"
import turnideasintoreality1440wpng from "./turn-ideas-into-reality-1440w.png"
import turnideasintoreality1920wpng from "./turn-ideas-into-reality-1920w.png"
import turnideasintoreality2048wpng from "./turn-ideas-into-reality-2048w.png"
import turnideasintoreality480wwebp from "./turn-ideas-into-reality-480w.webp"
import turnideasintoreality768wwebp from "./turn-ideas-into-reality-768w.webp"
import turnideasintoreality1024wwebp from "./turn-ideas-into-reality-1024w.webp"
import turnideasintoreality1440wwebp from "./turn-ideas-into-reality-1440w.webp"
import turnideasintoreality1920wwebp from "./turn-ideas-into-reality-1920w.webp"
import turnideasintoreality2048wwebp from "./turn-ideas-into-reality-2048w.webp"
import turnideasintoreality480wavif from "./turn-ideas-into-reality-480w.avif"
import turnideasintoreality768wavif from "./turn-ideas-into-reality-768w.avif"
import turnideasintoreality1024wavif from "./turn-ideas-into-reality-1024w.avif"
import turnideasintoreality1440wavif from "./turn-ideas-into-reality-1440w.avif"
import turnideasintoreality1920wavif from "./turn-ideas-into-reality-1920w.avif"
import turnideasintoreality2048wavif from "./turn-ideas-into-reality-2048w.avif"
import youcandoitjpg from "../you-can-do-it.jpg"
import youcandoit480wpng from "./you-can-do-it-480w.png"
import youcandoit768wpng from "./you-can-do-it-768w.png"
import youcandoit1024wpng from "./you-can-do-it-1024w.png"
import youcandoit1440wpng from "./you-can-do-it-1440w.png"
import youcandoit1920wpng from "./you-can-do-it-1920w.png"
import youcandoit2048wpng from "./you-can-do-it-2048w.png"
import youcandoit480wwebp from "./you-can-do-it-480w.webp"
import youcandoit768wwebp from "./you-can-do-it-768w.webp"
import youcandoit1024wwebp from "./you-can-do-it-1024w.webp"
import youcandoit1440wwebp from "./you-can-do-it-1440w.webp"
import youcandoit1920wwebp from "./you-can-do-it-1920w.webp"
import youcandoit2048wwebp from "./you-can-do-it-2048w.webp"
import youcandoit480wavif from "./you-can-do-it-480w.avif"
import youcandoit768wavif from "./you-can-do-it-768w.avif"
import youcandoit1024wavif from "./you-can-do-it-1024w.avif"
import youcandoit1440wavif from "./you-can-do-it-1440w.avif"
import youcandoit1920wavif from "./you-can-do-it-1920w.avif"
import youcandoit2048wavif from "./you-can-do-it-2048w.avif"

const config = {
'bathroom1-after.jpg': {
  _path: bathroom1afterjpg,
  'png': {
    480: bathroom1after480wpng,
    768: bathroom1after768wpng,
    1024: bathroom1after1024wpng,
    1440: bathroom1after1440wpng,
    1920: bathroom1after1920wpng,
    2048: bathroom1after2048wpng,
  },
  'webp': {
    480: bathroom1after480wwebp,
    768: bathroom1after768wwebp,
    1024: bathroom1after1024wwebp,
    1440: bathroom1after1440wwebp,
    1920: bathroom1after1920wwebp,
    2048: bathroom1after2048wwebp,
  },
  'avif': {
    480: bathroom1after480wavif,
    768: bathroom1after768wavif,
    1024: bathroom1after1024wavif,
    1440: bathroom1after1440wavif,
    1920: bathroom1after1920wavif,
    2048: bathroom1after2048wavif,
  },
},
'bathroom1-before.jpg': {
  _path: bathroom1beforejpg,
  'png': {
    480: bathroom1before480wpng,
    768: bathroom1before768wpng,
    1024: bathroom1before1024wpng,
    1440: bathroom1before1440wpng,
    1920: bathroom1before1920wpng,
    2048: bathroom1before2048wpng,
  },
  'webp': {
    480: bathroom1before480wwebp,
    768: bathroom1before768wwebp,
    1024: bathroom1before1024wwebp,
    1440: bathroom1before1440wwebp,
    1920: bathroom1before1920wwebp,
    2048: bathroom1before2048wwebp,
  },
  'avif': {
    480: bathroom1before480wavif,
    768: bathroom1before768wavif,
    1024: bathroom1before1024wavif,
    1440: bathroom1before1440wavif,
    1920: bathroom1before1920wavif,
    2048: bathroom1before2048wavif,
  },
},
'bathroom2-afterA.jpg': {
  _path: bathroom2afterAjpg,
  'png': {
    480: bathroom2afterA480wpng,
    768: bathroom2afterA768wpng,
    1024: bathroom2afterA1024wpng,
    1440: bathroom2afterA1440wpng,
    1920: bathroom2afterA1920wpng,
    2048: bathroom2afterA2048wpng,
  },
  'webp': {
    480: bathroom2afterA480wwebp,
    768: bathroom2afterA768wwebp,
    1024: bathroom2afterA1024wwebp,
    1440: bathroom2afterA1440wwebp,
    1920: bathroom2afterA1920wwebp,
    2048: bathroom2afterA2048wwebp,
  },
  'avif': {
    480: bathroom2afterA480wavif,
    768: bathroom2afterA768wavif,
    1024: bathroom2afterA1024wavif,
    1440: bathroom2afterA1440wavif,
    1920: bathroom2afterA1920wavif,
    2048: bathroom2afterA2048wavif,
  },
},
'books.jpg': {
  _path: booksjpg,
  'png': {
    480: books480wpng,
    768: books768wpng,
    1024: books1024wpng,
    1440: books1440wpng,
    1920: books1920wpng,
    2048: books2048wpng,
  },
  'webp': {
    480: books480wwebp,
    768: books768wwebp,
    1024: books1024wwebp,
    1440: books1440wwebp,
    1920: books1920wwebp,
    2048: books2048wwebp,
  },
  'avif': {
    480: books480wavif,
    768: books768wavif,
    1024: books1024wavif,
    1440: books1440wavif,
    1920: books1920wavif,
    2048: books2048wavif,
  },
},
'books2-afterA.jpg': {
  _path: books2afterAjpg,
  'png': {
    480: books2afterA480wpng,
    768: books2afterA768wpng,
    1024: books2afterA1024wpng,
    1440: books2afterA1440wpng,
    1920: books2afterA1920wpng,
    2048: books2afterA2048wpng,
  },
  'webp': {
    480: books2afterA480wwebp,
    768: books2afterA768wwebp,
    1024: books2afterA1024wwebp,
    1440: books2afterA1440wwebp,
    1920: books2afterA1920wwebp,
    2048: books2afterA2048wwebp,
  },
  'avif': {
    480: books2afterA480wavif,
    768: books2afterA768wavif,
    1024: books2afterA1024wavif,
    1440: books2afterA1440wavif,
    1920: books2afterA1920wavif,
    2048: books2afterA2048wavif,
  },
},
'books2-afterB.jpg': {
  _path: books2afterBjpg,
  'png': {
    480: books2afterB480wpng,
    768: books2afterB768wpng,
    1024: books2afterB1024wpng,
    1440: books2afterB1440wpng,
    1920: books2afterB1920wpng,
    2048: books2afterB2048wpng,
  },
  'webp': {
    480: books2afterB480wwebp,
    768: books2afterB768wwebp,
    1024: books2afterB1024wwebp,
    1440: books2afterB1440wwebp,
    1920: books2afterB1920wwebp,
    2048: books2afterB2048wwebp,
  },
  'avif': {
    480: books2afterB480wavif,
    768: books2afterB768wavif,
    1024: books2afterB1024wavif,
    1440: books2afterB1440wavif,
    1920: books2afterB1920wavif,
    2048: books2afterB2048wavif,
  },
},
'cabinet1-after.jpg': {
  _path: cabinet1afterjpg,
  'png': {
    480: cabinet1after480wpng,
    768: cabinet1after768wpng,
    1024: cabinet1after1024wpng,
    1440: cabinet1after1440wpng,
    1920: cabinet1after1920wpng,
    2048: cabinet1after2048wpng,
  },
  'webp': {
    480: cabinet1after480wwebp,
    768: cabinet1after768wwebp,
    1024: cabinet1after1024wwebp,
    1440: cabinet1after1440wwebp,
    1920: cabinet1after1920wwebp,
    2048: cabinet1after2048wwebp,
  },
  'avif': {
    480: cabinet1after480wavif,
    768: cabinet1after768wavif,
    1024: cabinet1after1024wavif,
    1440: cabinet1after1440wavif,
    1920: cabinet1after1920wavif,
    2048: cabinet1after2048wavif,
  },
},
'cabinet1-before.jpg': {
  _path: cabinet1beforejpg,
  'png': {
    480: cabinet1before480wpng,
    768: cabinet1before768wpng,
    1024: cabinet1before1024wpng,
    1440: cabinet1before1440wpng,
    1920: cabinet1before1920wpng,
    2048: cabinet1before2048wpng,
  },
  'webp': {
    480: cabinet1before480wwebp,
    768: cabinet1before768wwebp,
    1024: cabinet1before1024wwebp,
    1440: cabinet1before1440wwebp,
    1920: cabinet1before1920wwebp,
    2048: cabinet1before2048wwebp,
  },
  'avif': {
    480: cabinet1before480wavif,
    768: cabinet1before768wavif,
    1024: cabinet1before1024wavif,
    1440: cabinet1before1440wavif,
    1920: cabinet1before1920wavif,
    2048: cabinet1before2048wavif,
  },
},
'closet1.jpg': {
  _path: closet1jpg,
  'png': {
    480: closet1480wpng,
    768: closet1768wpng,
    1024: closet11024wpng,
    1440: closet11440wpng,
    1920: closet11920wpng,
    2048: closet12048wpng,
  },
  'webp': {
    480: closet1480wwebp,
    768: closet1768wwebp,
    1024: closet11024wwebp,
    1440: closet11440wwebp,
    1920: closet11920wwebp,
    2048: closet12048wwebp,
  },
  'avif': {
    480: closet1480wavif,
    768: closet1768wavif,
    1024: closet11024wavif,
    1440: closet11440wavif,
    1920: closet11920wavif,
    2048: closet12048wavif,
  },
},
'closet2.jpg': {
  _path: closet2jpg,
  'png': {
    480: closet2480wpng,
    768: closet2768wpng,
    1024: closet21024wpng,
    1440: closet21440wpng,
    1920: closet21920wpng,
    2048: closet22048wpng,
  },
  'webp': {
    480: closet2480wwebp,
    768: closet2768wwebp,
    1024: closet21024wwebp,
    1440: closet21440wwebp,
    1920: closet21920wwebp,
    2048: closet22048wwebp,
  },
  'avif': {
    480: closet2480wavif,
    768: closet2768wavif,
    1024: closet21024wavif,
    1440: closet21440wavif,
    1920: closet21920wavif,
    2048: closet22048wavif,
  },
},
'closet3.jpg': {
  _path: closet3jpg,
  'png': {
    480: closet3480wpng,
    768: closet3768wpng,
    1024: closet31024wpng,
    1440: closet31440wpng,
    1920: closet31920wpng,
    2048: closet32048wpng,
  },
  'webp': {
    480: closet3480wwebp,
    768: closet3768wwebp,
    1024: closet31024wwebp,
    1440: closet31440wwebp,
    1920: closet31920wwebp,
    2048: closet32048wwebp,
  },
  'avif': {
    480: closet3480wavif,
    768: closet3768wavif,
    1024: closet31024wavif,
    1440: closet31440wavif,
    1920: closet31920wavif,
    2048: closet32048wavif,
  },
},
'closet4.jpg': {
  _path: closet4jpg,
  'png': {
    480: closet4480wpng,
    768: closet4768wpng,
    1024: closet41024wpng,
    1440: closet41440wpng,
    1920: closet41920wpng,
    2048: closet42048wpng,
  },
  'webp': {
    480: closet4480wwebp,
    768: closet4768wwebp,
    1024: closet41024wwebp,
    1440: closet41440wwebp,
    1920: closet41920wwebp,
    2048: closet42048wwebp,
  },
  'avif': {
    480: closet4480wavif,
    768: closet4768wavif,
    1024: closet41024wavif,
    1440: closet41440wavif,
    1920: closet41920wavif,
    2048: closet42048wavif,
  },
},
'closet5-after1.jpg': {
  _path: closet5after1jpg,
  'png': {
    480: closet5after1480wpng,
    768: closet5after1768wpng,
    1024: closet5after11024wpng,
    1440: closet5after11440wpng,
    1920: closet5after11920wpng,
    2048: closet5after12048wpng,
  },
  'webp': {
    480: closet5after1480wwebp,
    768: closet5after1768wwebp,
    1024: closet5after11024wwebp,
    1440: closet5after11440wwebp,
    1920: closet5after11920wwebp,
    2048: closet5after12048wwebp,
  },
  'avif': {
    480: closet5after1480wavif,
    768: closet5after1768wavif,
    1024: closet5after11024wavif,
    1440: closet5after11440wavif,
    1920: closet5after11920wavif,
    2048: closet5after12048wavif,
  },
},
'closet5-after2.jpg': {
  _path: closet5after2jpg,
  'png': {
    480: closet5after2480wpng,
    768: closet5after2768wpng,
    1024: closet5after21024wpng,
    1440: closet5after21440wpng,
    1920: closet5after21920wpng,
    2048: closet5after22048wpng,
  },
  'webp': {
    480: closet5after2480wwebp,
    768: closet5after2768wwebp,
    1024: closet5after21024wwebp,
    1440: closet5after21440wwebp,
    1920: closet5after21920wwebp,
    2048: closet5after22048wwebp,
  },
  'avif': {
    480: closet5after2480wavif,
    768: closet5after2768wavif,
    1024: closet5after21024wavif,
    1440: closet5after21440wavif,
    1920: closet5after21920wavif,
    2048: closet5after22048wavif,
  },
},
'closet5-after3.jpg': {
  _path: closet5after3jpg,
  'png': {
    480: closet5after3480wpng,
    768: closet5after3768wpng,
    1024: closet5after31024wpng,
    1440: closet5after31440wpng,
    1920: closet5after31920wpng,
    2048: closet5after32048wpng,
  },
  'webp': {
    480: closet5after3480wwebp,
    768: closet5after3768wwebp,
    1024: closet5after31024wwebp,
    1440: closet5after31440wwebp,
    1920: closet5after31920wwebp,
    2048: closet5after32048wwebp,
  },
  'avif': {
    480: closet5after3480wavif,
    768: closet5after3768wavif,
    1024: closet5after31024wavif,
    1440: closet5after31440wavif,
    1920: closet5after31920wavif,
    2048: closet5after32048wavif,
  },
},
'closet5-before1.jpg': {
  _path: closet5before1jpg,
  'png': {
    480: closet5before1480wpng,
    768: closet5before1768wpng,
    1024: closet5before11024wpng,
    1440: closet5before11440wpng,
    1920: closet5before11920wpng,
    2048: closet5before12048wpng,
  },
  'webp': {
    480: closet5before1480wwebp,
    768: closet5before1768wwebp,
    1024: closet5before11024wwebp,
    1440: closet5before11440wwebp,
    1920: closet5before11920wwebp,
    2048: closet5before12048wwebp,
  },
  'avif': {
    480: closet5before1480wavif,
    768: closet5before1768wavif,
    1024: closet5before11024wavif,
    1440: closet5before11440wavif,
    1920: closet5before11920wavif,
    2048: closet5before12048wavif,
  },
},
'closet5-before2.jpg': {
  _path: closet5before2jpg,
  'png': {
    480: closet5before2480wpng,
    768: closet5before2768wpng,
    1024: closet5before21024wpng,
    1440: closet5before21440wpng,
    1920: closet5before21920wpng,
    2048: closet5before22048wpng,
  },
  'webp': {
    480: closet5before2480wwebp,
    768: closet5before2768wwebp,
    1024: closet5before21024wwebp,
    1440: closet5before21440wwebp,
    1920: closet5before21920wwebp,
    2048: closet5before22048wwebp,
  },
  'avif': {
    480: closet5before2480wavif,
    768: closet5before2768wavif,
    1024: closet5before21024wavif,
    1440: closet5before21440wavif,
    1920: closet5before21920wavif,
    2048: closet5before22048wavif,
  },
},
'closet5-before3.jpg': {
  _path: closet5before3jpg,
  'png': {
    480: closet5before3480wpng,
    768: closet5before3768wpng,
    1024: closet5before31024wpng,
    1440: closet5before31440wpng,
    1920: closet5before31920wpng,
    2048: closet5before32048wpng,
  },
  'webp': {
    480: closet5before3480wwebp,
    768: closet5before3768wwebp,
    1024: closet5before31024wwebp,
    1440: closet5before31440wwebp,
    1920: closet5before31920wwebp,
    2048: closet5before32048wwebp,
  },
  'avif': {
    480: closet5before3480wavif,
    768: closet5before3768wavif,
    1024: closet5before31024wavif,
    1440: closet5before31440wavif,
    1920: closet5before31920wavif,
    2048: closet5before32048wavif,
  },
},
'colors.jpg': {
  _path: colorsjpg,
  'png': {
    480: colors480wpng,
    768: colors768wpng,
    1024: colors1024wpng,
    1440: colors1440wpng,
    1920: colors1920wpng,
    2048: colors2048wpng,
  },
  'webp': {
    480: colors480wwebp,
    768: colors768wwebp,
    1024: colors1024wwebp,
    1440: colors1440wwebp,
    1920: colors1920wwebp,
    2048: colors2048wwebp,
  },
  'avif': {
    480: colors480wavif,
    768: colors768wavif,
    1024: colors1024wavif,
    1440: colors1440wavif,
    1920: colors1920wavif,
    2048: colors2048wavif,
  },
},
'fridge1-after1.jpg': {
  _path: fridge1after1jpg,
  'png': {
    480: fridge1after1480wpng,
    768: fridge1after1768wpng,
    1024: fridge1after11024wpng,
    1440: fridge1after11440wpng,
    1920: fridge1after11920wpng,
    2048: fridge1after12048wpng,
  },
  'webp': {
    480: fridge1after1480wwebp,
    768: fridge1after1768wwebp,
    1024: fridge1after11024wwebp,
    1440: fridge1after11440wwebp,
    1920: fridge1after11920wwebp,
    2048: fridge1after12048wwebp,
  },
  'avif': {
    480: fridge1after1480wavif,
    768: fridge1after1768wavif,
    1024: fridge1after11024wavif,
    1440: fridge1after11440wavif,
    1920: fridge1after11920wavif,
    2048: fridge1after12048wavif,
  },
},
'fridge1-after2.jpg': {
  _path: fridge1after2jpg,
  'png': {
    480: fridge1after2480wpng,
    768: fridge1after2768wpng,
    1024: fridge1after21024wpng,
    1440: fridge1after21440wpng,
    1920: fridge1after21920wpng,
    2048: fridge1after22048wpng,
  },
  'webp': {
    480: fridge1after2480wwebp,
    768: fridge1after2768wwebp,
    1024: fridge1after21024wwebp,
    1440: fridge1after21440wwebp,
    1920: fridge1after21920wwebp,
    2048: fridge1after22048wwebp,
  },
  'avif': {
    480: fridge1after2480wavif,
    768: fridge1after2768wavif,
    1024: fridge1after21024wavif,
    1440: fridge1after21440wavif,
    1920: fridge1after21920wavif,
    2048: fridge1after22048wavif,
  },
},
'fridge1-before1.jpg': {
  _path: fridge1before1jpg,
  'png': {
    480: fridge1before1480wpng,
    768: fridge1before1768wpng,
    1024: fridge1before11024wpng,
    1440: fridge1before11440wpng,
    1920: fridge1before11920wpng,
    2048: fridge1before12048wpng,
  },
  'webp': {
    480: fridge1before1480wwebp,
    768: fridge1before1768wwebp,
    1024: fridge1before11024wwebp,
    1440: fridge1before11440wwebp,
    1920: fridge1before11920wwebp,
    2048: fridge1before12048wwebp,
  },
  'avif': {
    480: fridge1before1480wavif,
    768: fridge1before1768wavif,
    1024: fridge1before11024wavif,
    1440: fridge1before11440wavif,
    1920: fridge1before11920wavif,
    2048: fridge1before12048wavif,
  },
},
'fridge1-before2.jpg': {
  _path: fridge1before2jpg,
  'png': {
    480: fridge1before2480wpng,
    768: fridge1before2768wpng,
    1024: fridge1before21024wpng,
    1440: fridge1before21440wpng,
    1920: fridge1before21920wpng,
    2048: fridge1before22048wpng,
  },
  'webp': {
    480: fridge1before2480wwebp,
    768: fridge1before2768wwebp,
    1024: fridge1before21024wwebp,
    1440: fridge1before21440wwebp,
    1920: fridge1before21920wwebp,
    2048: fridge1before22048wwebp,
  },
  'avif': {
    480: fridge1before2480wavif,
    768: fridge1before2768wavif,
    1024: fridge1before21024wavif,
    1440: fridge1before21440wavif,
    1920: fridge1before21920wavif,
    2048: fridge1before22048wavif,
  },
},
'fridge2-afterA.jpg': {
  _path: fridge2afterAjpg,
  'png': {
    480: fridge2afterA480wpng,
    768: fridge2afterA768wpng,
    1024: fridge2afterA1024wpng,
    1440: fridge2afterA1440wpng,
    1920: fridge2afterA1920wpng,
    2048: fridge2afterA2048wpng,
  },
  'webp': {
    480: fridge2afterA480wwebp,
    768: fridge2afterA768wwebp,
    1024: fridge2afterA1024wwebp,
    1440: fridge2afterA1440wwebp,
    1920: fridge2afterA1920wwebp,
    2048: fridge2afterA2048wwebp,
  },
  'avif': {
    480: fridge2afterA480wavif,
    768: fridge2afterA768wavif,
    1024: fridge2afterA1024wavif,
    1440: fridge2afterA1440wavif,
    1920: fridge2afterA1920wavif,
    2048: fridge2afterA2048wavif,
  },
},
'fridge2-beforeA.jpg': {
  _path: fridge2beforeAjpg,
  'png': {
    480: fridge2beforeA480wpng,
    768: fridge2beforeA768wpng,
    1024: fridge2beforeA1024wpng,
    1440: fridge2beforeA1440wpng,
    1920: fridge2beforeA1920wpng,
    2048: fridge2beforeA2048wpng,
  },
  'webp': {
    480: fridge2beforeA480wwebp,
    768: fridge2beforeA768wwebp,
    1024: fridge2beforeA1024wwebp,
    1440: fridge2beforeA1440wwebp,
    1920: fridge2beforeA1920wwebp,
    2048: fridge2beforeA2048wwebp,
  },
  'avif': {
    480: fridge2beforeA480wavif,
    768: fridge2beforeA768wavif,
    1024: fridge2beforeA1024wavif,
    1440: fridge2beforeA1440wavif,
    1920: fridge2beforeA1920wavif,
    2048: fridge2beforeA2048wavif,
  },
},
'fruit.jpg': {
  _path: fruitjpg,
  'png': {
    480: fruit480wpng,
    768: fruit768wpng,
    1024: fruit1024wpng,
    1440: fruit1440wpng,
    1920: fruit1920wpng,
    2048: fruit2048wpng,
  },
  'webp': {
    480: fruit480wwebp,
    768: fruit768wwebp,
    1024: fruit1024wwebp,
    1440: fruit1440wwebp,
    1920: fruit1920wwebp,
    2048: fruit2048wwebp,
  },
  'avif': {
    480: fruit480wavif,
    768: fruit768wavif,
    1024: fruit1024wavif,
    1440: fruit1440wavif,
    1920: fruit1920wavif,
    2048: fruit2048wavif,
  },
},
'kitchen1.jpg': {
  _path: kitchen1jpg,
  'png': {
    480: kitchen1480wpng,
    768: kitchen1768wpng,
    1024: kitchen11024wpng,
    1440: kitchen11440wpng,
    1920: kitchen11920wpng,
    2048: kitchen12048wpng,
  },
  'webp': {
    480: kitchen1480wwebp,
    768: kitchen1768wwebp,
    1024: kitchen11024wwebp,
    1440: kitchen11440wwebp,
    1920: kitchen11920wwebp,
    2048: kitchen12048wwebp,
  },
  'avif': {
    480: kitchen1480wavif,
    768: kitchen1768wavif,
    1024: kitchen11024wavif,
    1440: kitchen11440wavif,
    1920: kitchen11920wavif,
    2048: kitchen12048wavif,
  },
},
'kitchen2.jpg': {
  _path: kitchen2jpg,
  'png': {
    480: kitchen2480wpng,
    768: kitchen2768wpng,
    1024: kitchen21024wpng,
    1440: kitchen21440wpng,
    1920: kitchen21920wpng,
    2048: kitchen22048wpng,
  },
  'webp': {
    480: kitchen2480wwebp,
    768: kitchen2768wwebp,
    1024: kitchen21024wwebp,
    1440: kitchen21440wwebp,
    1920: kitchen21920wwebp,
    2048: kitchen22048wwebp,
  },
  'avif': {
    480: kitchen2480wavif,
    768: kitchen2768wavif,
    1024: kitchen21024wavif,
    1440: kitchen21440wavif,
    1920: kitchen21920wavif,
    2048: kitchen22048wavif,
  },
},
'kitchen3-after1.jpg': {
  _path: kitchen3after1jpg,
  'png': {
    480: kitchen3after1480wpng,
    768: kitchen3after1768wpng,
    1024: kitchen3after11024wpng,
    1440: kitchen3after11440wpng,
    1920: kitchen3after11920wpng,
    2048: kitchen3after12048wpng,
  },
  'webp': {
    480: kitchen3after1480wwebp,
    768: kitchen3after1768wwebp,
    1024: kitchen3after11024wwebp,
    1440: kitchen3after11440wwebp,
    1920: kitchen3after11920wwebp,
    2048: kitchen3after12048wwebp,
  },
  'avif': {
    480: kitchen3after1480wavif,
    768: kitchen3after1768wavif,
    1024: kitchen3after11024wavif,
    1440: kitchen3after11440wavif,
    1920: kitchen3after11920wavif,
    2048: kitchen3after12048wavif,
  },
},
'kitchen3-after2.jpg': {
  _path: kitchen3after2jpg,
  'png': {
    480: kitchen3after2480wpng,
    768: kitchen3after2768wpng,
    1024: kitchen3after21024wpng,
    1440: kitchen3after21440wpng,
    1920: kitchen3after21920wpng,
    2048: kitchen3after22048wpng,
  },
  'webp': {
    480: kitchen3after2480wwebp,
    768: kitchen3after2768wwebp,
    1024: kitchen3after21024wwebp,
    1440: kitchen3after21440wwebp,
    1920: kitchen3after21920wwebp,
    2048: kitchen3after22048wwebp,
  },
  'avif': {
    480: kitchen3after2480wavif,
    768: kitchen3after2768wavif,
    1024: kitchen3after21024wavif,
    1440: kitchen3after21440wavif,
    1920: kitchen3after21920wavif,
    2048: kitchen3after22048wavif,
  },
},
'kitchen3-after3.jpg': {
  _path: kitchen3after3jpg,
  'png': {
    480: kitchen3after3480wpng,
    768: kitchen3after3768wpng,
    1024: kitchen3after31024wpng,
    1440: kitchen3after31440wpng,
    1920: kitchen3after31920wpng,
    2048: kitchen3after32048wpng,
  },
  'webp': {
    480: kitchen3after3480wwebp,
    768: kitchen3after3768wwebp,
    1024: kitchen3after31024wwebp,
    1440: kitchen3after31440wwebp,
    1920: kitchen3after31920wwebp,
    2048: kitchen3after32048wwebp,
  },
  'avif': {
    480: kitchen3after3480wavif,
    768: kitchen3after3768wavif,
    1024: kitchen3after31024wavif,
    1440: kitchen3after31440wavif,
    1920: kitchen3after31920wavif,
    2048: kitchen3after32048wavif,
  },
},
'kitchen3-afterA.jpg': {
  _path: kitchen3afterAjpg,
  'png': {
    480: kitchen3afterA480wpng,
    768: kitchen3afterA768wpng,
    1024: kitchen3afterA1024wpng,
    1440: kitchen3afterA1440wpng,
    1920: kitchen3afterA1920wpng,
    2048: kitchen3afterA2048wpng,
  },
  'webp': {
    480: kitchen3afterA480wwebp,
    768: kitchen3afterA768wwebp,
    1024: kitchen3afterA1024wwebp,
    1440: kitchen3afterA1440wwebp,
    1920: kitchen3afterA1920wwebp,
    2048: kitchen3afterA2048wwebp,
  },
  'avif': {
    480: kitchen3afterA480wavif,
    768: kitchen3afterA768wavif,
    1024: kitchen3afterA1024wavif,
    1440: kitchen3afterA1440wavif,
    1920: kitchen3afterA1920wavif,
    2048: kitchen3afterA2048wavif,
  },
},
'kitchen3-afterB.jpg': {
  _path: kitchen3afterBjpg,
  'png': {
    480: kitchen3afterB480wpng,
    768: kitchen3afterB768wpng,
    1024: kitchen3afterB1024wpng,
    1440: kitchen3afterB1440wpng,
    1920: kitchen3afterB1920wpng,
    2048: kitchen3afterB2048wpng,
  },
  'webp': {
    480: kitchen3afterB480wwebp,
    768: kitchen3afterB768wwebp,
    1024: kitchen3afterB1024wwebp,
    1440: kitchen3afterB1440wwebp,
    1920: kitchen3afterB1920wwebp,
    2048: kitchen3afterB2048wwebp,
  },
  'avif': {
    480: kitchen3afterB480wavif,
    768: kitchen3afterB768wavif,
    1024: kitchen3afterB1024wavif,
    1440: kitchen3afterB1440wavif,
    1920: kitchen3afterB1920wavif,
    2048: kitchen3afterB2048wavif,
  },
},
'kitchen3-afterC.jpg': {
  _path: kitchen3afterCjpg,
  'png': {
    480: kitchen3afterC480wpng,
    768: kitchen3afterC768wpng,
    1024: kitchen3afterC1024wpng,
    1440: kitchen3afterC1440wpng,
    1920: kitchen3afterC1920wpng,
    2048: kitchen3afterC2048wpng,
  },
  'webp': {
    480: kitchen3afterC480wwebp,
    768: kitchen3afterC768wwebp,
    1024: kitchen3afterC1024wwebp,
    1440: kitchen3afterC1440wwebp,
    1920: kitchen3afterC1920wwebp,
    2048: kitchen3afterC2048wwebp,
  },
  'avif': {
    480: kitchen3afterC480wavif,
    768: kitchen3afterC768wavif,
    1024: kitchen3afterC1024wavif,
    1440: kitchen3afterC1440wavif,
    1920: kitchen3afterC1920wavif,
    2048: kitchen3afterC2048wavif,
  },
},
'kitchen3-afterD.jpg': {
  _path: kitchen3afterDjpg,
  'png': {
    480: kitchen3afterD480wpng,
    768: kitchen3afterD768wpng,
    1024: kitchen3afterD1024wpng,
    1440: kitchen3afterD1440wpng,
    1920: kitchen3afterD1920wpng,
    2048: kitchen3afterD2048wpng,
  },
  'webp': {
    480: kitchen3afterD480wwebp,
    768: kitchen3afterD768wwebp,
    1024: kitchen3afterD1024wwebp,
    1440: kitchen3afterD1440wwebp,
    1920: kitchen3afterD1920wwebp,
    2048: kitchen3afterD2048wwebp,
  },
  'avif': {
    480: kitchen3afterD480wavif,
    768: kitchen3afterD768wavif,
    1024: kitchen3afterD1024wavif,
    1440: kitchen3afterD1440wavif,
    1920: kitchen3afterD1920wavif,
    2048: kitchen3afterD2048wavif,
  },
},
'kitchen3-afterE.jpg': {
  _path: kitchen3afterEjpg,
  'png': {
    480: kitchen3afterE480wpng,
    768: kitchen3afterE768wpng,
    1024: kitchen3afterE1024wpng,
    1440: kitchen3afterE1440wpng,
    1920: kitchen3afterE1920wpng,
    2048: kitchen3afterE2048wpng,
  },
  'webp': {
    480: kitchen3afterE480wwebp,
    768: kitchen3afterE768wwebp,
    1024: kitchen3afterE1024wwebp,
    1440: kitchen3afterE1440wwebp,
    1920: kitchen3afterE1920wwebp,
    2048: kitchen3afterE2048wwebp,
  },
  'avif': {
    480: kitchen3afterE480wavif,
    768: kitchen3afterE768wavif,
    1024: kitchen3afterE1024wavif,
    1440: kitchen3afterE1440wavif,
    1920: kitchen3afterE1920wavif,
    2048: kitchen3afterE2048wavif,
  },
},
'kitchen3-afterF.jpg': {
  _path: kitchen3afterFjpg,
  'png': {
    480: kitchen3afterF480wpng,
    768: kitchen3afterF768wpng,
    1024: kitchen3afterF1024wpng,
    1440: kitchen3afterF1440wpng,
    1920: kitchen3afterF1920wpng,
    2048: kitchen3afterF2048wpng,
  },
  'webp': {
    480: kitchen3afterF480wwebp,
    768: kitchen3afterF768wwebp,
    1024: kitchen3afterF1024wwebp,
    1440: kitchen3afterF1440wwebp,
    1920: kitchen3afterF1920wwebp,
    2048: kitchen3afterF2048wwebp,
  },
  'avif': {
    480: kitchen3afterF480wavif,
    768: kitchen3afterF768wavif,
    1024: kitchen3afterF1024wavif,
    1440: kitchen3afterF1440wavif,
    1920: kitchen3afterF1920wavif,
    2048: kitchen3afterF2048wavif,
  },
},
'kitchen3-afterG.jpg': {
  _path: kitchen3afterGjpg,
  'png': {
    480: kitchen3afterG480wpng,
    768: kitchen3afterG768wpng,
    1024: kitchen3afterG1024wpng,
    1440: kitchen3afterG1440wpng,
    1920: kitchen3afterG1920wpng,
    2048: kitchen3afterG2048wpng,
  },
  'webp': {
    480: kitchen3afterG480wwebp,
    768: kitchen3afterG768wwebp,
    1024: kitchen3afterG1024wwebp,
    1440: kitchen3afterG1440wwebp,
    1920: kitchen3afterG1920wwebp,
    2048: kitchen3afterG2048wwebp,
  },
  'avif': {
    480: kitchen3afterG480wavif,
    768: kitchen3afterG768wavif,
    1024: kitchen3afterG1024wavif,
    1440: kitchen3afterG1440wavif,
    1920: kitchen3afterG1920wavif,
    2048: kitchen3afterG2048wavif,
  },
},
'kitchen3-afterH.jpg': {
  _path: kitchen3afterHjpg,
  'png': {
    480: kitchen3afterH480wpng,
    768: kitchen3afterH768wpng,
    1024: kitchen3afterH1024wpng,
    1440: kitchen3afterH1440wpng,
    1920: kitchen3afterH1920wpng,
    2048: kitchen3afterH2048wpng,
  },
  'webp': {
    480: kitchen3afterH480wwebp,
    768: kitchen3afterH768wwebp,
    1024: kitchen3afterH1024wwebp,
    1440: kitchen3afterH1440wwebp,
    1920: kitchen3afterH1920wwebp,
    2048: kitchen3afterH2048wwebp,
  },
  'avif': {
    480: kitchen3afterH480wavif,
    768: kitchen3afterH768wavif,
    1024: kitchen3afterH1024wavif,
    1440: kitchen3afterH1440wavif,
    1920: kitchen3afterH1920wavif,
    2048: kitchen3afterH2048wavif,
  },
},
'kitchen3-afterI.jpg': {
  _path: kitchen3afterIjpg,
  'png': {
    480: kitchen3afterI480wpng,
    768: kitchen3afterI768wpng,
    1024: kitchen3afterI1024wpng,
    1440: kitchen3afterI1440wpng,
    1920: kitchen3afterI1920wpng,
    2048: kitchen3afterI2048wpng,
  },
  'webp': {
    480: kitchen3afterI480wwebp,
    768: kitchen3afterI768wwebp,
    1024: kitchen3afterI1024wwebp,
    1440: kitchen3afterI1440wwebp,
    1920: kitchen3afterI1920wwebp,
    2048: kitchen3afterI2048wwebp,
  },
  'avif': {
    480: kitchen3afterI480wavif,
    768: kitchen3afterI768wavif,
    1024: kitchen3afterI1024wavif,
    1440: kitchen3afterI1440wavif,
    1920: kitchen3afterI1920wavif,
    2048: kitchen3afterI2048wavif,
  },
},
'kitchen3-afterJ.jpg': {
  _path: kitchen3afterJjpg,
  'png': {
    480: kitchen3afterJ480wpng,
    768: kitchen3afterJ768wpng,
    1024: kitchen3afterJ1024wpng,
    1440: kitchen3afterJ1440wpng,
    1920: kitchen3afterJ1920wpng,
    2048: kitchen3afterJ2048wpng,
  },
  'webp': {
    480: kitchen3afterJ480wwebp,
    768: kitchen3afterJ768wwebp,
    1024: kitchen3afterJ1024wwebp,
    1440: kitchen3afterJ1440wwebp,
    1920: kitchen3afterJ1920wwebp,
    2048: kitchen3afterJ2048wwebp,
  },
  'avif': {
    480: kitchen3afterJ480wavif,
    768: kitchen3afterJ768wavif,
    1024: kitchen3afterJ1024wavif,
    1440: kitchen3afterJ1440wavif,
    1920: kitchen3afterJ1920wavif,
    2048: kitchen3afterJ2048wavif,
  },
},
'kitchen3-afterK.jpg': {
  _path: kitchen3afterKjpg,
  'png': {
    480: kitchen3afterK480wpng,
    768: kitchen3afterK768wpng,
    1024: kitchen3afterK1024wpng,
    1440: kitchen3afterK1440wpng,
    1920: kitchen3afterK1920wpng,
    2048: kitchen3afterK2048wpng,
  },
  'webp': {
    480: kitchen3afterK480wwebp,
    768: kitchen3afterK768wwebp,
    1024: kitchen3afterK1024wwebp,
    1440: kitchen3afterK1440wwebp,
    1920: kitchen3afterK1920wwebp,
    2048: kitchen3afterK2048wwebp,
  },
  'avif': {
    480: kitchen3afterK480wavif,
    768: kitchen3afterK768wavif,
    1024: kitchen3afterK1024wavif,
    1440: kitchen3afterK1440wavif,
    1920: kitchen3afterK1920wavif,
    2048: kitchen3afterK2048wavif,
  },
},
'kitchen3-before1.jpg': {
  _path: kitchen3before1jpg,
  'png': {
    480: kitchen3before1480wpng,
    768: kitchen3before1768wpng,
    1024: kitchen3before11024wpng,
    1440: kitchen3before11440wpng,
    1920: kitchen3before11920wpng,
    2048: kitchen3before12048wpng,
  },
  'webp': {
    480: kitchen3before1480wwebp,
    768: kitchen3before1768wwebp,
    1024: kitchen3before11024wwebp,
    1440: kitchen3before11440wwebp,
    1920: kitchen3before11920wwebp,
    2048: kitchen3before12048wwebp,
  },
  'avif': {
    480: kitchen3before1480wavif,
    768: kitchen3before1768wavif,
    1024: kitchen3before11024wavif,
    1440: kitchen3before11440wavif,
    1920: kitchen3before11920wavif,
    2048: kitchen3before12048wavif,
  },
},
'kitchen3-before2.jpg': {
  _path: kitchen3before2jpg,
  'png': {
    480: kitchen3before2480wpng,
    768: kitchen3before2768wpng,
    1024: kitchen3before21024wpng,
    1440: kitchen3before21440wpng,
    1920: kitchen3before21920wpng,
    2048: kitchen3before22048wpng,
  },
  'webp': {
    480: kitchen3before2480wwebp,
    768: kitchen3before2768wwebp,
    1024: kitchen3before21024wwebp,
    1440: kitchen3before21440wwebp,
    1920: kitchen3before21920wwebp,
    2048: kitchen3before22048wwebp,
  },
  'avif': {
    480: kitchen3before2480wavif,
    768: kitchen3before2768wavif,
    1024: kitchen3before21024wavif,
    1440: kitchen3before21440wavif,
    1920: kitchen3before21920wavif,
    2048: kitchen3before22048wavif,
  },
},
'kitchen3-before3.jpg': {
  _path: kitchen3before3jpg,
  'png': {
    480: kitchen3before3480wpng,
    768: kitchen3before3768wpng,
    1024: kitchen3before31024wpng,
    1440: kitchen3before31440wpng,
    1920: kitchen3before31920wpng,
    2048: kitchen3before32048wpng,
  },
  'webp': {
    480: kitchen3before3480wwebp,
    768: kitchen3before3768wwebp,
    1024: kitchen3before31024wwebp,
    1440: kitchen3before31440wwebp,
    1920: kitchen3before31920wwebp,
    2048: kitchen3before32048wwebp,
  },
  'avif': {
    480: kitchen3before3480wavif,
    768: kitchen3before3768wavif,
    1024: kitchen3before31024wavif,
    1440: kitchen3before31440wavif,
    1920: kitchen3before31920wavif,
    2048: kitchen3before32048wavif,
  },
},
'kitchen4-afterA.jpg': {
  _path: kitchen4afterAjpg,
  'png': {
    480: kitchen4afterA480wpng,
    768: kitchen4afterA768wpng,
    1024: kitchen4afterA1024wpng,
    1440: kitchen4afterA1440wpng,
    1920: kitchen4afterA1920wpng,
    2048: kitchen4afterA2048wpng,
  },
  'webp': {
    480: kitchen4afterA480wwebp,
    768: kitchen4afterA768wwebp,
    1024: kitchen4afterA1024wwebp,
    1440: kitchen4afterA1440wwebp,
    1920: kitchen4afterA1920wwebp,
    2048: kitchen4afterA2048wwebp,
  },
  'avif': {
    480: kitchen4afterA480wavif,
    768: kitchen4afterA768wavif,
    1024: kitchen4afterA1024wavif,
    1440: kitchen4afterA1440wavif,
    1920: kitchen4afterA1920wavif,
    2048: kitchen4afterA2048wavif,
  },
},
'kitchen4-afterB.jpg': {
  _path: kitchen4afterBjpg,
  'png': {
    480: kitchen4afterB480wpng,
    768: kitchen4afterB768wpng,
    1024: kitchen4afterB1024wpng,
    1440: kitchen4afterB1440wpng,
    1920: kitchen4afterB1920wpng,
    2048: kitchen4afterB2048wpng,
  },
  'webp': {
    480: kitchen4afterB480wwebp,
    768: kitchen4afterB768wwebp,
    1024: kitchen4afterB1024wwebp,
    1440: kitchen4afterB1440wwebp,
    1920: kitchen4afterB1920wwebp,
    2048: kitchen4afterB2048wwebp,
  },
  'avif': {
    480: kitchen4afterB480wavif,
    768: kitchen4afterB768wavif,
    1024: kitchen4afterB1024wavif,
    1440: kitchen4afterB1440wavif,
    1920: kitchen4afterB1920wavif,
    2048: kitchen4afterB2048wavif,
  },
},
'kitchen4-afterC.jpg': {
  _path: kitchen4afterCjpg,
  'png': {
    480: kitchen4afterC480wpng,
    768: kitchen4afterC768wpng,
    1024: kitchen4afterC1024wpng,
    1440: kitchen4afterC1440wpng,
    1920: kitchen4afterC1920wpng,
    2048: kitchen4afterC2048wpng,
  },
  'webp': {
    480: kitchen4afterC480wwebp,
    768: kitchen4afterC768wwebp,
    1024: kitchen4afterC1024wwebp,
    1440: kitchen4afterC1440wwebp,
    1920: kitchen4afterC1920wwebp,
    2048: kitchen4afterC2048wwebp,
  },
  'avif': {
    480: kitchen4afterC480wavif,
    768: kitchen4afterC768wavif,
    1024: kitchen4afterC1024wavif,
    1440: kitchen4afterC1440wavif,
    1920: kitchen4afterC1920wavif,
    2048: kitchen4afterC2048wavif,
  },
},
'kitchen4-afterD.jpg': {
  _path: kitchen4afterDjpg,
  'png': {
    480: kitchen4afterD480wpng,
    768: kitchen4afterD768wpng,
    1024: kitchen4afterD1024wpng,
    1440: kitchen4afterD1440wpng,
    1920: kitchen4afterD1920wpng,
    2048: kitchen4afterD2048wpng,
  },
  'webp': {
    480: kitchen4afterD480wwebp,
    768: kitchen4afterD768wwebp,
    1024: kitchen4afterD1024wwebp,
    1440: kitchen4afterD1440wwebp,
    1920: kitchen4afterD1920wwebp,
    2048: kitchen4afterD2048wwebp,
  },
  'avif': {
    480: kitchen4afterD480wavif,
    768: kitchen4afterD768wavif,
    1024: kitchen4afterD1024wavif,
    1440: kitchen4afterD1440wavif,
    1920: kitchen4afterD1920wavif,
    2048: kitchen4afterD2048wavif,
  },
},
'kitchen4-afterE.jpg': {
  _path: kitchen4afterEjpg,
  'png': {
    480: kitchen4afterE480wpng,
    768: kitchen4afterE768wpng,
    1024: kitchen4afterE1024wpng,
    1440: kitchen4afterE1440wpng,
    1920: kitchen4afterE1920wpng,
    2048: kitchen4afterE2048wpng,
  },
  'webp': {
    480: kitchen4afterE480wwebp,
    768: kitchen4afterE768wwebp,
    1024: kitchen4afterE1024wwebp,
    1440: kitchen4afterE1440wwebp,
    1920: kitchen4afterE1920wwebp,
    2048: kitchen4afterE2048wwebp,
  },
  'avif': {
    480: kitchen4afterE480wavif,
    768: kitchen4afterE768wavif,
    1024: kitchen4afterE1024wavif,
    1440: kitchen4afterE1440wavif,
    1920: kitchen4afterE1920wavif,
    2048: kitchen4afterE2048wavif,
  },
},
'kitchen4-afterF.jpg': {
  _path: kitchen4afterFjpg,
  'png': {
    480: kitchen4afterF480wpng,
    768: kitchen4afterF768wpng,
    1024: kitchen4afterF1024wpng,
    1440: kitchen4afterF1440wpng,
    1920: kitchen4afterF1920wpng,
    2048: kitchen4afterF2048wpng,
  },
  'webp': {
    480: kitchen4afterF480wwebp,
    768: kitchen4afterF768wwebp,
    1024: kitchen4afterF1024wwebp,
    1440: kitchen4afterF1440wwebp,
    1920: kitchen4afterF1920wwebp,
    2048: kitchen4afterF2048wwebp,
  },
  'avif': {
    480: kitchen4afterF480wavif,
    768: kitchen4afterF768wavif,
    1024: kitchen4afterF1024wavif,
    1440: kitchen4afterF1440wavif,
    1920: kitchen4afterF1920wavif,
    2048: kitchen4afterF2048wavif,
  },
},
'kitchen5-afterA.jpg': {
  _path: kitchen5afterAjpg,
  'png': {
    480: kitchen5afterA480wpng,
    768: kitchen5afterA768wpng,
    1024: kitchen5afterA1024wpng,
    1440: kitchen5afterA1440wpng,
    1920: kitchen5afterA1920wpng,
    2048: kitchen5afterA2048wpng,
  },
  'webp': {
    480: kitchen5afterA480wwebp,
    768: kitchen5afterA768wwebp,
    1024: kitchen5afterA1024wwebp,
    1440: kitchen5afterA1440wwebp,
    1920: kitchen5afterA1920wwebp,
    2048: kitchen5afterA2048wwebp,
  },
  'avif': {
    480: kitchen5afterA480wavif,
    768: kitchen5afterA768wavif,
    1024: kitchen5afterA1024wavif,
    1440: kitchen5afterA1440wavif,
    1920: kitchen5afterA1920wavif,
    2048: kitchen5afterA2048wavif,
  },
},
'kitchen5-afterB.jpg': {
  _path: kitchen5afterBjpg,
  'png': {
    480: kitchen5afterB480wpng,
    768: kitchen5afterB768wpng,
    1024: kitchen5afterB1024wpng,
    1440: kitchen5afterB1440wpng,
    1920: kitchen5afterB1920wpng,
    2048: kitchen5afterB2048wpng,
  },
  'webp': {
    480: kitchen5afterB480wwebp,
    768: kitchen5afterB768wwebp,
    1024: kitchen5afterB1024wwebp,
    1440: kitchen5afterB1440wwebp,
    1920: kitchen5afterB1920wwebp,
    2048: kitchen5afterB2048wwebp,
  },
  'avif': {
    480: kitchen5afterB480wavif,
    768: kitchen5afterB768wavif,
    1024: kitchen5afterB1024wavif,
    1440: kitchen5afterB1440wavif,
    1920: kitchen5afterB1920wavif,
    2048: kitchen5afterB2048wavif,
  },
},
'kitchen5-afterC.jpg': {
  _path: kitchen5afterCjpg,
  'png': {
    480: kitchen5afterC480wpng,
    768: kitchen5afterC768wpng,
    1024: kitchen5afterC1024wpng,
    1440: kitchen5afterC1440wpng,
    1920: kitchen5afterC1920wpng,
    2048: kitchen5afterC2048wpng,
  },
  'webp': {
    480: kitchen5afterC480wwebp,
    768: kitchen5afterC768wwebp,
    1024: kitchen5afterC1024wwebp,
    1440: kitchen5afterC1440wwebp,
    1920: kitchen5afterC1920wwebp,
    2048: kitchen5afterC2048wwebp,
  },
  'avif': {
    480: kitchen5afterC480wavif,
    768: kitchen5afterC768wavif,
    1024: kitchen5afterC1024wavif,
    1440: kitchen5afterC1440wavif,
    1920: kitchen5afterC1920wavif,
    2048: kitchen5afterC2048wavif,
  },
},
'kitchen5-afterD.jpg': {
  _path: kitchen5afterDjpg,
  'png': {
    480: kitchen5afterD480wpng,
    768: kitchen5afterD768wpng,
    1024: kitchen5afterD1024wpng,
    1440: kitchen5afterD1440wpng,
    1920: kitchen5afterD1920wpng,
    2048: kitchen5afterD2048wpng,
  },
  'webp': {
    480: kitchen5afterD480wwebp,
    768: kitchen5afterD768wwebp,
    1024: kitchen5afterD1024wwebp,
    1440: kitchen5afterD1440wwebp,
    1920: kitchen5afterD1920wwebp,
    2048: kitchen5afterD2048wwebp,
  },
  'avif': {
    480: kitchen5afterD480wavif,
    768: kitchen5afterD768wavif,
    1024: kitchen5afterD1024wavif,
    1440: kitchen5afterD1440wavif,
    1920: kitchen5afterD1920wavif,
    2048: kitchen5afterD2048wavif,
  },
},
'kitchen5-afterE.jpg': {
  _path: kitchen5afterEjpg,
  'png': {
    480: kitchen5afterE480wpng,
    768: kitchen5afterE768wpng,
    1024: kitchen5afterE1024wpng,
    1440: kitchen5afterE1440wpng,
    1920: kitchen5afterE1920wpng,
    2048: kitchen5afterE2048wpng,
  },
  'webp': {
    480: kitchen5afterE480wwebp,
    768: kitchen5afterE768wwebp,
    1024: kitchen5afterE1024wwebp,
    1440: kitchen5afterE1440wwebp,
    1920: kitchen5afterE1920wwebp,
    2048: kitchen5afterE2048wwebp,
  },
  'avif': {
    480: kitchen5afterE480wavif,
    768: kitchen5afterE768wavif,
    1024: kitchen5afterE1024wavif,
    1440: kitchen5afterE1440wavif,
    1920: kitchen5afterE1920wavif,
    2048: kitchen5afterE2048wavif,
  },
},
'kitchen5-beforeA.jpg': {
  _path: kitchen5beforeAjpg,
  'png': {
    480: kitchen5beforeA480wpng,
    768: kitchen5beforeA768wpng,
    1024: kitchen5beforeA1024wpng,
    1440: kitchen5beforeA1440wpng,
    1920: kitchen5beforeA1920wpng,
    2048: kitchen5beforeA2048wpng,
  },
  'webp': {
    480: kitchen5beforeA480wwebp,
    768: kitchen5beforeA768wwebp,
    1024: kitchen5beforeA1024wwebp,
    1440: kitchen5beforeA1440wwebp,
    1920: kitchen5beforeA1920wwebp,
    2048: kitchen5beforeA2048wwebp,
  },
  'avif': {
    480: kitchen5beforeA480wavif,
    768: kitchen5beforeA768wavif,
    1024: kitchen5beforeA1024wavif,
    1440: kitchen5beforeA1440wavif,
    1920: kitchen5beforeA1920wavif,
    2048: kitchen5beforeA2048wavif,
  },
},
'kitchen5-beforeB.jpg': {
  _path: kitchen5beforeBjpg,
  'png': {
    480: kitchen5beforeB480wpng,
    768: kitchen5beforeB768wpng,
    1024: kitchen5beforeB1024wpng,
    1440: kitchen5beforeB1440wpng,
    1920: kitchen5beforeB1920wpng,
    2048: kitchen5beforeB2048wpng,
  },
  'webp': {
    480: kitchen5beforeB480wwebp,
    768: kitchen5beforeB768wwebp,
    1024: kitchen5beforeB1024wwebp,
    1440: kitchen5beforeB1440wwebp,
    1920: kitchen5beforeB1920wwebp,
    2048: kitchen5beforeB2048wwebp,
  },
  'avif': {
    480: kitchen5beforeB480wavif,
    768: kitchen5beforeB768wavif,
    1024: kitchen5beforeB1024wavif,
    1440: kitchen5beforeB1440wavif,
    1920: kitchen5beforeB1920wavif,
    2048: kitchen5beforeB2048wavif,
  },
},
'kitchen5-beforeC.jpg': {
  _path: kitchen5beforeCjpg,
  'png': {
    480: kitchen5beforeC480wpng,
    768: kitchen5beforeC768wpng,
    1024: kitchen5beforeC1024wpng,
    1440: kitchen5beforeC1440wpng,
    1920: kitchen5beforeC1920wpng,
    2048: kitchen5beforeC2048wpng,
  },
  'webp': {
    480: kitchen5beforeC480wwebp,
    768: kitchen5beforeC768wwebp,
    1024: kitchen5beforeC1024wwebp,
    1440: kitchen5beforeC1440wwebp,
    1920: kitchen5beforeC1920wwebp,
    2048: kitchen5beforeC2048wwebp,
  },
  'avif': {
    480: kitchen5beforeC480wavif,
    768: kitchen5beforeC768wavif,
    1024: kitchen5beforeC1024wavif,
    1440: kitchen5beforeC1440wavif,
    1920: kitchen5beforeC1920wavif,
    2048: kitchen5beforeC2048wavif,
  },
},
'kitchen5-beforeD.jpg': {
  _path: kitchen5beforeDjpg,
  'png': {
    480: kitchen5beforeD480wpng,
    768: kitchen5beforeD768wpng,
    1024: kitchen5beforeD1024wpng,
    1440: kitchen5beforeD1440wpng,
    1920: kitchen5beforeD1920wpng,
    2048: kitchen5beforeD2048wpng,
  },
  'webp': {
    480: kitchen5beforeD480wwebp,
    768: kitchen5beforeD768wwebp,
    1024: kitchen5beforeD1024wwebp,
    1440: kitchen5beforeD1440wwebp,
    1920: kitchen5beforeD1920wwebp,
    2048: kitchen5beforeD2048wwebp,
  },
  'avif': {
    480: kitchen5beforeD480wavif,
    768: kitchen5beforeD768wavif,
    1024: kitchen5beforeD1024wavif,
    1440: kitchen5beforeD1440wavif,
    1920: kitchen5beforeD1920wavif,
    2048: kitchen5beforeD2048wavif,
  },
},
'kitchen5-beforeE.jpg': {
  _path: kitchen5beforeEjpg,
  'png': {
    480: kitchen5beforeE480wpng,
    768: kitchen5beforeE768wpng,
    1024: kitchen5beforeE1024wpng,
    1440: kitchen5beforeE1440wpng,
    1920: kitchen5beforeE1920wpng,
    2048: kitchen5beforeE2048wpng,
  },
  'webp': {
    480: kitchen5beforeE480wwebp,
    768: kitchen5beforeE768wwebp,
    1024: kitchen5beforeE1024wwebp,
    1440: kitchen5beforeE1440wwebp,
    1920: kitchen5beforeE1920wwebp,
    2048: kitchen5beforeE2048wwebp,
  },
  'avif': {
    480: kitchen5beforeE480wavif,
    768: kitchen5beforeE768wavif,
    1024: kitchen5beforeE1024wavif,
    1440: kitchen5beforeE1440wavif,
    1920: kitchen5beforeE1920wavif,
    2048: kitchen5beforeE2048wavif,
  },
},
'laundry1-afterA.jpg': {
  _path: laundry1afterAjpg,
  'png': {
    480: laundry1afterA480wpng,
    768: laundry1afterA768wpng,
    1024: laundry1afterA1024wpng,
    1440: laundry1afterA1440wpng,
    1920: laundry1afterA1920wpng,
    2048: laundry1afterA2048wpng,
  },
  'webp': {
    480: laundry1afterA480wwebp,
    768: laundry1afterA768wwebp,
    1024: laundry1afterA1024wwebp,
    1440: laundry1afterA1440wwebp,
    1920: laundry1afterA1920wwebp,
    2048: laundry1afterA2048wwebp,
  },
  'avif': {
    480: laundry1afterA480wavif,
    768: laundry1afterA768wavif,
    1024: laundry1afterA1024wavif,
    1440: laundry1afterA1440wavif,
    1920: laundry1afterA1920wavif,
    2048: laundry1afterA2048wavif,
  },
},
'laundry1-beforeA.jpg': {
  _path: laundry1beforeAjpg,
  'png': {
    480: laundry1beforeA480wpng,
    768: laundry1beforeA768wpng,
    1024: laundry1beforeA1024wpng,
    1440: laundry1beforeA1440wpng,
    1920: laundry1beforeA1920wpng,
    2048: laundry1beforeA2048wpng,
  },
  'webp': {
    480: laundry1beforeA480wwebp,
    768: laundry1beforeA768wwebp,
    1024: laundry1beforeA1024wwebp,
    1440: laundry1beforeA1440wwebp,
    1920: laundry1beforeA1920wwebp,
    2048: laundry1beforeA2048wwebp,
  },
  'avif': {
    480: laundry1beforeA480wavif,
    768: laundry1beforeA768wavif,
    1024: laundry1beforeA1024wavif,
    1440: laundry1beforeA1440wavif,
    1920: laundry1beforeA1920wavif,
    2048: laundry1beforeA2048wavif,
  },
},
'laundry2-after.jpg': {
  _path: laundry2afterjpg,
  'png': {
    480: laundry2after480wpng,
    768: laundry2after768wpng,
    1024: laundry2after1024wpng,
    1440: laundry2after1440wpng,
    1920: laundry2after1920wpng,
    2048: laundry2after2048wpng,
  },
  'webp': {
    480: laundry2after480wwebp,
    768: laundry2after768wwebp,
    1024: laundry2after1024wwebp,
    1440: laundry2after1440wwebp,
    1920: laundry2after1920wwebp,
    2048: laundry2after2048wwebp,
  },
  'avif': {
    480: laundry2after480wavif,
    768: laundry2after768wavif,
    1024: laundry2after1024wavif,
    1440: laundry2after1440wavif,
    1920: laundry2after1920wavif,
    2048: laundry2after2048wavif,
  },
},
'laundry2-afterB.jpg': {
  _path: laundry2afterBjpg,
  'png': {
    480: laundry2afterB480wpng,
    768: laundry2afterB768wpng,
    1024: laundry2afterB1024wpng,
    1440: laundry2afterB1440wpng,
    1920: laundry2afterB1920wpng,
    2048: laundry2afterB2048wpng,
  },
  'webp': {
    480: laundry2afterB480wwebp,
    768: laundry2afterB768wwebp,
    1024: laundry2afterB1024wwebp,
    1440: laundry2afterB1440wwebp,
    1920: laundry2afterB1920wwebp,
    2048: laundry2afterB2048wwebp,
  },
  'avif': {
    480: laundry2afterB480wavif,
    768: laundry2afterB768wavif,
    1024: laundry2afterB1024wavif,
    1440: laundry2afterB1440wavif,
    1920: laundry2afterB1920wavif,
    2048: laundry2afterB2048wavif,
  },
},
'laundry2-before.jpg': {
  _path: laundry2beforejpg,
  'png': {
    480: laundry2before480wpng,
    768: laundry2before768wpng,
    1024: laundry2before1024wpng,
    1440: laundry2before1440wpng,
    1920: laundry2before1920wpng,
    2048: laundry2before2048wpng,
  },
  'webp': {
    480: laundry2before480wwebp,
    768: laundry2before768wwebp,
    1024: laundry2before1024wwebp,
    1440: laundry2before1440wwebp,
    1920: laundry2before1920wwebp,
    2048: laundry2before2048wwebp,
  },
  'avif': {
    480: laundry2before480wavif,
    768: laundry2before768wavif,
    1024: laundry2before1024wavif,
    1440: laundry2before1440wavif,
    1920: laundry2before1920wavif,
    2048: laundry2before2048wavif,
  },
},
'laundry2-beforeB.jpg': {
  _path: laundry2beforeBjpg,
  'png': {
    480: laundry2beforeB480wpng,
    768: laundry2beforeB768wpng,
    1024: laundry2beforeB1024wpng,
    1440: laundry2beforeB1440wpng,
    1920: laundry2beforeB1920wpng,
    2048: laundry2beforeB2048wpng,
  },
  'webp': {
    480: laundry2beforeB480wwebp,
    768: laundry2beforeB768wwebp,
    1024: laundry2beforeB1024wwebp,
    1440: laundry2beforeB1440wwebp,
    1920: laundry2beforeB1920wwebp,
    2048: laundry2beforeB2048wwebp,
  },
  'avif': {
    480: laundry2beforeB480wavif,
    768: laundry2beforeB768wavif,
    1024: laundry2beforeB1024wavif,
    1440: laundry2beforeB1440wavif,
    1920: laundry2beforeB1920wavif,
    2048: laundry2beforeB2048wavif,
  },
},
'learose.jpg': {
  _path: learosejpg,
  'png': {
    480: learose480wpng,
    768: learose768wpng,
    1024: learose1024wpng,
    1440: learose1440wpng,
    1920: learose1920wpng,
    2048: learose2048wpng,
  },
  'webp': {
    480: learose480wwebp,
    768: learose768wwebp,
    1024: learose1024wwebp,
    1440: learose1440wwebp,
    1920: learose1920wwebp,
    2048: learose2048wwebp,
  },
  'avif': {
    480: learose480wavif,
    768: learose768wavif,
    1024: learose1024wavif,
    1440: learose1440wavif,
    1920: learose1920wavif,
    2048: learose2048wavif,
  },
},
'learose2.jpg': {
  _path: learose2jpg,
  'png': {
    480: learose2480wpng,
    768: learose2768wpng,
    1024: learose21024wpng,
    1440: learose21440wpng,
    1920: learose21920wpng,
    2048: learose22048wpng,
  },
  'webp': {
    480: learose2480wwebp,
    768: learose2768wwebp,
    1024: learose21024wwebp,
    1440: learose21440wwebp,
    1920: learose21920wwebp,
    2048: learose22048wwebp,
  },
  'avif': {
    480: learose2480wavif,
    768: learose2768wavif,
    1024: learose21024wavif,
    1440: learose21440wavif,
    1920: learose21920wavif,
    2048: learose22048wavif,
  },
},
'learose3.jpg': {
  _path: learose3jpg,
  'png': {
    480: learose3480wpng,
    768: learose3768wpng,
    1024: learose31024wpng,
    1440: learose31440wpng,
    1920: learose31920wpng,
    2048: learose32048wpng,
  },
  'webp': {
    480: learose3480wwebp,
    768: learose3768wwebp,
    1024: learose31024wwebp,
    1440: learose31440wwebp,
    1920: learose31920wwebp,
    2048: learose32048wwebp,
  },
  'avif': {
    480: learose3480wavif,
    768: learose3768wavif,
    1024: learose31024wavif,
    1440: learose31440wavif,
    1920: learose31920wavif,
    2048: learose32048wavif,
  },
},
'learose4.jpg': {
  _path: learose4jpg,
  'png': {
    480: learose4480wpng,
    768: learose4768wpng,
    1024: learose41024wpng,
    1440: learose41440wpng,
    1920: learose41920wpng,
    2048: learose42048wpng,
  },
  'webp': {
    480: learose4480wwebp,
    768: learose4768wwebp,
    1024: learose41024wwebp,
    1440: learose41440wwebp,
    1920: learose41920wwebp,
    2048: learose42048wwebp,
  },
  'avif': {
    480: learose4480wavif,
    768: learose4768wavif,
    1024: learose41024wavif,
    1440: learose41440wavif,
    1920: learose41920wavif,
    2048: learose42048wavif,
  },
},
'learose5.jpg': {
  _path: learose5jpg,
  'png': {
    480: learose5480wpng,
    768: learose5768wpng,
    1024: learose51024wpng,
    1440: learose51440wpng,
    1920: learose51920wpng,
    2048: learose52048wpng,
  },
  'webp': {
    480: learose5480wwebp,
    768: learose5768wwebp,
    1024: learose51024wwebp,
    1440: learose51440wwebp,
    1920: learose51920wwebp,
    2048: learose52048wwebp,
  },
  'avif': {
    480: learose5480wavif,
    768: learose5768wavif,
    1024: learose51024wavif,
    1440: learose51440wavif,
    1920: learose51920wavif,
    2048: learose52048wavif,
  },
},
'logo-card.jpg': {
  _path: logocardjpg,
  'png': {
    480: logocard480wpng,
    768: logocard768wpng,
    1024: logocard1024wpng,
    1440: logocard1440wpng,
    1920: logocard1920wpng,
    2048: logocard2048wpng,
  },
  'webp': {
    480: logocard480wwebp,
    768: logocard768wwebp,
    1024: logocard1024wwebp,
    1440: logocard1440wwebp,
    1920: logocard1920wwebp,
    2048: logocard2048wwebp,
  },
  'avif': {
    480: logocard480wavif,
    768: logocard768wavif,
    1024: logocard1024wavif,
    1440: logocard1440wavif,
    1920: logocard1920wavif,
    2048: logocard2048wavif,
  },
},
'logo-lg.jpg': {
  _path: logolgjpg,
  'png': {
    480: logolg480wpng,
    768: logolg768wpng,
    1024: logolg1024wpng,
    1440: logolg1440wpng,
    1920: logolg1920wpng,
    2048: logolg2048wpng,
  },
  'webp': {
    480: logolg480wwebp,
    768: logolg768wwebp,
    1024: logolg1024wwebp,
    1440: logolg1440wwebp,
    1920: logolg1920wwebp,
    2048: logolg2048wwebp,
  },
  'avif': {
    480: logolg480wavif,
    768: logolg768wavif,
    1024: logolg1024wavif,
    1440: logolg1440wavif,
    1920: logolg1920wavif,
    2048: logolg2048wavif,
  },
},
'logo-md.jpg': {
  _path: logomdjpg,
  'png': {
    480: logomd480wpng,
    768: logomd768wpng,
    1024: logomd1024wpng,
    1440: logomd1440wpng,
    1920: logomd1920wpng,
    2048: logomd2048wpng,
  },
  'webp': {
    480: logomd480wwebp,
    768: logomd768wwebp,
    1024: logomd1024wwebp,
    1440: logomd1440wwebp,
    1920: logomd1920wwebp,
    2048: logomd2048wwebp,
  },
  'avif': {
    480: logomd480wavif,
    768: logomd768wavif,
    1024: logomd1024wavif,
    1440: logomd1440wavif,
    1920: logomd1920wavif,
    2048: logomd2048wavif,
  },
},
'logo-xl.jpg': {
  _path: logoxljpg,
  'png': {
    480: logoxl480wpng,
    768: logoxl768wpng,
    1024: logoxl1024wpng,
    1440: logoxl1440wpng,
    1920: logoxl1920wpng,
    2048: logoxl2048wpng,
  },
  'webp': {
    480: logoxl480wwebp,
    768: logoxl768wwebp,
    1024: logoxl1024wwebp,
    1440: logoxl1440wwebp,
    1920: logoxl1920wwebp,
    2048: logoxl2048wwebp,
  },
  'avif': {
    480: logoxl480wavif,
    768: logoxl768wavif,
    1024: logoxl1024wavif,
    1440: logoxl1440wavif,
    1920: logoxl1920wavif,
    2048: logoxl2048wavif,
  },
},
'misc-bathtubA.jpg': {
  _path: miscbathtubAjpg,
  'png': {
    480: miscbathtubA480wpng,
    768: miscbathtubA768wpng,
    1024: miscbathtubA1024wpng,
    1440: miscbathtubA1440wpng,
    1920: miscbathtubA1920wpng,
    2048: miscbathtubA2048wpng,
  },
  'webp': {
    480: miscbathtubA480wwebp,
    768: miscbathtubA768wwebp,
    1024: miscbathtubA1024wwebp,
    1440: miscbathtubA1440wwebp,
    1920: miscbathtubA1920wwebp,
    2048: miscbathtubA2048wwebp,
  },
  'avif': {
    480: miscbathtubA480wavif,
    768: miscbathtubA768wavif,
    1024: miscbathtubA1024wavif,
    1440: miscbathtubA1440wavif,
    1920: miscbathtubA1920wavif,
    2048: miscbathtubA2048wavif,
  },
},
'misc-books1.jpg': {
  _path: miscbooks1jpg,
  'png': {
    480: miscbooks1480wpng,
    768: miscbooks1768wpng,
    1024: miscbooks11024wpng,
    1440: miscbooks11440wpng,
    1920: miscbooks11920wpng,
    2048: miscbooks12048wpng,
  },
  'webp': {
    480: miscbooks1480wwebp,
    768: miscbooks1768wwebp,
    1024: miscbooks11024wwebp,
    1440: miscbooks11440wwebp,
    1920: miscbooks11920wwebp,
    2048: miscbooks12048wwebp,
  },
  'avif': {
    480: miscbooks1480wavif,
    768: miscbooks1768wavif,
    1024: miscbooks11024wavif,
    1440: miscbooks11440wavif,
    1920: miscbooks11920wavif,
    2048: miscbooks12048wavif,
  },
},
'misc-books2.jpg': {
  _path: miscbooks2jpg,
  'png': {
    480: miscbooks2480wpng,
    768: miscbooks2768wpng,
    1024: miscbooks21024wpng,
    1440: miscbooks21440wpng,
    1920: miscbooks21920wpng,
    2048: miscbooks22048wpng,
  },
  'webp': {
    480: miscbooks2480wwebp,
    768: miscbooks2768wwebp,
    1024: miscbooks21024wwebp,
    1440: miscbooks21440wwebp,
    1920: miscbooks21920wwebp,
    2048: miscbooks22048wwebp,
  },
  'avif': {
    480: miscbooks2480wavif,
    768: miscbooks2768wavif,
    1024: miscbooks21024wavif,
    1440: miscbooks21440wavif,
    1920: miscbooks21920wavif,
    2048: miscbooks22048wavif,
  },
},
'misc-books3.jpg': {
  _path: miscbooks3jpg,
  'png': {
    480: miscbooks3480wpng,
    768: miscbooks3768wpng,
    1024: miscbooks31024wpng,
    1440: miscbooks31440wpng,
    1920: miscbooks31920wpng,
    2048: miscbooks32048wpng,
  },
  'webp': {
    480: miscbooks3480wwebp,
    768: miscbooks3768wwebp,
    1024: miscbooks31024wwebp,
    1440: miscbooks31440wwebp,
    1920: miscbooks31920wwebp,
    2048: miscbooks32048wwebp,
  },
  'avif': {
    480: miscbooks3480wavif,
    768: miscbooks3768wavif,
    1024: miscbooks31024wavif,
    1440: miscbooks31440wavif,
    1920: miscbooks31920wavif,
    2048: miscbooks32048wavif,
  },
},
'misc-books4.jpg': {
  _path: miscbooks4jpg,
  'png': {
    480: miscbooks4480wpng,
    768: miscbooks4768wpng,
    1024: miscbooks41024wpng,
    1440: miscbooks41440wpng,
    1920: miscbooks41920wpng,
    2048: miscbooks42048wpng,
  },
  'webp': {
    480: miscbooks4480wwebp,
    768: miscbooks4768wwebp,
    1024: miscbooks41024wwebp,
    1440: miscbooks41440wwebp,
    1920: miscbooks41920wwebp,
    2048: miscbooks42048wwebp,
  },
  'avif': {
    480: miscbooks4480wavif,
    768: miscbooks4768wavif,
    1024: miscbooks41024wavif,
    1440: miscbooks41440wavif,
    1920: miscbooks41920wavif,
    2048: miscbooks42048wavif,
  },
},
'misc-books5.jpg': {
  _path: miscbooks5jpg,
  'png': {
    480: miscbooks5480wpng,
    768: miscbooks5768wpng,
    1024: miscbooks51024wpng,
    1440: miscbooks51440wpng,
    1920: miscbooks51920wpng,
    2048: miscbooks52048wpng,
  },
  'webp': {
    480: miscbooks5480wwebp,
    768: miscbooks5768wwebp,
    1024: miscbooks51024wwebp,
    1440: miscbooks51440wwebp,
    1920: miscbooks51920wwebp,
    2048: miscbooks52048wwebp,
  },
  'avif': {
    480: miscbooks5480wavif,
    768: miscbooks5768wavif,
    1024: miscbooks51024wavif,
    1440: miscbooks51440wavif,
    1920: miscbooks51920wavif,
    2048: miscbooks52048wavif,
  },
},
'misc-closet2.jpg': {
  _path: misccloset2jpg,
  'png': {
    480: misccloset2480wpng,
    768: misccloset2768wpng,
    1024: misccloset21024wpng,
    1440: misccloset21440wpng,
    1920: misccloset21920wpng,
    2048: misccloset22048wpng,
  },
  'webp': {
    480: misccloset2480wwebp,
    768: misccloset2768wwebp,
    1024: misccloset21024wwebp,
    1440: misccloset21440wwebp,
    1920: misccloset21920wwebp,
    2048: misccloset22048wwebp,
  },
  'avif': {
    480: misccloset2480wavif,
    768: misccloset2768wavif,
    1024: misccloset21024wavif,
    1440: misccloset21440wavif,
    1920: misccloset21920wavif,
    2048: misccloset22048wavif,
  },
},
'misc-closetA.jpg': {
  _path: miscclosetAjpg,
  'png': {
    480: miscclosetA480wpng,
    768: miscclosetA768wpng,
    1024: miscclosetA1024wpng,
    1440: miscclosetA1440wpng,
    1920: miscclosetA1920wpng,
    2048: miscclosetA2048wpng,
  },
  'webp': {
    480: miscclosetA480wwebp,
    768: miscclosetA768wwebp,
    1024: miscclosetA1024wwebp,
    1440: miscclosetA1440wwebp,
    1920: miscclosetA1920wwebp,
    2048: miscclosetA2048wwebp,
  },
  'avif': {
    480: miscclosetA480wavif,
    768: miscclosetA768wavif,
    1024: miscclosetA1024wavif,
    1440: miscclosetA1440wavif,
    1920: miscclosetA1920wavif,
    2048: miscclosetA2048wavif,
  },
},
'misc-closetB.jpg': {
  _path: miscclosetBjpg,
  'png': {
    480: miscclosetB480wpng,
    768: miscclosetB768wpng,
    1024: miscclosetB1024wpng,
    1440: miscclosetB1440wpng,
    1920: miscclosetB1920wpng,
    2048: miscclosetB2048wpng,
  },
  'webp': {
    480: miscclosetB480wwebp,
    768: miscclosetB768wwebp,
    1024: miscclosetB1024wwebp,
    1440: miscclosetB1440wwebp,
    1920: miscclosetB1920wwebp,
    2048: miscclosetB2048wwebp,
  },
  'avif': {
    480: miscclosetB480wavif,
    768: miscclosetB768wavif,
    1024: miscclosetB1024wavif,
    1440: miscclosetB1440wavif,
    1920: miscclosetB1920wavif,
    2048: miscclosetB2048wavif,
  },
},
'misc-closetC.jpg': {
  _path: miscclosetCjpg,
  'png': {
    480: miscclosetC480wpng,
    768: miscclosetC768wpng,
    1024: miscclosetC1024wpng,
    1440: miscclosetC1440wpng,
    1920: miscclosetC1920wpng,
    2048: miscclosetC2048wpng,
  },
  'webp': {
    480: miscclosetC480wwebp,
    768: miscclosetC768wwebp,
    1024: miscclosetC1024wwebp,
    1440: miscclosetC1440wwebp,
    1920: miscclosetC1920wwebp,
    2048: miscclosetC2048wwebp,
  },
  'avif': {
    480: miscclosetC480wavif,
    768: miscclosetC768wavif,
    1024: miscclosetC1024wavif,
    1440: miscclosetC1440wavif,
    1920: miscclosetC1920wavif,
    2048: miscclosetC2048wavif,
  },
},
'misc-drawerA.jpg': {
  _path: miscdrawerAjpg,
  'png': {
    480: miscdrawerA480wpng,
    768: miscdrawerA768wpng,
    1024: miscdrawerA1024wpng,
    1440: miscdrawerA1440wpng,
    1920: miscdrawerA1920wpng,
    2048: miscdrawerA2048wpng,
  },
  'webp': {
    480: miscdrawerA480wwebp,
    768: miscdrawerA768wwebp,
    1024: miscdrawerA1024wwebp,
    1440: miscdrawerA1440wwebp,
    1920: miscdrawerA1920wwebp,
    2048: miscdrawerA2048wwebp,
  },
  'avif': {
    480: miscdrawerA480wavif,
    768: miscdrawerA768wavif,
    1024: miscdrawerA1024wavif,
    1440: miscdrawerA1440wavif,
    1920: miscdrawerA1920wavif,
    2048: miscdrawerA2048wavif,
  },
},
'misc-drawerB.jpg': {
  _path: miscdrawerBjpg,
  'png': {
    480: miscdrawerB480wpng,
    768: miscdrawerB768wpng,
    1024: miscdrawerB1024wpng,
    1440: miscdrawerB1440wpng,
    1920: miscdrawerB1920wpng,
    2048: miscdrawerB2048wpng,
  },
  'webp': {
    480: miscdrawerB480wwebp,
    768: miscdrawerB768wwebp,
    1024: miscdrawerB1024wwebp,
    1440: miscdrawerB1440wwebp,
    1920: miscdrawerB1920wwebp,
    2048: miscdrawerB2048wwebp,
  },
  'avif': {
    480: miscdrawerB480wavif,
    768: miscdrawerB768wavif,
    1024: miscdrawerB1024wavif,
    1440: miscdrawerB1440wavif,
    1920: miscdrawerB1920wavif,
    2048: miscdrawerB2048wavif,
  },
},
'misc-fireplaceA.jpg': {
  _path: miscfireplaceAjpg,
  'png': {
    480: miscfireplaceA480wpng,
    768: miscfireplaceA768wpng,
    1024: miscfireplaceA1024wpng,
    1440: miscfireplaceA1440wpng,
    1920: miscfireplaceA1920wpng,
    2048: miscfireplaceA2048wpng,
  },
  'webp': {
    480: miscfireplaceA480wwebp,
    768: miscfireplaceA768wwebp,
    1024: miscfireplaceA1024wwebp,
    1440: miscfireplaceA1440wwebp,
    1920: miscfireplaceA1920wwebp,
    2048: miscfireplaceA2048wwebp,
  },
  'avif': {
    480: miscfireplaceA480wavif,
    768: miscfireplaceA768wavif,
    1024: miscfireplaceA1024wavif,
    1440: miscfireplaceA1440wavif,
    1920: miscfireplaceA1920wavif,
    2048: miscfireplaceA2048wavif,
  },
},
'misc-makeupA.jpg': {
  _path: miscmakeupAjpg,
  'png': {
    480: miscmakeupA480wpng,
    768: miscmakeupA768wpng,
    1024: miscmakeupA1024wpng,
    1440: miscmakeupA1440wpng,
    1920: miscmakeupA1920wpng,
    2048: miscmakeupA2048wpng,
  },
  'webp': {
    480: miscmakeupA480wwebp,
    768: miscmakeupA768wwebp,
    1024: miscmakeupA1024wwebp,
    1440: miscmakeupA1440wwebp,
    1920: miscmakeupA1920wwebp,
    2048: miscmakeupA2048wwebp,
  },
  'avif': {
    480: miscmakeupA480wavif,
    768: miscmakeupA768wavif,
    1024: miscmakeupA1024wavif,
    1440: miscmakeupA1440wavif,
    1920: miscmakeupA1920wavif,
    2048: miscmakeupA2048wavif,
  },
},
'misc-pantry1.jpg': {
  _path: miscpantry1jpg,
  'png': {
    480: miscpantry1480wpng,
    768: miscpantry1768wpng,
    1024: miscpantry11024wpng,
    1440: miscpantry11440wpng,
    1920: miscpantry11920wpng,
    2048: miscpantry12048wpng,
  },
  'webp': {
    480: miscpantry1480wwebp,
    768: miscpantry1768wwebp,
    1024: miscpantry11024wwebp,
    1440: miscpantry11440wwebp,
    1920: miscpantry11920wwebp,
    2048: miscpantry12048wwebp,
  },
  'avif': {
    480: miscpantry1480wavif,
    768: miscpantry1768wavif,
    1024: miscpantry11024wavif,
    1440: miscpantry11440wavif,
    1920: miscpantry11920wavif,
    2048: miscpantry12048wavif,
  },
},
'misc-pantry2.jpg': {
  _path: miscpantry2jpg,
  'png': {
    480: miscpantry2480wpng,
    768: miscpantry2768wpng,
    1024: miscpantry21024wpng,
    1440: miscpantry21440wpng,
    1920: miscpantry21920wpng,
    2048: miscpantry22048wpng,
  },
  'webp': {
    480: miscpantry2480wwebp,
    768: miscpantry2768wwebp,
    1024: miscpantry21024wwebp,
    1440: miscpantry21440wwebp,
    1920: miscpantry21920wwebp,
    2048: miscpantry22048wwebp,
  },
  'avif': {
    480: miscpantry2480wavif,
    768: miscpantry2768wavif,
    1024: miscpantry21024wavif,
    1440: miscpantry21440wavif,
    1920: miscpantry21920wavif,
    2048: miscpantry22048wavif,
  },
},
'misc-pantry3.jpg': {
  _path: miscpantry3jpg,
  'png': {
    480: miscpantry3480wpng,
    768: miscpantry3768wpng,
    1024: miscpantry31024wpng,
    1440: miscpantry31440wpng,
    1920: miscpantry31920wpng,
    2048: miscpantry32048wpng,
  },
  'webp': {
    480: miscpantry3480wwebp,
    768: miscpantry3768wwebp,
    1024: miscpantry31024wwebp,
    1440: miscpantry31440wwebp,
    1920: miscpantry31920wwebp,
    2048: miscpantry32048wwebp,
  },
  'avif': {
    480: miscpantry3480wavif,
    768: miscpantry3768wavif,
    1024: miscpantry31024wavif,
    1440: miscpantry31440wavif,
    1920: miscpantry31920wavif,
    2048: miscpantry32048wavif,
  },
},
'office1-after1.jpg': {
  _path: office1after1jpg,
  'png': {
    480: office1after1480wpng,
    768: office1after1768wpng,
    1024: office1after11024wpng,
    1440: office1after11440wpng,
    1920: office1after11920wpng,
    2048: office1after12048wpng,
  },
  'webp': {
    480: office1after1480wwebp,
    768: office1after1768wwebp,
    1024: office1after11024wwebp,
    1440: office1after11440wwebp,
    1920: office1after11920wwebp,
    2048: office1after12048wwebp,
  },
  'avif': {
    480: office1after1480wavif,
    768: office1after1768wavif,
    1024: office1after11024wavif,
    1440: office1after11440wavif,
    1920: office1after11920wavif,
    2048: office1after12048wavif,
  },
},
'office1-after2.jpg': {
  _path: office1after2jpg,
  'png': {
    480: office1after2480wpng,
    768: office1after2768wpng,
    1024: office1after21024wpng,
    1440: office1after21440wpng,
    1920: office1after21920wpng,
    2048: office1after22048wpng,
  },
  'webp': {
    480: office1after2480wwebp,
    768: office1after2768wwebp,
    1024: office1after21024wwebp,
    1440: office1after21440wwebp,
    1920: office1after21920wwebp,
    2048: office1after22048wwebp,
  },
  'avif': {
    480: office1after2480wavif,
    768: office1after2768wavif,
    1024: office1after21024wavif,
    1440: office1after21440wavif,
    1920: office1after21920wavif,
    2048: office1after22048wavif,
  },
},
'office1-before1.jpg': {
  _path: office1before1jpg,
  'png': {
    480: office1before1480wpng,
    768: office1before1768wpng,
    1024: office1before11024wpng,
    1440: office1before11440wpng,
    1920: office1before11920wpng,
    2048: office1before12048wpng,
  },
  'webp': {
    480: office1before1480wwebp,
    768: office1before1768wwebp,
    1024: office1before11024wwebp,
    1440: office1before11440wwebp,
    1920: office1before11920wwebp,
    2048: office1before12048wwebp,
  },
  'avif': {
    480: office1before1480wavif,
    768: office1before1768wavif,
    1024: office1before11024wavif,
    1440: office1before11440wavif,
    1920: office1before11920wavif,
    2048: office1before12048wavif,
  },
},
'office1-before2.jpg': {
  _path: office1before2jpg,
  'png': {
    480: office1before2480wpng,
    768: office1before2768wpng,
    1024: office1before21024wpng,
    1440: office1before21440wpng,
    1920: office1before21920wpng,
    2048: office1before22048wpng,
  },
  'webp': {
    480: office1before2480wwebp,
    768: office1before2768wwebp,
    1024: office1before21024wwebp,
    1440: office1before21440wwebp,
    1920: office1before21920wwebp,
    2048: office1before22048wwebp,
  },
  'avif': {
    480: office1before2480wavif,
    768: office1before2768wavif,
    1024: office1before21024wavif,
    1440: office1before21440wavif,
    1920: office1before21920wavif,
    2048: office1before22048wavif,
  },
},
'office2-afterA.jpg': {
  _path: office2afterAjpg,
  'png': {
    480: office2afterA480wpng,
    768: office2afterA768wpng,
    1024: office2afterA1024wpng,
    1440: office2afterA1440wpng,
    1920: office2afterA1920wpng,
    2048: office2afterA2048wpng,
  },
  'webp': {
    480: office2afterA480wwebp,
    768: office2afterA768wwebp,
    1024: office2afterA1024wwebp,
    1440: office2afterA1440wwebp,
    1920: office2afterA1920wwebp,
    2048: office2afterA2048wwebp,
  },
  'avif': {
    480: office2afterA480wavif,
    768: office2afterA768wavif,
    1024: office2afterA1024wavif,
    1440: office2afterA1440wavif,
    1920: office2afterA1920wavif,
    2048: office2afterA2048wavif,
  },
},
'office2-afterB.jpg': {
  _path: office2afterBjpg,
  'png': {
    480: office2afterB480wpng,
    768: office2afterB768wpng,
    1024: office2afterB1024wpng,
    1440: office2afterB1440wpng,
    1920: office2afterB1920wpng,
    2048: office2afterB2048wpng,
  },
  'webp': {
    480: office2afterB480wwebp,
    768: office2afterB768wwebp,
    1024: office2afterB1024wwebp,
    1440: office2afterB1440wwebp,
    1920: office2afterB1920wwebp,
    2048: office2afterB2048wwebp,
  },
  'avif': {
    480: office2afterB480wavif,
    768: office2afterB768wavif,
    1024: office2afterB1024wavif,
    1440: office2afterB1440wavif,
    1920: office2afterB1920wavif,
    2048: office2afterB2048wavif,
  },
},
'pantry1-afterA.jpg': {
  _path: pantry1afterAjpg,
  'png': {
    480: pantry1afterA480wpng,
    768: pantry1afterA768wpng,
    1024: pantry1afterA1024wpng,
    1440: pantry1afterA1440wpng,
    1920: pantry1afterA1920wpng,
    2048: pantry1afterA2048wpng,
  },
  'webp': {
    480: pantry1afterA480wwebp,
    768: pantry1afterA768wwebp,
    1024: pantry1afterA1024wwebp,
    1440: pantry1afterA1440wwebp,
    1920: pantry1afterA1920wwebp,
    2048: pantry1afterA2048wwebp,
  },
  'avif': {
    480: pantry1afterA480wavif,
    768: pantry1afterA768wavif,
    1024: pantry1afterA1024wavif,
    1440: pantry1afterA1440wavif,
    1920: pantry1afterA1920wavif,
    2048: pantry1afterA2048wavif,
  },
},
'pantry1-afterB.jpg': {
  _path: pantry1afterBjpg,
  'png': {
    480: pantry1afterB480wpng,
    768: pantry1afterB768wpng,
    1024: pantry1afterB1024wpng,
    1440: pantry1afterB1440wpng,
    1920: pantry1afterB1920wpng,
    2048: pantry1afterB2048wpng,
  },
  'webp': {
    480: pantry1afterB480wwebp,
    768: pantry1afterB768wwebp,
    1024: pantry1afterB1024wwebp,
    1440: pantry1afterB1440wwebp,
    1920: pantry1afterB1920wwebp,
    2048: pantry1afterB2048wwebp,
  },
  'avif': {
    480: pantry1afterB480wavif,
    768: pantry1afterB768wavif,
    1024: pantry1afterB1024wavif,
    1440: pantry1afterB1440wavif,
    1920: pantry1afterB1920wavif,
    2048: pantry1afterB2048wavif,
  },
},
'pantry1-afterC.jpg': {
  _path: pantry1afterCjpg,
  'png': {
    480: pantry1afterC480wpng,
    768: pantry1afterC768wpng,
    1024: pantry1afterC1024wpng,
    1440: pantry1afterC1440wpng,
    1920: pantry1afterC1920wpng,
    2048: pantry1afterC2048wpng,
  },
  'webp': {
    480: pantry1afterC480wwebp,
    768: pantry1afterC768wwebp,
    1024: pantry1afterC1024wwebp,
    1440: pantry1afterC1440wwebp,
    1920: pantry1afterC1920wwebp,
    2048: pantry1afterC2048wwebp,
  },
  'avif': {
    480: pantry1afterC480wavif,
    768: pantry1afterC768wavif,
    1024: pantry1afterC1024wavif,
    1440: pantry1afterC1440wavif,
    1920: pantry1afterC1920wavif,
    2048: pantry1afterC2048wavif,
  },
},
'pantry1-afterD.jpg': {
  _path: pantry1afterDjpg,
  'png': {
    480: pantry1afterD480wpng,
    768: pantry1afterD768wpng,
    1024: pantry1afterD1024wpng,
    1440: pantry1afterD1440wpng,
    1920: pantry1afterD1920wpng,
    2048: pantry1afterD2048wpng,
  },
  'webp': {
    480: pantry1afterD480wwebp,
    768: pantry1afterD768wwebp,
    1024: pantry1afterD1024wwebp,
    1440: pantry1afterD1440wwebp,
    1920: pantry1afterD1920wwebp,
    2048: pantry1afterD2048wwebp,
  },
  'avif': {
    480: pantry1afterD480wavif,
    768: pantry1afterD768wavif,
    1024: pantry1afterD1024wavif,
    1440: pantry1afterD1440wavif,
    1920: pantry1afterD1920wavif,
    2048: pantry1afterD2048wavif,
  },
},
'pantry1-afterE.jpg': {
  _path: pantry1afterEjpg,
  'png': {
    480: pantry1afterE480wpng,
    768: pantry1afterE768wpng,
    1024: pantry1afterE1024wpng,
    1440: pantry1afterE1440wpng,
    1920: pantry1afterE1920wpng,
    2048: pantry1afterE2048wpng,
  },
  'webp': {
    480: pantry1afterE480wwebp,
    768: pantry1afterE768wwebp,
    1024: pantry1afterE1024wwebp,
    1440: pantry1afterE1440wwebp,
    1920: pantry1afterE1920wwebp,
    2048: pantry1afterE2048wwebp,
  },
  'avif': {
    480: pantry1afterE480wavif,
    768: pantry1afterE768wavif,
    1024: pantry1afterE1024wavif,
    1440: pantry1afterE1440wavif,
    1920: pantry1afterE1920wavif,
    2048: pantry1afterE2048wavif,
  },
},
'pantry1-afterF.jpg': {
  _path: pantry1afterFjpg,
  'png': {
    480: pantry1afterF480wpng,
    768: pantry1afterF768wpng,
    1024: pantry1afterF1024wpng,
    1440: pantry1afterF1440wpng,
    1920: pantry1afterF1920wpng,
    2048: pantry1afterF2048wpng,
  },
  'webp': {
    480: pantry1afterF480wwebp,
    768: pantry1afterF768wwebp,
    1024: pantry1afterF1024wwebp,
    1440: pantry1afterF1440wwebp,
    1920: pantry1afterF1920wwebp,
    2048: pantry1afterF2048wwebp,
  },
  'avif': {
    480: pantry1afterF480wavif,
    768: pantry1afterF768wavif,
    1024: pantry1afterF1024wavif,
    1440: pantry1afterF1440wavif,
    1920: pantry1afterF1920wavif,
    2048: pantry1afterF2048wavif,
  },
},
'pantry1-afterG.jpg': {
  _path: pantry1afterGjpg,
  'png': {
    480: pantry1afterG480wpng,
    768: pantry1afterG768wpng,
    1024: pantry1afterG1024wpng,
    1440: pantry1afterG1440wpng,
    1920: pantry1afterG1920wpng,
    2048: pantry1afterG2048wpng,
  },
  'webp': {
    480: pantry1afterG480wwebp,
    768: pantry1afterG768wwebp,
    1024: pantry1afterG1024wwebp,
    1440: pantry1afterG1440wwebp,
    1920: pantry1afterG1920wwebp,
    2048: pantry1afterG2048wwebp,
  },
  'avif': {
    480: pantry1afterG480wavif,
    768: pantry1afterG768wavif,
    1024: pantry1afterG1024wavif,
    1440: pantry1afterG1440wavif,
    1920: pantry1afterG1920wavif,
    2048: pantry1afterG2048wavif,
  },
},
'pantry1-afterH.jpg': {
  _path: pantry1afterHjpg,
  'png': {
    480: pantry1afterH480wpng,
    768: pantry1afterH768wpng,
    1024: pantry1afterH1024wpng,
    1440: pantry1afterH1440wpng,
    1920: pantry1afterH1920wpng,
    2048: pantry1afterH2048wpng,
  },
  'webp': {
    480: pantry1afterH480wwebp,
    768: pantry1afterH768wwebp,
    1024: pantry1afterH1024wwebp,
    1440: pantry1afterH1440wwebp,
    1920: pantry1afterH1920wwebp,
    2048: pantry1afterH2048wwebp,
  },
  'avif': {
    480: pantry1afterH480wavif,
    768: pantry1afterH768wavif,
    1024: pantry1afterH1024wavif,
    1440: pantry1afterH1440wavif,
    1920: pantry1afterH1920wavif,
    2048: pantry1afterH2048wavif,
  },
},
'pantry1-afterI.jpg': {
  _path: pantry1afterIjpg,
  'png': {
    480: pantry1afterI480wpng,
    768: pantry1afterI768wpng,
    1024: pantry1afterI1024wpng,
    1440: pantry1afterI1440wpng,
    1920: pantry1afterI1920wpng,
    2048: pantry1afterI2048wpng,
  },
  'webp': {
    480: pantry1afterI480wwebp,
    768: pantry1afterI768wwebp,
    1024: pantry1afterI1024wwebp,
    1440: pantry1afterI1440wwebp,
    1920: pantry1afterI1920wwebp,
    2048: pantry1afterI2048wwebp,
  },
  'avif': {
    480: pantry1afterI480wavif,
    768: pantry1afterI768wavif,
    1024: pantry1afterI1024wavif,
    1440: pantry1afterI1440wavif,
    1920: pantry1afterI1920wavif,
    2048: pantry1afterI2048wavif,
  },
},
'pantry2-after1.jpg': {
  _path: pantry2after1jpg,
  'png': {
    480: pantry2after1480wpng,
    768: pantry2after1768wpng,
    1024: pantry2after11024wpng,
    1440: pantry2after11440wpng,
    1920: pantry2after11920wpng,
    2048: pantry2after12048wpng,
  },
  'webp': {
    480: pantry2after1480wwebp,
    768: pantry2after1768wwebp,
    1024: pantry2after11024wwebp,
    1440: pantry2after11440wwebp,
    1920: pantry2after11920wwebp,
    2048: pantry2after12048wwebp,
  },
  'avif': {
    480: pantry2after1480wavif,
    768: pantry2after1768wavif,
    1024: pantry2after11024wavif,
    1440: pantry2after11440wavif,
    1920: pantry2after11920wavif,
    2048: pantry2after12048wavif,
  },
},
'pantry2-after2.jpg': {
  _path: pantry2after2jpg,
  'png': {
    480: pantry2after2480wpng,
    768: pantry2after2768wpng,
    1024: pantry2after21024wpng,
    1440: pantry2after21440wpng,
    1920: pantry2after21920wpng,
    2048: pantry2after22048wpng,
  },
  'webp': {
    480: pantry2after2480wwebp,
    768: pantry2after2768wwebp,
    1024: pantry2after21024wwebp,
    1440: pantry2after21440wwebp,
    1920: pantry2after21920wwebp,
    2048: pantry2after22048wwebp,
  },
  'avif': {
    480: pantry2after2480wavif,
    768: pantry2after2768wavif,
    1024: pantry2after21024wavif,
    1440: pantry2after21440wavif,
    1920: pantry2after21920wavif,
    2048: pantry2after22048wavif,
  },
},
'pantry2-before1.jpg': {
  _path: pantry2before1jpg,
  'png': {
    480: pantry2before1480wpng,
    768: pantry2before1768wpng,
    1024: pantry2before11024wpng,
    1440: pantry2before11440wpng,
    1920: pantry2before11920wpng,
    2048: pantry2before12048wpng,
  },
  'webp': {
    480: pantry2before1480wwebp,
    768: pantry2before1768wwebp,
    1024: pantry2before11024wwebp,
    1440: pantry2before11440wwebp,
    1920: pantry2before11920wwebp,
    2048: pantry2before12048wwebp,
  },
  'avif': {
    480: pantry2before1480wavif,
    768: pantry2before1768wavif,
    1024: pantry2before11024wavif,
    1440: pantry2before11440wavif,
    1920: pantry2before11920wavif,
    2048: pantry2before12048wavif,
  },
},
'pantry2-before2.jpg': {
  _path: pantry2before2jpg,
  'png': {
    480: pantry2before2480wpng,
    768: pantry2before2768wpng,
    1024: pantry2before21024wpng,
    1440: pantry2before21440wpng,
    1920: pantry2before21920wpng,
    2048: pantry2before22048wpng,
  },
  'webp': {
    480: pantry2before2480wwebp,
    768: pantry2before2768wwebp,
    1024: pantry2before21024wwebp,
    1440: pantry2before21440wwebp,
    1920: pantry2before21920wwebp,
    2048: pantry2before22048wwebp,
  },
  'avif': {
    480: pantry2before2480wavif,
    768: pantry2before2768wavif,
    1024: pantry2before21024wavif,
    1440: pantry2before21440wavif,
    1920: pantry2before21920wavif,
    2048: pantry2before22048wavif,
  },
},
'qrcode-site.png': {
  _path: qrcodesitepng,
  'png': {
    480: qrcodesite480wpng,
    768: qrcodesite768wpng,
    1024: qrcodesite1024wpng,
    1440: qrcodesite1440wpng,
    1920: qrcodesite1920wpng,
    2048: qrcodesite2048wpng,
  },
  'webp': {
    480: qrcodesite480wwebp,
    768: qrcodesite768wwebp,
    1024: qrcodesite1024wwebp,
    1440: qrcodesite1440wwebp,
    1920: qrcodesite1920wwebp,
    2048: qrcodesite2048wwebp,
  },
  'avif': {
    480: qrcodesite480wavif,
    768: qrcodesite768wavif,
    1024: qrcodesite1024wavif,
    1440: qrcodesite1440wavif,
    1920: qrcodesite1920wavif,
    2048: qrcodesite2048wavif,
  },
},
'scrabble.jpg': {
  _path: scrabblejpg,
  'png': {
    480: scrabble480wpng,
    768: scrabble768wpng,
    1024: scrabble1024wpng,
    1440: scrabble1440wpng,
    1920: scrabble1920wpng,
    2048: scrabble2048wpng,
  },
  'webp': {
    480: scrabble480wwebp,
    768: scrabble768wwebp,
    1024: scrabble1024wwebp,
    1440: scrabble1440wwebp,
    1920: scrabble1920wwebp,
    2048: scrabble2048wwebp,
  },
  'avif': {
    480: scrabble480wavif,
    768: scrabble768wavif,
    1024: scrabble1024wavif,
    1440: scrabble1440wavif,
    1920: scrabble1920wavif,
    2048: scrabble2048wavif,
  },
},
'shoes1-afterA.jpg': {
  _path: shoes1afterAjpg,
  'png': {
    480: shoes1afterA480wpng,
    768: shoes1afterA768wpng,
    1024: shoes1afterA1024wpng,
    1440: shoes1afterA1440wpng,
    1920: shoes1afterA1920wpng,
    2048: shoes1afterA2048wpng,
  },
  'webp': {
    480: shoes1afterA480wwebp,
    768: shoes1afterA768wwebp,
    1024: shoes1afterA1024wwebp,
    1440: shoes1afterA1440wwebp,
    1920: shoes1afterA1920wwebp,
    2048: shoes1afterA2048wwebp,
  },
  'avif': {
    480: shoes1afterA480wavif,
    768: shoes1afterA768wavif,
    1024: shoes1afterA1024wavif,
    1440: shoes1afterA1440wavif,
    1920: shoes1afterA1920wavif,
    2048: shoes1afterA2048wavif,
  },
},
'storageUnit1-afterA.jpg': {
  _path: storageUnit1afterAjpg,
  'png': {
    480: storageUnit1afterA480wpng,
    768: storageUnit1afterA768wpng,
    1024: storageUnit1afterA1024wpng,
    1440: storageUnit1afterA1440wpng,
    1920: storageUnit1afterA1920wpng,
    2048: storageUnit1afterA2048wpng,
  },
  'webp': {
    480: storageUnit1afterA480wwebp,
    768: storageUnit1afterA768wwebp,
    1024: storageUnit1afterA1024wwebp,
    1440: storageUnit1afterA1440wwebp,
    1920: storageUnit1afterA1920wwebp,
    2048: storageUnit1afterA2048wwebp,
  },
  'avif': {
    480: storageUnit1afterA480wavif,
    768: storageUnit1afterA768wavif,
    1024: storageUnit1afterA1024wavif,
    1440: storageUnit1afterA1440wavif,
    1920: storageUnit1afterA1920wavif,
    2048: storageUnit1afterA2048wavif,
  },
},
'storageUnit1-afterB.jpg': {
  _path: storageUnit1afterBjpg,
  'png': {
    480: storageUnit1afterB480wpng,
    768: storageUnit1afterB768wpng,
    1024: storageUnit1afterB1024wpng,
    1440: storageUnit1afterB1440wpng,
    1920: storageUnit1afterB1920wpng,
    2048: storageUnit1afterB2048wpng,
  },
  'webp': {
    480: storageUnit1afterB480wwebp,
    768: storageUnit1afterB768wwebp,
    1024: storageUnit1afterB1024wwebp,
    1440: storageUnit1afterB1440wwebp,
    1920: storageUnit1afterB1920wwebp,
    2048: storageUnit1afterB2048wwebp,
  },
  'avif': {
    480: storageUnit1afterB480wavif,
    768: storageUnit1afterB768wavif,
    1024: storageUnit1afterB1024wavif,
    1440: storageUnit1afterB1440wavif,
    1920: storageUnit1afterB1920wavif,
    2048: storageUnit1afterB2048wavif,
  },
},
'storageUnit1-afterC.jpg': {
  _path: storageUnit1afterCjpg,
  'png': {
    480: storageUnit1afterC480wpng,
    768: storageUnit1afterC768wpng,
    1024: storageUnit1afterC1024wpng,
    1440: storageUnit1afterC1440wpng,
    1920: storageUnit1afterC1920wpng,
    2048: storageUnit1afterC2048wpng,
  },
  'webp': {
    480: storageUnit1afterC480wwebp,
    768: storageUnit1afterC768wwebp,
    1024: storageUnit1afterC1024wwebp,
    1440: storageUnit1afterC1440wwebp,
    1920: storageUnit1afterC1920wwebp,
    2048: storageUnit1afterC2048wwebp,
  },
  'avif': {
    480: storageUnit1afterC480wavif,
    768: storageUnit1afterC768wavif,
    1024: storageUnit1afterC1024wavif,
    1440: storageUnit1afterC1440wavif,
    1920: storageUnit1afterC1920wavif,
    2048: storageUnit1afterC2048wavif,
  },
},
'storageUnit1-afterD.jpg': {
  _path: storageUnit1afterDjpg,
  'png': {
    480: storageUnit1afterD480wpng,
    768: storageUnit1afterD768wpng,
    1024: storageUnit1afterD1024wpng,
    1440: storageUnit1afterD1440wpng,
    1920: storageUnit1afterD1920wpng,
    2048: storageUnit1afterD2048wpng,
  },
  'webp': {
    480: storageUnit1afterD480wwebp,
    768: storageUnit1afterD768wwebp,
    1024: storageUnit1afterD1024wwebp,
    1440: storageUnit1afterD1440wwebp,
    1920: storageUnit1afterD1920wwebp,
    2048: storageUnit1afterD2048wwebp,
  },
  'avif': {
    480: storageUnit1afterD480wavif,
    768: storageUnit1afterD768wavif,
    1024: storageUnit1afterD1024wavif,
    1440: storageUnit1afterD1440wavif,
    1920: storageUnit1afterD1920wavif,
    2048: storageUnit1afterD2048wavif,
  },
},
'storageUnit1-beforeA.jpg': {
  _path: storageUnit1beforeAjpg,
  'png': {
    480: storageUnit1beforeA480wpng,
    768: storageUnit1beforeA768wpng,
    1024: storageUnit1beforeA1024wpng,
    1440: storageUnit1beforeA1440wpng,
    1920: storageUnit1beforeA1920wpng,
    2048: storageUnit1beforeA2048wpng,
  },
  'webp': {
    480: storageUnit1beforeA480wwebp,
    768: storageUnit1beforeA768wwebp,
    1024: storageUnit1beforeA1024wwebp,
    1440: storageUnit1beforeA1440wwebp,
    1920: storageUnit1beforeA1920wwebp,
    2048: storageUnit1beforeA2048wwebp,
  },
  'avif': {
    480: storageUnit1beforeA480wavif,
    768: storageUnit1beforeA768wavif,
    1024: storageUnit1beforeA1024wavif,
    1440: storageUnit1beforeA1440wavif,
    1920: storageUnit1beforeA1920wavif,
    2048: storageUnit1beforeA2048wavif,
  },
},
'storageUnit1-beforeB.jpg': {
  _path: storageUnit1beforeBjpg,
  'png': {
    480: storageUnit1beforeB480wpng,
    768: storageUnit1beforeB768wpng,
    1024: storageUnit1beforeB1024wpng,
    1440: storageUnit1beforeB1440wpng,
    1920: storageUnit1beforeB1920wpng,
    2048: storageUnit1beforeB2048wpng,
  },
  'webp': {
    480: storageUnit1beforeB480wwebp,
    768: storageUnit1beforeB768wwebp,
    1024: storageUnit1beforeB1024wwebp,
    1440: storageUnit1beforeB1440wwebp,
    1920: storageUnit1beforeB1920wwebp,
    2048: storageUnit1beforeB2048wwebp,
  },
  'avif': {
    480: storageUnit1beforeB480wavif,
    768: storageUnit1beforeB768wavif,
    1024: storageUnit1beforeB1024wavif,
    1440: storageUnit1beforeB1440wavif,
    1920: storageUnit1beforeB1920wavif,
    2048: storageUnit1beforeB2048wavif,
  },
},
'treat-yourself.jpg': {
  _path: treatyourselfjpg,
  'png': {
    480: treatyourself480wpng,
    768: treatyourself768wpng,
    1024: treatyourself1024wpng,
    1440: treatyourself1440wpng,
    1920: treatyourself1920wpng,
    2048: treatyourself2048wpng,
  },
  'webp': {
    480: treatyourself480wwebp,
    768: treatyourself768wwebp,
    1024: treatyourself1024wwebp,
    1440: treatyourself1440wwebp,
    1920: treatyourself1920wwebp,
    2048: treatyourself2048wwebp,
  },
  'avif': {
    480: treatyourself480wavif,
    768: treatyourself768wavif,
    1024: treatyourself1024wavif,
    1440: treatyourself1440wavif,
    1920: treatyourself1920wavif,
    2048: treatyourself2048wavif,
  },
},
'turn-ideas-into-reality.jpg': {
  _path: turnideasintorealityjpg,
  'png': {
    480: turnideasintoreality480wpng,
    768: turnideasintoreality768wpng,
    1024: turnideasintoreality1024wpng,
    1440: turnideasintoreality1440wpng,
    1920: turnideasintoreality1920wpng,
    2048: turnideasintoreality2048wpng,
  },
  'webp': {
    480: turnideasintoreality480wwebp,
    768: turnideasintoreality768wwebp,
    1024: turnideasintoreality1024wwebp,
    1440: turnideasintoreality1440wwebp,
    1920: turnideasintoreality1920wwebp,
    2048: turnideasintoreality2048wwebp,
  },
  'avif': {
    480: turnideasintoreality480wavif,
    768: turnideasintoreality768wavif,
    1024: turnideasintoreality1024wavif,
    1440: turnideasintoreality1440wavif,
    1920: turnideasintoreality1920wavif,
    2048: turnideasintoreality2048wavif,
  },
},
'you-can-do-it.jpg': {
  _path: youcandoitjpg,
  'png': {
    480: youcandoit480wpng,
    768: youcandoit768wpng,
    1024: youcandoit1024wpng,
    1440: youcandoit1440wpng,
    1920: youcandoit1920wpng,
    2048: youcandoit2048wpng,
  },
  'webp': {
    480: youcandoit480wwebp,
    768: youcandoit768wwebp,
    1024: youcandoit1024wwebp,
    1440: youcandoit1440wwebp,
    1920: youcandoit1920wwebp,
    2048: youcandoit2048wwebp,
  },
  'avif': {
    480: youcandoit480wavif,
    768: youcandoit768wavif,
    1024: youcandoit1024wavif,
    1440: youcandoit1440wavif,
    1920: youcandoit1920wavif,
    2048: youcandoit2048wavif,
  },
},
}
export default config
import { useEffect, useState } from 'react'

export function useWidth(width, dir = 'max') {
  const [matches, setMatches] = useState(window.matchMedia(`(${dir}-width: ${width}px)`).matches)

  useEffect(() => {
    window
      .matchMedia(`(${dir}-width: ${width}px)`)
      .addEventListener('change', (e) => setMatches(e.matches))
  }, [width, dir])
  return matches
}

export function columns(cols = 12) {
  return `calc(calc(100% / 12) * ${cols})`
}

import React, { useState, useEffect, useRef, memo } from 'react'
import imageIndex from '../../images/dist/index.js'
import { useStableCallback, useStableRef, checkWebpAvif } from '../utils.js'

const backgroundStyles = {
  position: 'absolute',
  maxWidth: '100%',
  maxHeight: '100%',
  // inset: 0,
  zIndex: -1,
}

const images = Object.values(imageIndex)

function lookup(src) {
  const { avif, webp, png } = images.find((image) => image._path === src) || {}
  return Object.entries({ avif, webp, png }).filter(([, value]) => value)
}

function srcset(widths) {
  return Object.entries(widths)
    .map(([width, cName]) => `${cName} ${width}w`)
    .join(', ')
}

export async function imageSet(src) {
  const supported = await checkWebpAvif()
  return (
    'image-set(' +
    lookup(src)
      .map(([type, widths]) => {
        if (supported[type] ?? true) {
          const { 2048: x2, 1024: x1 } = widths
          return `url("${x1}") 1x, url("${x2}") 2x`
        }
        return null
      })
      .filter(Boolean)
      .join(', ') +
    ')'
  )
}

export default function Image({
  src,
  alt,
  background,
  inset,
  topOffset = 0,
  fullWidth,
  style = {},
  ...props
}) {
  const [top, setTop] = useState()
  const [scale, setScale] = useState(1)
  const ref = useRef()

  const resize = useStableCallback(() => {
    const node = ref.current
    if (node) {
      const { scrollHeight, clientHeight, parentElement } = node
      parentElement.style.backgroundColor = background
      if (scrollHeight > clientHeight) {
        const diff = scrollHeight - clientHeight
        setTop(`-${Math.floor(diff / 2) + topOffset}px`)
      }
      if (!inset && clientHeight < parentElement.clientHeight) {
        setScale((parentElement.clientHeight * 1.2) / clientHeight)
        setTop(Math.floor((parentElement.clientHeight - scrollHeight) / 2) + topOffset + 'px')
      }
    }
  })

  useEffect(() => {
    // We call this so many times to do it as fast as possible
    requestAnimationFrame?.(resize)
    requestIdleCallback?.(resize)
    setTimeout(resize, 50)
    setTimeout(resize, 100)
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [inset, background, resize])

  return (
    <picture
      ref={ref}
      style={
        background
          ? {
              backgroundColor: background,
              top,
              inset,
              transform: `scale(${scale})`,
              width: fullWidth ? '100%' : undefined,
              ...backgroundStyles,
            }
          : null
      }
    >
      {lookup(src).map(([type, widths]) => {
        return <source key={type} type={`image/${type}`} srcSet={srcset(widths)} />
      })}
      <img
        src={src}
        alt={alt}
        {...props}
        style={{ ...style, width: fullWidth ? '100%' : undefined }}
        onLoad={resize}
      />
    </picture>
  )
}

function Rotate({ delay = 1000, ...props }) {
  const sourcesRef = useStableRef(
    Object.entries(props)
      .filter(([key]) => key.startsWith('src'))
      .map(([, values]) => values)
  )
  const [activeSrc, setActiveSrc] = useState(sourcesRef.current[0])
  const activeSrcRef = useRef(activeSrc)

  useEffect(() => {
    const interval = setInterval(() => {
      const { current } = activeSrcRef
      const nextSrc =
        sourcesRef.current[
          (sourcesRef.current.findIndex((src) => src === current) + 1) % sourcesRef.current.length
        ]
      setActiveSrc(nextSrc)
      activeSrcRef.current = nextSrc
    }, delay)
    return () => clearInterval(interval)
  }, [sourcesRef, activeSrcRef, delay])

  return (
    <Image
      src={activeSrc}
      {...Object.fromEntries(Object.entries(props).filter(([key]) => !key.startsWith('src')))}
    />
  )
}
export const RotateImages = memo(Rotate)

const sizeMap = {
  xs: 100,
  sm: 200,
  md: 300,
  lg: 500,
  xl: 700,
  full: '100%',
}

export function Portrait({ size, maxWidth, ...props }) {
  const width = sizeMap[size] || sizeMap.full
  return (
    <div style={{ width, maxWidth: maxWidth ?? '100%', height: 'auto' }}>
      <Image {...{ size, ...props }} style={{ borderRadius: 50 }} />
    </div>
  )
}

import { lazy, Suspense } from 'react'
import Nav from './components/nav/Nav'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

function withSuspense(Component, Skeleton = () => null) {
  return function SuspendedComponent(props) {
    return (
      <Suspense fallback={<Skeleton />}>
        <Component {...props} />
      </Suspense>
    )
  }
}

const Home = withSuspense(lazy(() => import('./pages/Home')))
const About = withSuspense(lazy(() => import('./pages/About')))
const Services = withSuspense(lazy(() => import('./pages/Services')))
const Contact = withSuspense(lazy(() => import('./pages/Contact')))
const ProjectGallery = withSuspense(lazy(() => import('./pages/ProjectGallery')))
const BeforeAfter = withSuspense(lazy(() => import('./pages/BeforeAfter')))

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Nav />
        <main>
          <Routes>
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/work" element={<ProjectGallery />} />
            <Route path="/results" element={<BeforeAfter />} />
            <Route path="/" index element={<Home />} />
          </Routes>
        </main>
      </div>
    </BrowserRouter>
  )
}

export default App

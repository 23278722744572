import { useEffect, useRef, useCallback } from 'react'

export function noop() {}

export function boolAttr(value) {
  return value ? '' : undefined
}

export function useStableRef(data) {
  const ref = useRef(data)
  useEffect(() => {
    ref.current = data
  }, [data])
  return ref
}

export function useStableCallback(cb) {
  const cbRef = useStableRef(cb)
  return useCallback((...args) => cbRef.current(...args), [cbRef])
}

const upcomingTypes = {
  avif: 'data:image/avif;base64,AAAAHGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZgAAAeptZXRhAAAAAAAAACFoZGxyAAAAAAAAAABwaWN0AAAAAAAAAAAAAAAAAAAAAA5waXRtAAAAAAABAAAANGlsb2MAAAAAREAAAgACAAAAAAIOAAEAAAAAAAAAFQABAAAAAAIjAAEAAAAAAAAAGgAAADhpaW5mAAAAAAACAAAAFWluZmUCAAAAAAEAAGF2MDEAAAAAFWluZmUCAAAAAAIAAGF2MDEAAAABKWlwcnAAAAEBaXBjbwAAABNjb2xybmNseAACAAIABoAAAAAMYXYxQ4EAHAAAAAAUaXNwZQAAAAAAAAABAAAAAQAAAChjbGFwAAAAAQAAAAEAAAABAAAAAf////EAAAAC////8QAAAAIAAAAOcGl4aQAAAAABCAAAADhhdXhDAAAAAHVybjptcGVnOm1wZWdCOmNpY3A6c3lzdGVtczphdXhpbGlhcnk6YWxwaGEAAAAADGF2MUOBAAwAAAAAFGlzcGUAAAAAAAAAAQAAAAEAAAAoY2xhcAAAAAEAAAABAAAAAQAAAAH////xAAAAAv////EAAAACAAAAEHBpeGkAAAAAAwgICAAAACBpcG1hAAAAAAAAAAIAAQWBhwiJigACBYIDhIWGAAAAGmlyZWYAAAAAAAAADmF1eGwAAgABAAEAAAA3bWRhdBIACgQYAAaVMgsX8AABIAACIRujoBIACggYAAaIEBA0IDIMF/AAAEgAAACwE0vo',
  webp: 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAgA0JaQAA3AA/vv9UAA=',
}

export async function checkBrowserImageSupport(type) {
  if (upcomingTypes[type]) {
    return new Promise((resolve) => {
      const image = new Image()
      image.onload = () => resolve(true)
      image.onerror = () => resolve(false)
      image.setAttribute('src', upcomingTypes[type])
    })
  }
  return true
}

const supportedImageTypes = {
  avif: checkBrowserImageSupport('avif'),
  webp: checkBrowserImageSupport('webp'),
}

export const checkWebpAvif = () => {
  const avif = supportedImageTypes.avif
  const webp = supportedImageTypes.webp
  return Promise.all([avif, webp]).then(([avif, webp]) => {
    return { avif, webp }
  })
}
